import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getEnvVars } from '../../app/env';
import 'swiper/css';
import 'swiper/css/pagination';
import { useSelector, useDispatch } from 'react-redux';
// import required modules
import { Autoplay } from 'swiper';
import axios from 'axios';
import { Link } from 'react-router-dom';
import star from '../../assets/icons/starfilled.svg';
import profileIcon from '../../assets/icons/profile.svg';
import { setRateInUserCurrency } from '../../features/user/userSlice';

const { REACT_APP_API_URL } = getEnvVars();

export default function App() {
  const [experts, setExperts] = useState([]);
  const [swiper, setSwiper] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { currency, rateInUserCurrency } = useSelector((state) => state.user);

  useEffect(() => {
    (async () => {
      dispatch(setRateInUserCurrency(currency));
      setLoading(true);
      const api = `${REACT_APP_API_URL}/v1/experts/search?limit=10&skip=0&orderBy=rating`;
      await axios
        .get(api)
        .then((result) => {
          const experts = result.data.data.docs;
          setLoading(false);
          setExperts(experts);
        })
        .catch(function (error) {
          setLoading(false);
          console.error(error);
        });
    })();
  }, []);

  const onMouseEnter = () => swiper.autoplay.stop();
  const onMouseLeave = () => swiper.autoplay.start();

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        onSwiper={setSwiper}
        focusableElements="textarea, button"
        grabCursor={true}
        modules={[Autoplay]}
        className="w-full"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {experts.map((item, index) => (
          <SwiperSlide key={index}>
            <div
              className="px-4 py-6 bg-white text-neutral-400 shadow-sm rounded-2xl mr-3 h-max md:h-72"
              key={item.id + index}
            >
              <div className="flex">
                <div className="h-20 w-20 object-cover relative">
                  {item?.profilePhoto ? (
                    <img
                      src={item?.profilePhoto}
                      alt=""
                      className="w-full h-full rounded-lg object-cover"
                    />
                  ) : (
                    <img
                      src={profileIcon}
                      alt=""
                      className="w-full h-full rounded-lg"
                    />
                  )}
                </div>

                <div className="grid pl-2">
                  <p className="text-[22px] font-bold text-black">
                    {(item.firstName + ' ' + item.lastName).length > 20 ? (
                      (item.firstName + ' ' + item.lastName).slice(0, 20) +
                      '...'
                    ) : (
                      <>{item.firstName + ' ' + item.lastName}</>
                    )}
                  </p>
                  <p>
                    {item.jobTitle?.length > 35
                      ? item.jobTitle.slice(0, 35) + '...'
                      : item.jobTitle}
                  </p>
                  <div className="flex">
                    <img src={star} alt="rating-icon" className="pr-2 py-1" />
                    <span className="text-lg font-bold self-center text-black">
                      {item.rating}
                    </span>
                  </div>
                </div>
              </div>

              <p className="text-sm py-2 pr-8 lg:pr-0 h-20">
                {item.bio?.length > 120 ? (
                  item.bio.slice(0, 120) + '...'
                ) : (
                  <>{item.bio}</>
                )}
              </p>

              <div className="flex gap-2 text-xs md:text-sm">
                {item.skills[0]?.length > 18 ? (
                  <p className="font-medium bg-neutral-200 text-gray-800 rounded-lg px-3 lg:px-1.5 xl:px-3 py-0.5 h-min">
                    {item.skills[0].slice(0, 18) + '...'}
                  </p>
                ) : item.skills[0] ? (
                  <p className="font-medium bg-neutral-200 text-gray-800 rounded-lg px-3 lg:px-1.5 xl:px-3 py-0.5 h-min">
                    {item.skills[0]}
                  </p>
                ) : (
                  ''
                )}

                {item.skills[1]?.length > 18 ? (
                  <p className="font-medium bg-neutral-200 text-gray-800 rounded-lg px-3 lg:px-1.5 xl:px-3 py-0.5 h-min">
                    {item.skills[1].slice(0, 18) + '...'}
                  </p>
                ) : item.skills[0] ? (
                  <p className="font-medium bg-neutral-200 text-gray-800 rounded-lg px-3 lg:px-1.5 xl:px-3 py-0.5 h-min">
                    {item.skills[1]}
                  </p>
                ) : (
                  ''
                )}
              </div>

              <div className="flex pt-4">
                <p className="self-center">
                  From{' '}
                  <span className="text-black font-semibold">
                    {rateInUserCurrency == null
                      ? null
                      : new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency,
                        }).format(item?.fee * rateInUserCurrency)}
                  </span>
                </p>
                <Link to={'/book/' + item.id} className="ml-auto">
                  <button className="bg-[#270058] text-sm font-medium text-white py-2.5 px-6 rounded-lg">
                    Book call
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {loading && (
        <div className="sm:ml-20 flex gap-6">
          <div className="grid rounded-2xl shadow-sm px-4 py-6 bg-white text-neutral-400 w-[42rem] mr-3 h-max md:h-72">
            <div
              role="status"
              className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
            >
              <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
            </div>
          </div>

          <div className="grid rounded-2xl shadow-sm px-4 py-6 bg-white text-neutral-400 w-[42rem] mr-3 h-max md:h-72">
            <div
              role="status"
              className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
            >
              <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
            </div>
          </div>

          <div className="grid rounded-2xl shadow-sm px-4 py-6 bg-white text-neutral-400 w-[42rem] mr-3 h-max md:h-72">
            <div
              role="status"
              className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
            >
              <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
              <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
