import React from 'react';
import Navbar from '../components/layout/navbar';
import errorIcon from '../assets/imgs/404.svg';
import { Link } from 'react-router-dom';

const ExpertErrorpage = () => {
  return (
    <div>
      <Navbar />
      <div className="mt-16 h-[90vh] flex flex-col items-center justify-center gap-4 p-4">
        <div className="max-w-[37rem] w-full">
          <img src={errorIcon} alt="error" />
        </div>
        <p className="max-w-[43rem] text-center text-black font-semibold my-2">
          Oh no!!! We can’t seem to find the expert you’re looking for. Try
          going back home, or to the previous page or visit our help center for
          more information.
        </p>
        <Link
          to={'/'}
          className="w-full max-w-[20rem] bg-[#270058] text-white rounded p-2 mt-6 flex items-center justify-center gap-2"
        >
          Go to Homepage
        </Link>
      </div>
    </div>
  );
};

export default ExpertErrorpage;
