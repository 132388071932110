import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LeftPane from './expertAuthleftBg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GoogleLogin } from '@react-oauth/google';
import { getEnvVars } from '../../app/env';
import StepOneForm from '../../components/AuthComponents/Expert/StepOneForm';
import StepTwoForm from '../../components/AuthComponents/Expert/StepTwoForm';

const { REACT_APP_API_URL, REACT_APP_FRONTEND_URL } = getEnvVars();

const SignupExpt = () => {
  // const navigate = useNavigate();
  const [userName /*setUserName*/] = useState(() =>
    sessionStorage.getItem('referralId'),
  );

  const toastID = Math.random();
  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
  });
  const [screen, setScreen] = useState(1);
  const [signupScreen, setSignupScreen] = useState(false);

  const googleSuccess = async (credentialResponse) => {
    let reqData = {
      token: credentialResponse.credential,
      homepageUrl: REACT_APP_FRONTEND_URL,
      verificationSuccessfulUrl: `${REACT_APP_FRONTEND_URL}/email_verification_success`,
    };
    if (userName) {
      reqData = { ...reqData, referralCode: userName };
    }

    try {
      await axios
        .post(`${REACT_APP_API_URL}/v1/auth/register-expert-gauth`, reqData)
        .then((res) => {
          toast.success('Account created successfully', { toastId: toastID });
          // navigate('/expert/google_success');
          localStorage.setItem('exptToken', res.data.data.token);
        });
      sessionStorage.removeItem('referralId');
      // navigate('/expert/google_success');
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message, { toastId: toastID });
    }
  };

  const googleError = () => {
    toast.error('Google auth failed', { toastId: toastID });
  };

  const handleStepOneData = (data) => {
    setState({ ...state, ...data });
    setScreen(2);
  };

  return (
    <div className="grid lg:grid-cols-5 text-sm expert-auth">
      <div className="col-span-2 sticky top-0 px-16 hidden lg:block">
        <LeftPane />
      </div>

      <div className="col-span-3 px-6 sm:px-36 flex flex-col justify-center items-center h-screen overflow-scroll">
        {signupScreen !== false ? (
          <>
            {screen === 1 ? (
              <StepOneForm
                handleStepOneData={handleStepOneData}
                formData={state}
              />
            ) : (
              <StepTwoForm
                stepOneData={state}
                handleStepOne={() => setScreen(1)}
                userName={userName}
              />
            )}
          </>
        ) : (
          <div className="text-center">
            <h1 className="font-semibold text-center text-[28px] mb-4">
              Let’s Get Started
            </h1>

            <p>Create an account on Zedintro</p>

            <button
              className="bg-[#270058] text-white min-w-[12.5rem] rounded p-2 mt-6"
              type="button"
              onClick={() => setSignupScreen(true)}
            >
              Sign up with Email
            </button>

            <div className="text-sm text-gray-500 text-center my-4">OR</div>

            <div className="googleButton w-min mx-auto">
              <GoogleLogin
                onSuccess={googleSuccess}
                onError={googleError}
                text="signup_with"
              />
            </div>

            <p className="text-neutral-700 text-center flex gap-2 items-center justify-center mt-6">
              Already have an account?
              <Link to="/login/expert/" className="text-purple-500">
                Sign in
              </Link>
            </p>

            <p className="text-neutral-700 text-center flex flex-wrap space-x-1.5 items-center justify-center mt-16">
              By signing up I agree to Zedintro
              <Link to="/terms" className="text-bgPurple font-bold mx-1.5">
                Terms & Conditions
              </Link>
              and
              <Link to="/privacy" className="text-bgPurple font-bold">
                Privacy Policy
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignupExpt;
