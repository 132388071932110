import React from 'react';
import PropTypes from 'prop-types';
import AlternateFooter from './AlternateFooter';
import Sidebar from './sidebar';
import Navbar from './dashNavbar';
import UserNavbar from './navbar';
import Header from '../banner';
import ProtectedRoute from '../../utils/ProtectedRoute';
import Container from '../shared/Container';

const Layout = ({ children }) => {
  let title;
  if (
    window.location.pathname === '/expert/account/profile' ||
    window.location.pathname === '/profile'
  ) {
    title = 'Profile';
  } else if (
    window.location.pathname === '/expert/account/password' ||
    window.location.pathname === '/password'
  ) {
    title = 'Password';
  } else if (window.location.pathname === '/expert/account/availability') {
    title = 'Availability';
  } else if (window.location.pathname === '/expert/account/payment') {
    title = 'Payment';
  } else {
    title;
  }

  return (
    <ProtectedRoute>
      <div className="bg-[#EFEFEF] relative min-h-[100vh]">
        {window.location.pathname === '/profile' ||
        window.location.pathname === '/password' ? (
          <UserNavbar />
        ) : (
          <Navbar />
        )}
        <Header
          title={title}
          pathUrl={
            window.location.pathname.includes('expert')
              ? `/expert/dashboard`
              : '/search'
          }
        />
        <Container>
          <div className="md:grid md:grid-cols-12 pb-28 ">
            <div className="md:col-span-3">
              <Sidebar title={title} />
            </div>
            <div className="md:col-span-9 md:mr-10">{children}</div>
          </div>
        </Container>
        <AlternateFooter />
      </div>
    </ProtectedRoute>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
