import React, { useEffect, useMemo, useState } from 'react';
import { FiUpload, FiTrash2, FiLogOut } from 'react-icons/fi';
import axios from 'axios';
import { toast } from 'react-toastify';
import ProtectedRoute from '../utils/ProtectedRoute';
import { useDispatch, useSelector } from 'react-redux';
import ReactLoading from 'react-loading';
import { expertMedia, resetMedia } from '../features/experts/expertSlice';
import { setClientUsername, updateUser } from '../features/user/userSlice';
import profileIcon from '../assets/icons/person.svg';
import SelectMenu from '../components/shared/SelectMenu';
import { INDUSTRIES } from '../data/constants';
import CountryDropDown from '../components/shared/CountryDropDown';
import countryList from 'react-select-country-list';
import { BiChevronDown } from 'react-icons/bi';
import { getEnvVars } from '../app/env';
import AlternateFooter from '../components/layout/AlternateFooter';
import UserNavbar from '../components/layout/navbar';
import Header from '../components/banner';
import { FiUser, FiLock } from 'react-icons/fi';
import { useNavigate } from 'react-router';
import SettingDropdown from '../components/shared/SettingDropdown';
import { BsClipboardCheck } from 'react-icons/bs';
import Container from '../components/shared/Container';

const { REACT_APP_API_URL, REACT_APP_FRONTEND_URL } = getEnvVars();

const userMenu = [
  {
    path: '/profile',
    name: 'Profile',
    icon: <FiUser />,
  },

  {
    path: '/password',
    name: 'Password',
    icon: <FiLock />,
  },
];

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { media, mediaStatus } = useSelector((state) => state.expert);
  const { profile } = useSelector((state) => state.user);
  const initialState = {
    profilePhoto: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
    company: '',
    industry: '',
    location: '',
    bio: '',
    userName: '',
  };
  const [state, setState] = useState(initialState);
  const [clipboard, setClipboard] = useState(false);
  const [clientUsername /*setClientUsername*/] = useState(() =>
    localStorage.getItem('userName'),
  );
  const countriesList = useMemo(() => countryList().getData(), []);
  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('userToken'),
  };
  const toastID = Math.random();

  let title;
  if (window.location.pathname === '/profile') {
    title = 'Profile';
  } else if (window.location.pathname === '/password') {
    title = 'Password';
  } else {
    title;
  }

  useEffect(() => {
    if (mediaStatus === 'success') {
      const updateObj = {
        firstName: profile.firstName,
        lastName: profile.lastName,
        bio: profile.bio,
        location: profile.location,
        jobTitle: profile.jobTitle,
        company: profile.company,
        industry: profile.industry,
        profilePhoto: profile.profilePhoto,
      };
      for (const key in updateObj) {
        if (!/\S/.test(updateObj[key]) || !updateObj[key]) {
          delete updateObj[key];
        } else {
          updateObj[key] =
            typeof updateObj[key] === 'string'
              ? updateObj[key].trim()
              : updateObj[key];
        }
      }
      toast.success('Profile image updated', { toastId: toastID });
      dispatch(updateUser({ ...updateObj, profilePhoto: media.url }));
    }

    return () => {
      if (mediaStatus === 'success') {
        dispatch(resetMedia());
      }
    };
  }, [mediaStatus]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const reqObj = { ...state };

    if (clientUsername !== reqObj.userName) {
      dispatch(setClientUsername(reqObj.userName));
      return;
    }

    delete reqObj.profilePhoto;
    delete reqObj.userName;

    for (const key in reqObj) {
      if (reqObj[key] === '' || !reqObj[key]) {
        delete reqObj[key];
      }
    }

    try {
      await axios
        .patch(`${REACT_APP_API_URL}/v1/clients/profile`, reqObj, {
          headers,
        })
        .then((res) => {
          toast.success(res.data.message, { toastId: toastID });
        });
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message, { toastId: toastID });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    document.getElementById('btn').className = 'btn-default';
  };

  function handleFileInputChange(event) {
    event.preventDefault();
    dispatch(expertMedia({ photo: event.target.files[0] }));
  }

  const handleSelect = (item, field) => {
    document.getElementById('btn').className = 'btn-default';
    setState({
      ...state,
      [field]: field === 'location' ? item.label : item.value,
    });
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/');
  };

  const handleShareLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `${REACT_APP_FRONTEND_URL}?name=${profile?.displayName}`,
      );
      setClipboard(true);
      setTimeout(() => {
        setClipboard(false);
      }, 1500);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ProtectedRoute>
      <div className="bg-[#EFEFEF] relative">
        <UserNavbar />

        <Header title={title} pathUrl={'/search'} />

        <Container>
          <div className="justify-between mb-12 md:flex hidden relative">
            <div
              className={`flex space-x-3 items-center ${
                title === 'Profile'
                  ? 'border-b-2 border-textPurple text-textPurple'
                  : ' text-textLight'
              } cursor-pointer px-3 py-1.5`}
              onClick={() => navigate('/profile')}
            >
              <FiUser className=" font-semibold text-xl" />
              <p>Profile</p>
            </div>

            <div
              className={`flex space-x-3 items-center ${
                title === 'Password'
                  ? 'border-b-2 border-textPurple text-textPurple'
                  : ' text-textLight'
              } cursor-pointer px-3 py-1.5`}
              onClick={() => navigate('/password')}
            >
              <FiLock className=" font-semibold text-xl" />
              <p>Password</p>
            </div>

            <div
              className={`flex space-x-3 items-center ${
                !title
                  ? 'border-b-2 border-textPurple text-textPurple'
                  : ' text-textLight'
              } cursor-pointer px-3 py-1.5`}
              onClick={handleLogout}
            >
              <FiLogOut className=" font-semibold text-xl" />
              <p>Logout</p>
            </div>
          </div>
        </Container>
        <div className="mx-10 relative">
          <SettingDropdown options={userMenu} title={title} />
        </div>
        <Container>
          <div className="lg:grid lg:grid-cols-12 gap-8">
            <div className="md:col-span-5  mx-auto lg:mx-0">
              <div className="rounded-lg bg-white flex flex-col justify-center lg:h-full px-4 py-8 lg:py-0 sm:px-6">
                <div className="h-[26rem] bg-gray-200 rounded-lg">
                  {profile?.profilePhoto ? (
                    <img
                      src={profile?.profilePhoto}
                      alt="profile-photo"
                      className="w-full h-full rounded-lg"
                    />
                  ) : (
                    <img
                      src={profileIcon}
                      alt="profile-photo"
                      className="w-full h-full rounded-lg"
                    />
                  )}
                </div>

                <div className="">
                  <div className="relative flex items-center my-6">
                    <input
                      type="file"
                      accept="image/*"
                      className="w-[34px] cursor-pointer absolute opacity-0"
                      onChange={handleFileInputChange}
                      required
                    />
                    <div className="rounded-lg border inline-block border-neutral-300 p-2 h-9 cursor-pointer">
                      <FiUpload />
                    </div>
                    <p className="ml-4">Upload new profile picture</p>
                  </div>

                  <div className="relative flex items-center">
                    <div className="rounded-lg border border-neutral-300 p-2 h-9 cursor-pointer">
                      <FiTrash2 />
                    </div>
                    <p className="ml-4">Delete profile picture</p>
                  </div>

                  {mediaStatus === 'loading' && (
                    <ReactLoading
                      type="cylon"
                      color="#270058"
                      className=""
                      height={20}
                      width={20}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className="md:col-span-7 mt-10 lg:mt-0">
              <div className="shadow-sm bg-white p-5 sm:p-8 rounded-lg">
                <form className="grid gap-y-4 mt-6" onSubmit={handleSubmit}>
                  <div className="grid sm:grid-cols-2 sm:gap-x-4 sm:gap-y-0 gap-y-4">
                    <div>
                      <label htmlFor="" className="font-medium text-sm">
                        First name
                      </label>
                      <input
                        type="text"
                        placeholder={profile?.firstName}
                        value={state.firstName}
                        className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                        name="firstName"
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="" className="font-medium text-sm">
                        Last name
                      </label>
                      <input
                        type="text"
                        placeholder={profile?.lastName}
                        value={state.lastName}
                        className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                        name="lastName"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-2 sm:gap-x-4 sm:gap-y-0 gap-y-4">
                    <div className="">
                      <label htmlFor="" className="font-medium text-sm">
                        Email address
                      </label>
                      <input
                        type="email"
                        placeholder={profile?.email}
                        className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                        disabled
                      />
                    </div>

                    <div>
                      <label htmlFor="userName" className="font-medium text-sm">
                        Username
                      </label>
                      <input
                        type="text"
                        value={clientUsername}
                        className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                        name="userName"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-2 sm:gap-x-4 sm:gap-y-0 gap-y-4">
                    <div>
                      <label htmlFor="jobTitle" className="font-medium text-sm">
                        Job title
                      </label>

                      <input
                        type="text"
                        placeholder={profile?.jobTitle}
                        value={state.jobTitle}
                        className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                        name="jobTitle"
                        id="jobTitle"
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      <label htmlFor="" className="font-medium text-sm">
                        Company
                      </label>
                      <input
                        type="text"
                        placeholder={profile?.company}
                        value={state.company}
                        className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                        name="company"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="grid sm:grid-cols-2 sm:gap-x-4 sm:gap-y-0 gap-y-4">
                    <div className="relative flex flex-col gap-1">
                      <label htmlFor="industry" className="font-medium text-sm">
                        Industry
                      </label>

                      <SelectMenu
                        optionField={'industry'}
                        className={'w-full max-h-[20rem] overflow-y-auto mt-4'}
                        options={INDUSTRIES}
                        handleSelect={handleSelect}
                      >
                        <div className="w-full border border-gray-300 rounded-lg p-2 text-sm sm:text-base font-normal flex items-center justify-between">
                          {state.industry
                            ? state.industry
                            : 'Select your industry'}
                          <BiChevronDown className="text-black text-xl" />
                        </div>
                      </SelectMenu>
                    </div>

                    <div className="flex flex-col gap-1">
                      <label htmlFor="location" className="font-medium text-sm">
                        Location
                      </label>

                      <CountryDropDown
                        optionField={'location'}
                        className={'w-full max-h-[20rem] overflow-y-auto mt-4'}
                        options={countriesList}
                        handleSelect={handleSelect}
                      >
                        <div className="w-full border border-gray-300 rounded-lg p-2 text-sm sm:text-base font-normal flex items-center justify-between">
                          {state.location
                            ? state.location
                            : 'Select your location'}
                          <BiChevronDown className="text-black text-xl" />
                        </div>
                      </CountryDropDown>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="" className="font-medium text-sm">
                      Bio
                    </label>
                    <textarea
                      name="bio"
                      id=""
                      cols="30"
                      rows="4"
                      placeholder={profile?.bio}
                      value={state.bio}
                      className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                      onChange={handleChange}
                    ></textarea>
                  </div>

                  <button className="w-full btn-disabled" id="btn">
                    Save Changes
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Container>
        <Container>
          <div className="mt-5 pb-28">
            <p className="font-semibold sm:text-xl text-lg py-4">
              Referral Link
            </p>

            <div className="flex sm:flex-row flex-col gap-2 sm:items-center">
              <input
                type="text"
                name=""
                id=""
                disabled
                placeholder={
                  profile?.displayName
                    ? `${REACT_APP_FRONTEND_URL}?name=${profile?.displayName}`
                    : 'No referral link available until you set your username'
                }
                className="w-full sm:p-2 p-1.5 text-sm sm:text-base placeholder:text-sm text-textLight border border-neutral-500 rounded-lg bg-transparent"
                value={
                  profile?.displayName
                    ? `${REACT_APP_FRONTEND_URL}?name=${profile?.displayName}`
                    : ''
                }
              />

              <button
                className="btn-default md:w-2/12 w-4/12 text-xs md:text-sm relative"
                onClick={handleShareLink}
              >
                {clipboard && (
                  <div className="absolute shadow-md rounded-md -top-10 text-black bg-white py-1 px-3 flex gap-2 items-center">
                    <BsClipboardCheck />
                    copied
                  </div>
                )}
                Copy Link
              </button>
            </div>

            {!profile?.displayName && (
              <p className="text-sm text-red-500 mt-2">
                Your username is required to have a referral link
              </p>
            )}
          </div>
        </Container>
        <AlternateFooter />
      </div>
    </ProtectedRoute>
  );
};

export default Profile;
