import React from "react";
import Navbar from "../components/layout/navbar";
import AlternateFooter from "../components/layout/AlternateFooter";
import confirm from "../assets/icons/confirm.svg";
import { Link } from "react-router-dom";
import ProtectedRoute from "../utils/ProtectedRoute";

const Confirmation = () => {
  return (
    <ProtectedRoute>
      <div className="confirmation mx-auto altNav min-h-screen">
        <Navbar />
        <div className="text-center mx-4 mt-32 mb-40">
          <img src={confirm} className="mx-auto" alt="icon" />
          <h1 className="font-bold text-3xl leading-9 my-8">
            Your request has been sent
          </h1>
          <p className=" max-w-[30rem] m-auto">
            Please look out in your email to know when your request has been
            accepted
          </p>
          <Link to={"/call"}>
            <button className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded py-3 px-5 w-40">
              Go to calls
            </button>
          </Link>
        </div>
        <AlternateFooter />
      </div>
    </ProtectedRoute>
  );
};

export default Confirmation;
