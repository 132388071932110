import React from 'react';
import Navbar from '../components/layout/navbar';
import Footer from '../components/layout/footer';
import {
  MdPhoneAndroid,
  MdOutlineLocationOn,
  MdMailOutline,
} from 'react-icons/md';
import FacebookPixel from '../utils/FacebookPixel';
import Container from '../components/shared/Container';

const Contact = () => {
  return (
    <div className="about mx-auto">
      <FacebookPixel />
      <Navbar />
      <div className="sm:pb-20">
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 py-36">
            <div>
              <p className="text-gray-800">Contact us.</p>

              <h1 className="font-bold sm:text-5xl text-4xl sm:my-4 my-3">
                Get in touch with us
              </h1>

              <p className="text-gray-800">
                Easily get in touch us whenever you may need us.
              </p>

              <div className="grid gap-y-8 sm:my-10 my-4">
                <div className="flex items-center">
                  <div className="bg-[#F9EDFF] mr-3 sm:rounded-[10px] rounded-md sm:p-4 p-3">
                    <MdMailOutline className="text-[#41008B] self-center sm:text-4xl text-2xl" />
                  </div>

                  <div className="">
                    <p className="font-semibold text-xl">Email Address</p>
                    <p>info@zedintro.com</p>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="bg-[#F9EDFF] mr-3 sm:rounded-[10px] rounded-md sm:p-4 p-3">
                    <MdPhoneAndroid className="text-[#41008B] self-center sm:text-4xl text-2xl" />
                  </div>

                  <div className="">
                    <p className="font-semibold text-xl">Phone Number</p>
                    <p>+234-812-654-8750</p>
                  </div>
                </div>

                <div className="flex items-center">
                  <div className="bg-[#F9EDFF] mr-3 sm:rounded-[10px] rounded-md sm:p-4 p-3">
                    <MdOutlineLocationOn className="text-[#41008B] self-center sm:text-4xl text-2xl" />
                  </div>

                  <div className="">
                    <p className="font-semibold text-xl">Location</p>
                    <p>7 Akin George Street, Obanikoro, Lagos.</p>
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={''}>
              <div className="grid sm:p-8 p-4 sm:rounded-2xl rounded-xl shadow-md gap-y-6 !bg-[#F9F5FF] mt-6 sm:mt-0">
                <div>
                  <label htmlFor="">Your Name</label> <br />
                  <input
                    type="text"
                    className="w-full border border-neutral-300 rounded-lg p-2 mt-2"
                    placeholder="Enter your name"
                    name=""
                  />
                </div>

                <div>
                  <label htmlFor="">Your Email Address</label> <br />
                  <input
                    type="email"
                    className="w-full border border-neutral-300 rounded-lg p-2 mt-2"
                    placeholder="Enter your email address"
                    name=""
                  />
                </div>

                <div>
                  <label htmlFor="">Your Phone Number</label> <br />
                  <input
                    type="number"
                    className="w-full border border-neutral-300 rounded-lg p-2 mt-2"
                    placeholder="Enter your phone number"
                    name=""
                  />
                </div>

                <div>
                  <label htmlFor="">Your Message</label> <br />
                  <textarea
                    type="text"
                    className="w-full border border-neutral-300 rounded-lg p-2 mt-2"
                    placeholder="Enter your message"
                    cols="30"
                    rows="4"
                    name=""
                  />
                </div>
                <button className="btn-default w-full">Save Message</button>
              </div>
            </form>
          </div>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
