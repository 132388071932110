import { configureStore } from "@reduxjs/toolkit";
import expertReducer from "../features/experts/expertSlice";
import sessionReducer from "../features/session/sessionSlice";
import userReducer from "../features/user/userSlice";
import appReducer from "../features/app/appSlice";

export const store = configureStore({
  reducer: {
    expert: expertReducer,
    session: sessionReducer,
    user: userReducer,
    app: appReducer,
  },
});
