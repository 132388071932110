import React, { useEffect } from "react";
import Navbar from "../components/layout/navbar";
import AlternateFooter from "../components/layout/AlternateFooter";
import confirm from "../assets/icons/confirm.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { singleSession } from "../features/session/sessionSlice";
import ProtectedRoute from "../utils/ProtectedRoute";

const Confirmation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { session } = useSelector((state) => state.session);
  const { id } = useParams();

  useEffect(() => {
    dispatch(singleSession({ id }));
  }, []);

  return (
    <ProtectedRoute>
      <div className="border border-transparent">
        <Navbar /> 
        <div className="text-center mx-4 mt-40 mb-40 h-full">
          <img src={confirm} className="mx-auto" alt="confirm-icon" />
          <h1 className="font-bold text-3xl leading-9 my-8">
            You’re ready to Zedintro!
          </h1>
          <p className="px-4 md:px-44 lg:px-80 xl:px-96">
            Your call with{" "}
            {`${session?.participant?.firstName} ${session?.participant?.lastName}`}{" "}
            has been set
          </p>
          <button
            className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded py-3 px-5 w-40"
            onClick={() => navigate("/Call/")}
          >
            Go to calls
          </button>
        </div>
        <AlternateFooter />
      </div>
    </ProtectedRoute>
  );
};

export default Confirmation;
