import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import starsfull from '../assets/icons/starsfull.svg';
import star from '../assets/icons/starfilled.svg';
import { FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailability,
  getExpert,
  resetAvailability,
} from '../features/experts/expertSlice';
import {
  createSession,
  resetSession,
  saveSession,
} from '../features/session/sessionSlice';
import ProtectedRoute from '../utils/ProtectedRoute';
import Layout from '../components/layout/layout';
import { getEnvVars } from '../app/env';
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import AvailabilityDropdown from '../components/shared/AvailabilityDropdown';
import { BiChevronDown } from 'react-icons/bi';
import { setRateInUserCurrency } from '../features/user/userSlice';
import AvailabilityCalendar from '../components/shared/AvailabilityCalendar';
import Container from '../components/shared/Container';

const duration = [15, 30, 45, 60, 75, 90, 105, 120];
// Convert each hour to an ISO string time
const isoTimes = [...Array(24).keys()].map((hour) => hour);
const { REACT_APP_API_URL } = getEnvVars();

const Book = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const paramValue = searchParams.get('share');

  const { availabilitySlots, availabilityStatus, expert } = useSelector(
    (state) => state.expert,
  );
  const {
    requestStatus: sessionStatus,
    session,
    // price,
  } = useSelector((state) => state.session);
  const { currency, rateInUserCurrency } = useSelector((state) => state.user);
  const { id } = useParams();

  const [screen, setScreen] = useState(false);
  const [sessionState, setSessionState] = useState({
    isScheduled: screen !== true ? true : '',
    startsAt: '',
    endsAt: '',
    expertId: id,
    title: '',
    agenda: '',
  });
  const [reviews, setReviews] = useState([]);
  const [currDuration, setCurrDuration] = useState(0);
  const [currTime, setCurrTime] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [similarExperts, setSimilarExperts] = useState([]);
  const [availableTime, setAvailableTime] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let payload;

    // Book a call
    if (screen === false) {
      if (!selectedTime) {
        toast.warn('select availability');
      } else {
        payload = {
          ...sessionState,
          startsAt: selectedTime,
          endsAt: new Date(
            new Date(selectedTime).setMinutes(
              new Date(selectedTime).getMinutes() + currDuration,
            ),
          ).toISOString(),
          duration: currDuration,
          isScheduled: false,
        };
      }
    } else {
      // Request a call
      payload = {
        ...sessionState,
        startsAt: new Date(
          new Date(currTime).setHours(selectedTime),
        ).toISOString(),
        endsAt: new Date(
          new Date(new Date(currTime).setHours(selectedTime)).setMinutes(
            new Date(currTime).getMinutes() + currDuration,
          ),
        ).toISOString(),
        duration: currDuration,
      };
    }

    dispatch(saveSession(payload));
    dispatch(createSession(payload));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSessionState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    dispatch(setRateInUserCurrency(currency));
  }, []);

  useEffect(() => {
    if (sessionStatus === 'success' && !screen && session?.id) {
      navigate(`/book/${session?.id}/payment`);
      dispatch(resetSession());
    } else if (sessionStatus === 'success' && screen) {
      navigate(`/request/confirmation`);
      dispatch(resetSession());
    }

    dispatch(resetSession({ payload: false }));

    return () => {
      if (sessionStatus === 'success') {
        dispatch(resetSession());
      }
    };
  }, [sessionStatus]);

  //Get expert availability
  useEffect(() => {
    dispatch(resetAvailability());
    dispatch(
      getAvailability({
        id,
        month: currTime.getMonth(),
        year: currTime.getFullYear(),
        day: currTime.getDate(),
      }),
    );
    //eslint-disable-next-line
  }, [currTime]);

  useEffect(() => {
    (async () => {
      const api = `${REACT_APP_API_URL}/v1/reviews/?expertId=${id}&limit=5&skip=0`;
      await axios
        .get(api)
        .then((result) => {
          const reviews = result.data.data.docs;
          setReviews(reviews);
        })
        .catch(function (error) {
          console.error(error);
        });
    })();
  }, []);

  //Get Expert data
  useEffect(() => {
    dispatch(getExpert({ id: id }));
    if (paramValue) {
      sessionStorage.setItem('redirectUrl', location.pathname);
    }
  }, []);

  useEffect(() => {
    if (expert) {
      (async () => {
        const api = `${REACT_APP_API_URL}/v1/experts/search?industry=${expert?.industry}`;
        await axios
          .get(api)
          .then((result) => {
            const similarExperts = result.data.data.docs;
            // This filters out the current expert, leaving experts in similar category.
            setSimilarExperts(
              similarExperts.filter((item) => item.id !== expert?.id),
            );
          })
          .catch(function (error) {
            console.error(error);
          });
      })();
    }
  }, [expert]);

  useEffect(() => {
    if (availabilitySlots[formatDateToYYYYMMDD(currTime)]) {
      setAvailableTime(availabilitySlots[formatDateToYYYYMMDD(currTime)]);
    } else {
      setAvailableTime([]);
    }
  }, [availabilitySlots]);

  const handleDuration = (idx) => {
    if (expert?.minutesPerUnitSession <= idx) {
      setCurrDuration(idx);
    }
  };

  // TODO Place this in a transforms.util.js file and import it
  // covert date to YYYY-MM-DD date format
  function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  // const handleDate = (e) => {
  //   const selectedDate = new Date(e.target.value);
  //   setCurrTime(selectedDate);
  // };

  const handleTime = (idx) => {
    setSelectedTime(idx);
  };

  const getFullName = (expert) => {
    return [expert?.firstName, expert?.lastName].filter(Boolean).join(' ');
  };

  const handleGetAvailability = (formattedDate) => {
    if (availabilitySlots[formattedDate]) {
      setAvailableTime(availabilitySlots[formattedDate]);
    } else {
      setCurrTime(new Date(formattedDate));
    }
  };

  return (
    <ProtectedRoute>
      <ToastContainer />
      <Layout>
        <Container>
          <div className="lg:grid lg:grid-cols-6 grid-rows-1 gap-4 py-12 sm:py-20">
            <div className="lg:col-span-3 mb-10 lg:mb-0">
              <div className="bg-white text-gray-500 p-4 sm:p-6 md:p-8 rounded-lg md:rounded-2xl max-w-xl mx-auto lg:mx-0">
                <div className="sm:h-[30rem] h-[23rem] rounded md:rounded-lg">
                  <img
                    src={expert?.profilePhoto}
                    alt=""
                    className="w-full rounded md:rounded-lg h-full object-cover object-top"
                  />
                </div>

                <div className="mt-4 flex gap-2">
                  <p className="text-black text-lg font-bold name">
                    {getFullName(expert)}
                  </p>
                  <img src={star} alt="rating-icon" className="ml-auto" />
                  <span className="text-black font-semibold pt-1">
                    {expert?.rating}
                  </span>
                </div>
                <p>{expert?.jobTitle}</p>
              </div>

              <div className="bg-white text-gray-500 p-6 md:p-8 mb-8 sm:mb-0 rounded-lg md:rounded-2xl lg:max-w-xl mt-8 lg:min-h-[17rem] mx-auto lg:mx-0">
                {expert?.skills.length > 0 && (
                  <div className="flex text-xs text-gray-700 py-2 font-medium">
                    {expert?.skills.map((skill) => (
                      <p
                        className="bg-gray-100 px-2 py-1 h-min rounded-xl"
                        key={skill}
                      >
                        {skill}
                      </p>
                    ))}
                  </div>
                )}

                <p className="text-black text-lg font-semibold name leading-8">
                  Bio
                </p>

                <p className="text-sm sm:text-base text-gray-500 py-2">
                  {expert?.bio}
                </p>

                <div className="w-full mb-4 flex gap-3 items-center">
                  {expert?.linkedinUrl && (
                    <button className="!bg-textPurple/10 text-textPurple rounded md:rounded-lg text-sm">
                      <a
                        href={expert?.linkedinUrl}
                        className="flex gap-2 items-center justify-between px-2 py-0.5 "
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaLinkedinIn className="text-textPurple text-sm" />
                        LinkedIn
                      </a>
                    </button>
                  )}

                  {expert?.twitterUrl && (
                    <button className="!bg-textPurple/10 text-textPurple rounded md:rounded-lg text-sm">
                      <a
                        href={expert?.twitterUrl}
                        className="flex gap-2 items-center justify-between px-2 py-0.5 "
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FaTwitter className="text-textPurple text-sm" />
                        Twitter
                      </a>
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="lg:col-span-3">
              <form onSubmit={handleSubmit}>
                <div className="p-6 md:p-8 bg-white rounded-lg md:rounded-2xl shadow">
                  {screen !== false ? (
                    <div className="flex text-sm sm:text-base justify-between pb-5 sm:pb-6">
                      <p
                        className="font-semibold sm:text-sm underline text-[#270058] underline-offset-4 sm:underline-offset-8 cursor-pointer"
                        onClick={() => setScreen(true)}
                      >
                        Request call
                      </p>

                      <p
                        className="font-semibold sm:text-sm underline text-gray-400 underline-offset-4 sm:underline-offset-8 cursor-pointer"
                        onClick={() => setScreen(false)}
                      >
                        Book call
                      </p>
                    </div>
                  ) : (
                    <div className="flex text-sm sm:text-base justify-between pb-5 sm:pb-6">
                      <p
                        className="font-semibold text-sm underline text-gray-400 underline-offset-4 sm:underline-offset-8 cursor-pointer"
                        onClick={() => setScreen(true)}
                      >
                        Request call
                      </p>

                      <p
                        className="font-semibold text-sm underline text-[#270058] underline-offset-4 sm:underline-offset-8 cursor-pointer"
                        onClick={() => setScreen(false)}
                      >
                        Book call
                      </p>
                    </div>
                  )}

                  <div className="mb-6 flex items-center gap-2 sm:gap-4">
                    <p className="border-2 border-black/70 rounded-full p-1 inline-flex items-center justify-center text-xs sm:text-sm font-bold sm:w-5 w-4 sm:h-5 h-4">
                      i
                    </p>
                    {screen ? (
                      <p className="sm:text-sm text-xs truncate">
                        Choose a time convenient for you and request for a call
                        with an expert?.
                      </p>
                    ) : (
                      <p className="sm:text-sm text-xs truncate">
                        Book a call at an available time of the expert that’s
                        convenient for you.
                      </p>
                    )}
                  </div>

                  <div className="">
                    <label
                      htmlFor=""
                      className="text-gray-700 font-semibold text-sm sm:text-base"
                    >
                      Call title
                    </label>
                    <input
                      type="text"
                      name="title"
                      placeholder="Add Title"
                      className="mt-2 py-2 px-4 w-full border border-gray-300 rounded md:rounded-lg text-sm sm:text-base"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mt-6">
                    <label
                      htmlFor=""
                      className="text-gray-700 font-semibold text-sm sm:text-base"
                    >
                      Call agenda
                    </label>
                    <input
                      type="text"
                      name="agenda"
                      placeholder="Add Call Agenda"
                      className="mt-2 py-2 px-4 w-full border border-gray-300 rounded md:rounded-lg text-sm sm:text-base"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mt-6">
                    <p className="text-gray-700 font-semibold flex-none pb-2 text-sm sm:text-base">
                      Select time
                    </p>

                    <div className="sm:flex sm:gap-2 items-center justify-between">
                      <select
                        name=""
                        id=""
                        className="text-gray-500 text-xs border border-gray-200 rounded-md py-2 px-8 sm:w-auto block w-full"
                      >
                        <option value="">(GMT+1 Lagos)</option>
                      </select>

                      <AvailabilityCalendar
                        availabilitySlots={availabilitySlots}
                        handleGetAvailability={handleGetAvailability}
                        expertName={expert?.firstName}
                      />

                      {/* <input
                        type="date"
                        name="date"
                        id="date"
                        onChange={handleDate}
                        value={formatDateToYYYYMMDD(currTime)}
                        className="text-gray-500 text-xs border border-gray-200 rounded-md py-2 px-2 sm:px-4 sm:w-auto cursor-pointer my-3 sm:my-0 inline"
                      /> */}

                      <AvailabilityDropdown
                        options={screen ? isoTimes : availableTime}
                        handleSelect={handleTime}
                        booked={screen}
                        className={
                          'top-12 left-2 max-h-[12rem] overflow-scroll'
                        }
                      >
                        <div className="text-gray-500 text-xs flex gap-3 items-center justify-between border border-gray-200 rounded-md py-2 px-2 sm:px-4 mx-1 sm:w-auto">
                          {selectedTime
                            ? new Date(selectedTime).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                              })
                            : 'Availability'}
                          {availabilityStatus === 'loading' ? (
                            <ReactLoading
                              type="spin"
                              color="#41008B"
                              className=""
                              height={18}
                              width={18}
                            />
                          ) : (
                            <BiChevronDown className="text-xl" />
                          )}
                        </div>
                      </AvailabilityDropdown>
                    </div>

                    {availableTime.length === 0 &&
                      !screen &&
                      availabilityStatus === 'success' && (
                        <p className="mt-2 text-red-400 text-xs">
                          <span className="capitalize">{`${expert?.firstName} ${expert?.lastName} `}</span>
                          isn&apos;t available on{' '}
                          {formatDateToYYYYMMDD(currTime)}
                        </p>
                      )}
                  </div>

                  <p className="text-gray-700 font-semibold w-2/3 lg:w-10/12 md:pt-6 text-sm sm:text-base">
                    Select Duration
                  </p>

                  <div className="flex pt-2">
                    <div className="grid grid-cols-3 md:grid-cols-4 gap-2 lg:gap-4 w-full">
                      {duration.map((item, id) => (
                        <button
                          type="button"
                          className={`whitespace-nowrap py-2.5 px-2 sm:px-4 lg:px-2 text-xs sm:text-sm border border-gray-300 rounded md:rounded-lg ${
                            currDuration === item
                              ? 'bg-[#00164F] text-white'
                              : expert?.minutesPerUnitSession > item
                                ? 'bg-gray-200 text-textLight/50'
                                : 'bg-white text-gray-600'
                          }`}
                          key={id}
                          onClick={() => handleDuration(item)}
                        >
                          {item} Mins
                        </button>
                      ))}
                    </div>
                  </div>

                  <div className="mt-6">
                    {selectedTime && currDuration > 1 && (
                      <p className="text-[#00164F] text-xs sm:text-sm">
                        Your call is scheduled to hold from{' '}
                        {screen === false
                          ? new Date(selectedTime).toLocaleTimeString()
                          : new Date(
                              new Date(currTime).setHours(selectedTime),
                            ).toLocaleTimeString()}{' '}
                        to{' '}
                        {screen === false
                          ? new Date(
                              new Date(selectedTime).setMinutes(
                                new Date(selectedTime).getMinutes() +
                                  currDuration,
                              ),
                            ).toLocaleTimeString()
                          : new Date(
                              new Date(
                                new Date(currTime).setHours(selectedTime),
                              ).setMinutes(
                                new Date(currTime).getMinutes() + currDuration,
                              ),
                            ).toLocaleTimeString()}
                      </p>
                    )}
                  </div>

                  <div className="sm:flex mt-6 gap-4">
                    <p className="text-xl md:text-2xl font-semibold pb-2 text-gray-800 mr-auto self-center">
                      {currDuration === 0 || rateInUserCurrency == null
                        ? ''
                        : new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency,
                          }).format(
                            (currDuration / 15) *
                              expert?.fee *
                              rateInUserCurrency,
                          )}
                    </p>

                    <button
                      className="bg-[#00164F] text-white flex items-center justify-center gap-2 rounded py-3 sm:px-0 px-5 w-full sm:w-56 text-sm sm:text-base"
                      onSubmit={handleSubmit}
                      type="submit"
                      id="btnAction"
                    >
                      {sessionStatus === 'loading' && (
                        <ReactLoading
                          type="cylon"
                          color="#fff"
                          className=""
                          height={20}
                          width={20}
                        />
                      )}
                      {screen !== false ? 'Request call' : 'Book call'}{' '}
                    </button>
                  </div>
                </div>
              </form>

              <div className="bg-white text-gray-500 p-6 md:p-8 rounded-lg md:rounded-2xl mt-8">
                <h3 className="text-black text-lg font-semibold leading-8">
                  What to expect in a minimum of 15mins duration
                </h3>

                <div className="">
                  <ul className="list-disc text-sm sm:text-base pl-4 flex flex-col space-y-1 mt-2">
                    <li>
                      <p>ask three or more questions</p>
                    </li>
                    <li>
                      <p>advice on brand building + strategy</p>
                    </li>
                    <li>
                      <p>
                        tips on how we got our products into major retailers
                      </p>
                    </li>
                    <li>
                      <p>
                        thoughtful insights on how to get motivated and
                        differentiated
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="w-full">
              <p className="text-xl lg:text-4xl font-semibold pt-6 pb-4 pr-2 name leading-8">
                Reviews
              </p>
              <div className="">
                {reviews.length !== 0 ? (
                  reviews.map((review, key) => (
                    <div
                      className="rounded md:rounded-lg p-4 my-4 bg-white"
                      key={key + review?._id}
                    >
                      <div className="flex">
                        <div className="bg-gray-300 rounded-full p-2.5">
                          <FiUser size={25} className="text-purple-900" />
                        </div>
                        <div className="grid pl-4">
                          <h5 className="font-semibold text-xl">
                            {review?.client?.firstName +
                              ' ' +
                              review?.client?.lastName}
                          </h5>
                          <div className="flex">
                            <img
                              src={starsfull}
                              alt="rating-icon"
                              className=""
                            />
                            {review?.rating}
                            <p className="text-gray-400 font-normal text-xs self-center ml-3">
                              {new Date(review?.updatedAt).toDateString()}
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="pt-4">{review?.comment}</p>
                    </div>
                  ))
                ) : (
                  <div className="py-24 text-center">No reviews yet</div>
                )}
              </div>
            </div>
          </div>

          <div className="pt-10 pb-20">
            <h5 className="font-semibold lg:text-4xl text-xl">
              Similar Experts
            </h5>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-4 xl:gap-10 mt-10">
              {similarExperts?.length !== 0 ? (
                similarExperts.slice(0, 3).map((expert, key) => (
                  <div
                    className="px-4 py-6 bg-white text-neutral-400 shadow-sm rounded-lg md:rounded-2xl h-max md:h-68"
                    key={expert.id + key}
                  >
                    <div className="flex">
                      <img
                        src={expert?.profilePhoto}
                        alt=""
                        className="h-20 rounded md:rounded-lg"
                      />
                      <div className="grid pl-2">
                        <p className="text-[22px] font-bold text-black">
                          {expert?.firstName + ' ' + expert?.lastName}
                        </p>
                        <p>
                          {expert?.jobTitle.length > 16
                            ? expert?.jobTitle.slice(0, 16) + '...'
                            : expert?.jobTitle}
                        </p>
                        <div className="flex">
                          <img
                            src={star}
                            alt="rating-icon"
                            className="pl-2 py-1"
                          />
                          <span className="text-lg font-bold self-center text-black">
                            {expert?.rating}
                          </span>
                        </div>
                      </div>
                    </div>
                    <p className="text-sm py-2 pr-8 lg:pr-0">
                      {expert?.bio.length > 80 ? (
                        expert?.bio.slice(0, 80) + '...'
                      ) : (
                        <>{expert?.bio}</>
                      )}
                    </p>
                    <div className="flex gap-2 text-xs md:text-sm">
                      <p className="font-medium bg-neutral-200 text-gray-800 rounded md:rounded-lg px-3 lg:px-1.5 xl:px-3 py-0.5 h-min">
                        {expert?.skills[0]?.length > 5 ? (
                          expert?.skills[0].slice(0, 12) + '...'
                        ) : (
                          <>{expert?.skills[0]}</>
                        )}
                      </p>
                      <p className="font-medium bg-neutral-200 text-gray-800 rounded md:rounded-lg px-3 lg:px-1.5 xl:px-3 py-0.5 h-min">
                        {expert?.skills[1]?.length > 5 ? (
                          expert?.skills[1].slice(0, 12) + '...'
                        ) : (
                          <>{expert?.skills[1]}</>
                        )}
                      </p>
                    </div>
                    <div className="flex pt-4">
                      <p className="self-center">
                        From{' '}
                        <span className="text-black font-semibold">
                          {rateInUserCurrency && expert
                            ? new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency,
                              }).format(expert.fee * rateInUserCurrency)
                            : null}
                        </span>
                      </p>
                      <button
                        className="bg-[#270058] text-sm font-medium text-white py-2.5 px-6 rounded md:rounded-lg ml-auto"
                        onClick={() =>
                          window.location.reload(
                            navigate('/book/' + expert?.id),
                          )
                        }
                      >
                        Book call
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="opacity-0">hidden</div>
                  <div className="text-center pt-12">No Similar Experts</div>
                  <div className="opacity-0">hidden</div>
                </>
              )}
            </div>
          </div>
        </Container>
      </Layout>
    </ProtectedRoute>
  );
};

export default Book;
