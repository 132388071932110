import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import addMinutes from 'date-fns/addMinutes';
import { BiChevronDown } from 'react-icons/bi';
import { ToastContainer } from 'react-toastify';
import ReactLoading from 'react-loading';
import starsfull from '../assets/icons/starsfull.svg';
import star from '../assets/icons/starfilled.svg';
import { FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailability,
  getExpert,
  resetAvailability,
} from '../features/experts/expertSlice';
import {
  claimGiftSession,
  getGiftSession,
  getGiftedSession,
  resetSession,
} from '../features/session/sessionSlice';
import AvailabilityDropdown from '../components/shared/AvailabilityDropdown';
import ProtectedRoute from '../utils/ProtectedRoute';
import Layout from '../components/layout/layout';
import { getEnvVars } from '../app/env';
import { setRateInUserCurrency } from '../features/user/userSlice';
import Container from '../components/shared/Container';

const { REACT_APP_API_URL } = getEnvVars();

const ClaimGift = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const { availabilitySlots, availabilityStatus, expert } = useSelector(
    (state) => state.expert,
  );
  const { giftSessions, claimedGiftStatus } = useSelector(
    (state) => state.session,
  );
  const { currency, rateInUserCurrency } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getGiftSession({ admin: false }));
  }, []);
  useEffect(() => {
    dispatch(setRateInUserCurrency(currency));
  }, []);

  function formatDateToYYYYMMDD(date) {
    date = new Date(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  const [state, setState] = useState({
    startsAt: '',
    giftSessionId: '',
    title: '',
    agenda: '',
  });
  const [reviews, setReviews] = useState([]);
  const [claimedState, setClaimedState] = useState('');
  const [currentTime, setCurrentTime] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState('');
  const [similarExperts, setSimilarExperts] = useState([]);
  const [availableTime, setAvailableTime] = useState([]);
  const filteredValue = useMemo(
    () =>
      giftSessions?.filter(
        (filterX) =>
          filterX?.recipient?.id === localStorage.getItem('userId') &&
          filterX?.isClaimed === false,
      ),
    [],
  );

  const handleGiftClaiming = async (e) => {
    e.preventDefault();
    let payload;

    // Claim gift
    payload = {
      ...state,
      startsAt: selectedTime,
      giftSessionId: filteredValue[0]?.id,
    };

    dispatch(
      getGiftedSession({
        firstName: filteredValue[0]?.expert?.firstName,
        lastName: filteredValue[0]?.expert?.lastName,
      }),
    );
    dispatch(claimGiftSession(payload));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  useEffect(() => {
    // TODO Please request for an API endpoint to get a single session.
    // In fact, create a task for it @tracemycodes
    setClaimedState(claimedGiftStatus);
    if (claimedGiftStatus === 'success') {
      navigate(`/gift/confirmation/${filteredValue[0]?.expert?.id}`);
      dispatch(resetSession());
    }
  }, [claimedGiftStatus, claimedState]);

  useEffect(() => {
    if (availabilitySlots[formatDateToYYYYMMDD(currentTime)]) {
      setAvailableTime(availabilitySlots[formatDateToYYYYMMDD(currentTime)]);
    } else {
      setAvailableTime([]);
    }
  }, [availabilitySlots]);

  //Get expert availability
  useEffect(() => {
    dispatch(resetAvailability());
    dispatch(
      getAvailability({
        id,
        month: currentTime.getMonth(),
        year: currentTime.getFullYear(),
        day: currentTime.getDate(),
      }),
    );
    //eslint-disable-next-line
  }, [currentTime]);

  useEffect(() => {
    (async () => {
      const api = `${REACT_APP_API_URL}/v1/reviews/?expertId=${id}&limit=5&skip=0`;
      await axios
        .get(api)
        .then((result) => {
          const reviews = result.data.data.docs;
          setReviews(reviews);
        })
        .catch(function (error) {
          console.error(error);
        });
    })();
  }, []);

  //Get Expert data
  useEffect(() => {
    dispatch(getExpert({ id: id }));
  }, []);

  useEffect(() => {
    (async () => {
      const industry = localStorage.getItem('userSearch');
      const api = `${REACT_APP_API_URL}/v1/experts/search?industry=${industry}`;
      await axios
        .get(api)
        .then((result) => {
          // you don't need to use `await` when you use `.then`
          const similarExperts = result.data.data.docs;
          setSimilarExperts(similarExperts);
        })
        .catch(function (error) {
          console.error(error);
        });
    })();
  }, []);

  const handleDate = (e) => {
    const selectedDate = new Date(e.target.value);
    setCurrentTime(selectedDate);
  };

  const handleTime = (value) => {
    setSelectedTime(value);
  };

  return (
    <ProtectedRoute>
      <div className="schedule mx-auto altNav">
        <ToastContainer />
        <Layout>
          <Container>
            <div className="lg:grid lg:grid-cols-6 grid-rows-1 py-12">
              <div className="py-8 lg:col-span-3">
                <div className="bg-white text-gray-500 h-full card lg:mx-20 p-6 md:p-8 rounded-2xl mx-auto">
                  <div className=" h-[27rem] object-cover rounded-lg">
                    <img
                      src={expert?.profilePhoto}
                      alt=""
                      className="w-full rounded-lg h-full"
                    />
                  </div>

                  <div className="pt-2 flex gap-2">
                    <p className="text-black text-lg font-bold pr-2 name">
                      {expert?.firstName + ' ' + expert?.lastName}
                    </p>
                    <img src={star} alt="rating-icon" className="ml-auto" />
                    <span className="text-black text-sm font-semibold pt-1">
                      {expert?.rating}
                    </span>
                  </div>

                  <p>{expert?.jobTitle}</p>

                  <p className="text-black font-semibold pr-2 pt-6 name leading-8">
                    About Me
                  </p>

                  <p className="text-sm text-gray-500 py-2">{expert?.bio}</p>

                  <span className="flex text-xs text-gray-700 py-2 font-medium">
                    <p className="bg-gray-100 px-2 py-1 h-min rounded-xl">
                      {expert?.skills ? expert?.skills[0] : null}
                    </p>
                    <p className="bg-gray-100 px-2 py-1 h-min mx-1 rounded-xl">
                      {expert?.skills ? expert?.skills[1] : null}
                    </p>
                    <p className="bg-gray-100 px-2 py-1 h-min rounded-xl">
                      {expert?.skills ? expert?.skills[2] : null}
                    </p>
                  </span>
                </div>
              </div>

              {/* TODO THis form should not be submitted until all inputs have been filled */}
              <form
                className="py-8 lg:pt-0 mt-8 lg:col-span-3"
                onSubmit={handleGiftClaiming}
              >
                <div className="lg:mr-20 p-6 md:p-8 h-full card bg-white rounded-2xl">
                  <p className="font-semibold underline text-[#270058] underline-offset-8 cursor-pointer mb-8">
                    Book call
                  </p>

                  <div className="">
                    <label htmlFor="" className="text-gray-700 font-semibold">
                      Call title
                    </label>
                    <br />
                    <input
                      type="text"
                      name="title"
                      placeholder="Add Title"
                      className=" mt-2 py-2.5 px-4 w-full border border-gray-300 rounded-lg"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mt-6">
                    <label htmlFor="" className="text-gray-700 font-semibold">
                      Call agenda
                    </label>
                    <br />
                    <input
                      type="text"
                      name="agenda"
                      placeholder="Add Call Agenda"
                      className="mt-2 py-2.5 px-4 w-full border border-gray-300 rounded-lg"
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="mt-6">
                    <p className="text-gray-700 font-semibold flex-none pb-2 text-sm sm:text-base">
                      Select time
                    </p>

                    <div className="sm:flex sm:gap-2 items-center justify-between">
                      <select
                        name=""
                        id=""
                        className="text-gray-500 text-xs border border-gray-200 rounded-md py-2 px-8 sm:w-auto block w-full"
                      >
                        <option value="">(GMT+1 Lagos)</option>
                      </select>

                      <input
                        type="date"
                        name="date"
                        id="date"
                        onChange={handleDate}
                        value={formatDateToYYYYMMDD(currentTime)}
                        className="text-gray-500 text-xs border border-gray-200 rounded-md py-2 px-2 sm:px-4 sm:w-auto cursor-pointer my-3 sm:my-0 inline"
                      />

                      <AvailabilityDropdown
                        options={availableTime}
                        handleSelect={handleTime}
                        booked={false}
                        className={
                          'top-12 left-2 max-h-[12rem] overflow-scroll'
                        }
                      >
                        <div className="text-gray-500 text-xs flex gap-3 items-center justify-between border border-gray-200 rounded-md py-2 px-2 sm:px-4 mx-1 sm:w-auto">
                          {selectedTime
                            ? new Date(selectedTime).toLocaleTimeString([], {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: true,
                              })
                            : 'Availability'}
                          {availabilityStatus === 'loading' ? (
                            <ReactLoading
                              type="spin"
                              color="#41008B"
                              className=""
                              height={18}
                              width={18}
                            />
                          ) : (
                            <BiChevronDown className="text-xl" />
                          )}
                        </div>
                      </AvailabilityDropdown>
                    </div>

                    {availableTime?.length === 0 &&
                      availabilityStatus === 'success' && (
                        <p className="mt-2 text-red-400 text-xs">
                          <span className="capitalize">{`${expert?.firstName} ${expert?.lastName} `}</span>
                          isn&apos;t available on{' '}
                          {formatDateToYYYYMMDD(currentTime)}
                        </p>
                      )}
                  </div>

                  <p className="text-gray-700 text-sm pt-2 md:pt-4">
                    {filteredValue && filteredValue[0]?.minutes
                      ? `You were gifted a ${filteredValue[0]?.minutes} minutes session with ${expert?.firstName} ${expert?.lastName}`
                      : ``}
                  </p>

                  <p className="text-gray-700 text-sm ">
                    {selectedTime
                      ? `Your session will hold from ${new Date(
                          selectedTime,
                        ).toLocaleTimeString()} to ${addMinutes(
                          selectedTime,
                          filteredValue[0]?.minutes,
                        ).toLocaleTimeString()} on ${new Date(
                          selectedTime,
                        ).toDateString()}`
                      : `Please select your preferred start time from the expert's availability`}
                  </p>

                  <div className="flex mt-8 gap-4">
                    {/* TODO THis button should be disabled until all inputs have been filled */}
                    <button
                      className="bg-[#00164F] text-white flex items-center justify-center gap-2 rounded py-3 px-5 w-full"
                      onSubmit={handleGiftClaiming}
                      type="submit"
                      id="btnAction"
                    >
                      {claimedState === 'loading' && (
                        <ReactLoading
                          type="cylon"
                          color="#fff"
                          className=""
                          height={20}
                          width={20}
                        />
                      )}
                      Claim gift session
                    </button>
                  </div>
                </div>
              </form>

              <div className="lg:col-span-5">
                <div className="lg:ml-20 w-full">
                  <p className="text-xl font-semibold pt-6 pb-4 pr-2 name leading-8">
                    Reviews
                  </p>

                  <div className="">
                    {reviews.length !== 0 ? (
                      reviews.map((review, key) => (
                        <div
                          className="card rounded-lg p-4 my-4 bg-white"
                          key={key}
                        >
                          <div className="flex">
                            <div className="bg-gray-300 rounded-full h-12 w-12 flex items-center justify-center">
                              <FiUser size={25} className="text-purple-900" />
                            </div>
                            <div className="grid pl-4">
                              <h5 className="font-semibold text-xl">
                                {review.client.firstName +
                                  ' ' +
                                  review.client.lastName}
                              </h5>
                              <div className="flex">
                                <img
                                  src={starsfull}
                                  alt="rating-icon"
                                  className=""
                                />
                                {review.rating}
                                <p className="text-gray-400 font-normal text-xs self-center ml-3">
                                  {new Date(review.updatedAt).toDateString()}
                                </p>
                              </div>
                            </div>
                          </div>
                          <p className="pt-4">{review.comment}</p>
                        </div>
                      ))
                    ) : (
                      <div className="py-24 text-center">No reviews yet</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="pt-10 pb-20 lg:mx-20 lg:col-span-6">
                <h5 className="font-semibold text-4xl">Similar Experts</h5>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 lg:gap-4 xl:gap-10 mt-10">
                  {similarExperts.length !== 0 ? (
                    similarExperts.slice(0, 3).map((expert, key) => (
                      <div
                        className="px-4 py-6 bg-white text-neutral-400 shadow-sm rounded-2xl h-max md:h-68"
                        key={key}
                      >
                        <div className="flex">
                          <img
                            src={expert.profilePhoto}
                            alt=""
                            className="h-20 rounded-lg"
                          />
                          <div className="grid pl-2">
                            <p className="text-[22px] font-bold text-black">
                              {expert.firstName + ' ' + expert.lastName}
                            </p>
                            <p>
                              {expert.jobTitle.length > 16
                                ? expert.jobTitle.slice(0, 16) + '...'
                                : expert.jobTitle}
                            </p>
                            <div className="flex">
                              <img
                                src={star}
                                alt="rating-icon"
                                className="pl-2 py-1"
                              />
                              <span className="text-lg font-bold self-center text-black">
                                {expert.rating}
                              </span>
                            </div>
                          </div>
                        </div>
                        <p className="text-sm py-2 pr-8 lg:pr-0">
                          {expert.bio.length > 80 ? (
                            expert.bio.slice(0, 80) + '...'
                          ) : (
                            <>{expert.bio}</>
                          )}
                        </p>
                        <div className="flex gap-2 text-xs md:text-sm">
                          <p className="font-medium bg-neutral-200 text-gray-800 rounded-lg px-3 lg:px-1.5 xl:px-3 py-0.5 h-min">
                            {expert.skills[0].length > 5 ? (
                              expert.skills[0].slice(0, 12) + '...'
                            ) : (
                              <>{expert.skills[0]}</>
                            )}
                          </p>
                          <p className="font-medium bg-neutral-200 text-gray-800 rounded-lg px-3 lg:px-1.5 xl:px-3 py-0.5 h-min">
                            {expert.skills[1].length > 5 ? (
                              expert.skills[1].slice(0, 12) + '...'
                            ) : (
                              <>{expert.skills[1]}</>
                            )}
                          </p>
                        </div>
                        <div className="flex pt-4">
                          <p className="self-center">
                            From{' '}
                            <span className="text-black font-semibold">
                              {rateInUserCurrency && expert
                                ? new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency,
                                  }).format(expert.fee * rateInUserCurrency)
                                : null}
                            </span>
                          </p>
                          <button
                            className="bg-[#270058] text-sm font-medium text-white py-2.5 px-6 rounded-lg ml-auto"
                            onClick={() =>
                              window.location.reload(
                                navigate('/book/' + expert.id),
                              )
                            }
                          >
                            Book call
                          </button>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div className="opacity-0">hidden</div>
                      <div className="text-center pt-12">
                        No Similar Experts
                      </div>
                      <div className="opacity-0">hidden</div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Container>
        </Layout>
      </div>
    </ProtectedRoute>
  );
};

export default ClaimGift;
