import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout/accountLayout';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getExpertBank, getProfile, resetCalendar, updateProfile } from '../../features/experts/expertSlice';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { getEnvVars } from '../../app/env';
import ReactLoading from 'react-loading';
import SelectMenu from '../../components/shared/SelectMenu';
import { FiChevronDown } from 'react-icons/fi';
import { toast } from 'react-toastify';

const { REACT_APP_API_URL } = getEnvVars();

const Payment = () => {
  const dispatch = useDispatch();
  const { expert, calendarStatus, bankDetails } = useSelector((state) => state.expert);
  const [account, setAccount] = useState(0);
  const [fee, setFee] = useState(0);
  const [token /*setToken*/] = useState(() => localStorage.exptToken);
  const [banks, setBanks] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [loading, setLoading] = useState(false);
  const [accLoading, setAccLoading] = useState(false);
  const [accountNumber, setAccountNumber] = useState('');
  const [bank, setBank] = useState();
  const [fees, setFees] = useState(0);
  const [, /*displayBank*/ setDisplayBank] = useState('');

  const options = [
    { id: 1, value: 'Nigeria', code: 'NG' },
    { id: 2, value: 'Ghana', code: 'GH' },
    { id: 3, value: 'Uganda', code: 'UG' },
    { id: 4, value: 'Kenya', code: 'KE' },
    { id: 5, value: 'Tanzania', code: 'TZ' },
    { id: 6, value: 'South Africa', code: 'ZA' },
  ];

  const headers = {
    Authorization: 'Bearer ' + token,
  };

  function openFee() {
    setFee(1);
  }

  function openBank() {
    setAccount(1);
  }

  const handleSelectChange = (target) => {
    setSelectedValue(target);
  };

  const handleAccount = (e) => {
    setAccountNumber(e.target.value);
  };

  const handleBankSelect = (bank) => {
    setBank(bank);
  };

  useEffect(() => {
    dispatch(getProfile());
    dispatch(getExpertBank())
  }, []);

  useEffect(() => {
    if (calendarStatus === 'success') {
      setFee(0);
    }
    return () => {
      dispatch(resetCalendar())
    }
  }, [calendarStatus]);

  useEffect(() => {
    const getData = async () => {
      const api = `${REACT_APP_API_URL}/v1/banks?countryCode=${selectedValue?.code}`;
      setLoading(true);
      await axios
        .get(api, { headers })
        .then((res) => {
          const dataInfo = res.data.data;
          setLoading(false);
          setBanks(dataInfo);
        })
        .catch(function (error) {
          console.error(error);
          toast.error(`${selectedValue.value} banks not available`);
          setLoading(false);
        });
        setLoading(false)
    };
    if (selectedValue?.code !== '' && selectedValue) {
      getData();
    }
  }, [token, selectedValue]);

  const saveBanksUrl = `${REACT_APP_API_URL}/v1/banks`;

  const submitForm = () => {
    const data = {
      accountNumber: accountNumber,
      bankCode: bank.code,
      bankName: bank.name,
      branchCode: bank.code,
      country: selectedValue.code,
    };
    setAccLoading(true);
    axios
      .put(saveBanksUrl, data, { headers })

      .then(() => {
        const newValue = account + 1;
        setAccLoading(false);
        setAccount(newValue);
      })

      .catch((error) => {
        setAccLoading(false);
        console.error('There was an error !', error.message);
      });

    setDisplayBank(data);
    localStorage.setItem('exptBankName', data.bankName);
    localStorage.setItem('exptBankAcc', accountNumber);
  };

  const handleFee = (e) => {
    setFees(e.target.value);
  };

  const submitFee = () => {
    const data = {
      fee: parseInt(fees),
    };

    dispatch(updateProfile(data));
  };

  return (
    <ProtectedRoute>
      <Layout>
        <div className="grid gap-y-6">
          <div className="card grid gap-y-4 ">
            <p className="text-xl">Call fee</p>
            {fee === 0 ? (
              <>
                <p className="font-semibold text-[28px] leading-9">
                  $ {expert?.fee}
                </p>

                <p className="text-neutral-400 text-sm">
                  {!expert?.fee && 'No fee has been set'}
                </p>

                <hr />

                <p
                  className="text-[#792AE2] font-medium text-sm cursor-pointer"
                  onClick={openFee}
                >
                  Set call fee
                </p>
              </>
            ) : fee === 1 ? (
              <form onSubmit={submitForm} className="grid gap-y-4">
                <input
                  onChange={handleFee}
                  type="text"
                  className="w-full border border-[#792AE2] rounded-lg p-2 font-semibold text-[28px] leading-9"
                  placeholder="$0.00"
                />

                <small className="text-neutral-400">
                  The call fee is calculated based on a 15 minute session
                </small>

                <hr />

                <div className="flex justify-between">
                  <button
                    className="btn-default w-max flex items-center justify-between gap-2"
                    type="button"
                    onClick={submitFee}
                  >
                    {calendarStatus === 'loading' && (
                      <ReactLoading
                        type="cylon"
                        color="#fff"
                        className=""
                        height={20}
                        width={20}
                      />
                    )}
                    Save changes
                  </button>

                  <button
                    className="rounded-lg px-6 w-max text-[#792AE2] border-2 border-neutral-300"
                    type="button"
                    onClick={() => setFee(0)}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            ) : fee === 2 ? (
              <>
                <p className="font-semibold text-[28px] leading-9"> </p>
                <small className="text-neutral-400 text-sm">
                  The call fee is calculated based on a 15 minute session
                </small>
                <hr />
                <p
                  className="text-[#792AE2] font-medium text-sm cursor-pointer"
                  onClick={openFee}
                >
                  Change call fee
                </p>
              </>
            ) : (
              ''
            )}
          </div>

          <div className="card grid gap-y-6">
            <p className="text-xl">Withdrawal Account</p>
            {account === 0 && (bankDetails?.name === undefined || !bankDetails) ? (
              <>
                <p className="text-neutral-400">No account is connected</p>
                <hr />
                <p
                  className="text-[#792AE2] font-medium text-sm cursor-pointer"
                  onClick={openBank}
                >
                  Set Bank Account
                </p>
              </>
            ) : account === 1 ? (
              <form action="" className="grid gap-y-4 mt-10">
                <div>
                  <label htmlFor="" className="font-medium text-sm text-black">
                    Select country
                  </label>

                  <SelectMenu
                    options={options}
                    optionField={'country'}
                    className={'w-full top-14'}
                    handleSelect={handleSelectChange}
                  >
                    <div className="w-full p-2 flex justify-between items-center text-base px-3 border font-normal border-neutral-300 rounded-lg mt-1">
                      <p>
                        {selectedValue?.value
                          ? selectedValue?.value
                          : 'Select a country'}
                      </p>
                      <FiChevronDown />
                    </div>
                  </SelectMenu>
                </div>

                <div>
                  <label htmlFor="" className="font-medium text-sm">
                    Select bank
                  </label>

                  <SelectMenu
                    options={banks}
                    optionField={'bankName'}
                    className={'w-full top-14 max-h-[20rem] overflow-auto'}
                    handleSelect={handleBankSelect}
                  >
                    <div className="w-full p-2 flex justify-between items-center text-base px-3 border font-normal border-neutral-300 rounded-lg mt-1">
                      <p>{bank?.name ? bank?.name : 'Select a bank name'}</p>
                      {loading ? (
                        <ReactLoading
                          type="spin"
                          color="#934CFD"
                          className=""
                          height={20}
                          width={20}
                        />
                      ) : (
                        <FiChevronDown />
                      )}
                    </div>
                  </SelectMenu>
                </div>

                <div>
                  <label htmlFor="" className="font-medium text-sm">
                    Account number
                  </label>
                  <input
                    onChange={handleAccount}
                    name="accountNumber"
                    type="text"
                    placeholder=""
                    className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                  />
                </div>

                <hr />

                <button
                  className="btn-default w-max flex items-center justify-center gap-2"
                  type="button"
                  onClick={submitForm}
                >
                  {accLoading && (
                    <ReactLoading
                      type="spin"
                      color="#fff"
                      className=""
                      height={20}
                      width={20}
                    />
                  )}
                  Add bank account
                </button>
              </form>
            ) : (
              <>
                <div className="">
                  <p className="font-semibold text-xl">
                    {bankDetails?.name} {bankDetails?.accountNumber}
                  </p>
                  <p className="text-neutral">
                    {expert?.firstName} {expert?.lastName}
                  </p>
                </div>
                <hr />
                <p
                  className="text-[#792AE2] font-medium text-sm cursor-pointer"
                  onClick={openBank}
                >
                  Change bank account
                </p>
              </>
            )}
          </div>
        </div>
      </Layout>
    </ProtectedRoute>
  );
};

export default Payment;
