import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProtectedRoute from '../utils/ProtectedRoute';
import { getEnvVars } from '../app/env';
import Header from '../components/banner';
import UserNavbar from '../components/layout/navbar';
import AlternateFooter from '../components/layout/AlternateFooter';
import { FiLock, FiLogOut, FiUser } from 'react-icons/fi';
import SettingDropdown from '../components/shared/SettingDropdown';
import Container from '../components/shared/Container';

const { REACT_APP_FRONTEND_URL, REACT_APP_API_URL } = getEnvVars();

const userMenu = [
  {
    path: '/profile',
    name: 'Profile',
    icon: <FiUser />,
  },

  {
    path: '/password',
    name: 'Password',
    icon: <FiLock />,
  },
];

const Password = () => {
  const initialState = {
    oldPassword: '',
    newPassword: '',
    homepageUrl: REACT_APP_FRONTEND_URL,
  };
  const [state, setState] = useState(initialState);
  const [confirmPassword, setConfirmPassword] = useState('');

  const navigate = useNavigate();
  const toastID = Math.random();

  let title;
  if (window.location.pathname === '/profile') {
    title = 'Profile';
  } else if (window.location.pathname === '/password') {
    title = 'Password';
  }

  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('exptToken'),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.newPassword !== confirmPassword) {
      toast.error(
        "Confirm password doesn't match new password, Please try again",
        { toastId: toastID },
      );
    } else if (state.oldPassword === state.newPassword) {
      toast.error(
        "New password shouldn't match old password, Please try again",
        { toastId: toastID },
      );
    } else {
      try {
        await axios
          .patch(`${REACT_APP_API_URL}/v1/auth/update-password`, state, {
            headers,
          })
          .then((res) => {
            toast.success(res.data.message, { toastId: toastID });
            navigate('/reset_password_success');
          });
      } catch (err) {
        console.error(err);
        // err.response.data.message
        toast.error(err.response.data.message, { toastId: toastID });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
    navigate('/');
  };

  return (
    <ProtectedRoute>
      <div className="bg-[#EFEFEF] relative min-h-screen pb-20">
        <UserNavbar />

        <Header title={title} pathUrl={'/search'} />
        <Container>
          <div className="justify-between mb-12 md:flex hidden relative ">
            <div
              className={`flex space-x-3 items-center ${
                title === 'Profile'
                  ? 'border-b-2 border-textPurple text-textPurple'
                  : ' text-textLight'
              } cursor-pointer px-3 py-1.5`}
              onClick={() => navigate('/profile')}
            >
              <FiUser className=" font-semibold text-xl" />
              <p>Profile</p>
            </div>

            <div
              className={`flex space-x-3 items-center ${
                title === 'Password'
                  ? 'border-b-2 border-textPurple text-textPurple'
                  : ' text-textLight'
              } cursor-pointer px-3 py-1.5`}
              onClick={() => navigate('/password')}
            >
              <FiLock className=" font-semibold text-xl" />
              <p>Password</p>
            </div>

            <div
              className={`flex space-x-3 items-center ${
                !title
                  ? 'border-b-2 border-textPurple text-textPurple'
                  : ' text-textLight'
              } cursor-pointer px-3 py-1.5`}
              onClick={handleLogout}
            >
              <FiLogOut className=" font-semibold text-xl" />
              <p>Logout</p>
            </div>
          </div>
        </Container>
        <Container>
          <div className="relative">
            <SettingDropdown options={userMenu} title={title} />
          </div>
        </Container>
        <Container>
          <div className="flex justify-between mb-12">
            <form
              className="shadow-sm p-5 sm:p-8 bg-white rounded-xl sm:rounded-2xl grid gap-y-10 gap-x-6 !py-12 w-full"
              onSubmit={handleSubmit}
            >
              <div>
                <label htmlFor="" className="text-sm font-medium">
                  Current Password
                </label>{' '}
                <br />
                <input
                  type="password"
                  name="oldPassword"
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                  placeholder="test123"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="" className="text-sm font-medium">
                  New Password
                </label>{' '}
                <br />
                <input
                  type="password"
                  name="newPassword"
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                  placeholder="Enter new password"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="" className="text-sm font-medium">
                  Confirm Password
                </label>{' '}
                <br />
                <input
                  type="password"
                  name="confirm password"
                  className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
                  placeholder="Re-enter new password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>

              <button className="btn-default w-full">Reset Password</button>
            </form>
          </div>
        </Container>
        <AlternateFooter />
      </div>
    </ProtectedRoute>
  );
};

export default Password;
