import React, { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { IoMdCalendar } from 'react-icons/io';

const months = [
  'Jan',
  'Feb',
  'Mar',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]; // array of month names

export default function AvailabilityCalendar({
  className,
  availabilitySlots,
  handleGetAvailability,
}) {
  const [date, setDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [month, setMonth] = useState(date.getMonth());
  const [year, setYear] = useState(date.getFullYear());
  const [calendarHTML, setCalendarHTML] = useState([]);

  useEffect(() => {
    generateCalendar();
  }, [month, availabilitySlots]);

  const handleDateSelect = (availability, close, formattedDate) => {
    if (availability?.length === 0) {
      close();
      return;
    }
    close();
    setSelectedDate(new Date(formattedDate));
    handleGetAvailability(formattedDate);
  };

  const generateCalendar = () => {
    let dayone = new Date(year, month, 1).getDay();
    let lastdate = new Date(year, month + 1, 0).getDate();
    let dayend = new Date(year, month, lastdate).getDay();
    let monthlastdate = new Date(year, month, 0).getDate();

    let items = [];

    const weekLength = 6;

    for (let i = dayone; i > 0; i--) {
      items.push(
        <li
          key={`inactive-${i}`}
          className="inactive text-textLight w-6 h-6 flex items-center justify-center cursor-pointer"
        >
          {monthlastdate - i + 1}
        </li>,
      );
    }

    for (let i = 1; i <= lastdate; i++) {
      let isToday =
        i === date.getDate() &&
        month === new Date().getMonth() &&
        year === new Date().getFullYear()
          ? 'active'
          : '';

      const formattedDay = i.toString().padStart(2, '0');
      const formattedMonth = (month + 1).toString().padStart(2, '0');
      const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

      items.push(
        <Menu.Item key={`active-${i}`}>
          {({ close }) => (
            <li
              key={`active-${i}`}
              className={`${
                isToday ? ' border border-[#B0A9B4]' : ''
              } w-6 h-6 flex items-center justify-center rounded-full cursor-pointer ${
                availabilitySlots[formattedDate]?.length > 0
                  ? 'font-semibold hover:bg-[#934CFD] hover:text-white'
                  : 'text-textLight'
              }`}
              onClick={() =>
                handleDateSelect(
                  availabilitySlots[formattedDate],
                  close,
                  formattedDate,
                )
              }
            >
              <p>{i}</p>
            </li>
          )}
        </Menu.Item>,
      );
    }

    for (let i = dayend; i < weekLength; i++) {
      items.push(
        <li
          key={`inactive-next-${i}`}
          className="inactive text-textLight w-6 h-6 flex items-center justify-center cursor-pointer"
        >
          {i - dayend + 1}
        </li>,
      );
    }

    setCalendarHTML(items);
  };

  const calendarNav = (idx) => {
    // Check if the icon is "prev" or "next"
    let newMonth = !idx ? month - 1 : month + 1;

    // Check if the month is out of range
    if (newMonth > 0 || newMonth < 11) {
      // Set the date to the first day of the newMonth with the new year
      const todayDate = new Date();
      const newDate = new Date(year, newMonth, new Date().getDate());
      setDate(newDate);
      // Set the year to the new year
      const newYear = newDate.getFullYear();
      setYear(newYear);
      // Set the month to the new month
      newMonth = newDate.getMonth();
      setMonth(newMonth);
    
      const formattedMonth = (newMonth + 1).toString().padStart(2, '0');
      const formattedDayDate = todayDate.getDate().toString().padStart(2, '0');


      let formattedDate;
      if (newMonth === todayDate.getMonth()) {
        formattedDate = `${newYear}-${formattedMonth}-${formattedDayDate}`;
      } else {
        formattedDate = `${newYear}-${formattedMonth}-01`;
      }

      handleGetAvailability(formattedDate);
    } else {
      // Set the date to the current date
      setDate(new Date());
      generateCalendar();
    }
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  return (
    <Menu as="div" className="relative inline-flex text-left">
      <div>
        <Menu.Button className="text-sm m-auto">
          <div className="text-gray-500 text-xs border border-gray-200 rounded-md py-2 px-2 sm:px-4 sm:w-auto cursor-pointer my-3 sm:my-0 inline-flex items-center space-x-3">
            <p>{formatDate(selectedDate)}</p>
            <IoMdCalendar className="text-sm" />
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${
            className
              ? className
              : 'sm:left-1/2 sm:-translate-x-1/2 mt-12 sm:mt-10'
          } z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-2 rounded-md shadow-md">
            <header className="px-3 py-2">
              <div className="flex items-center justify-between">
                <p className="calendar-current-date text-sm">{`${months[month]} ${year}`}</p>

                <div className="calendar-navigation flex">
                  <span
                    id="calendar-prev"
                    className="cursor-pointer"
                    onClick={() => calendarNav(false)}
                  >
                    <FiChevronLeft className="font-bold text-base" />
                  </span>

                  <span
                    id="calendar-next"
                    className="cursor-pointer"
                    onClick={() => calendarNav(true)}
                  >
                    <FiChevronRight className="font-bold text-base" />
                  </span>
                </div>
              </div>
            </header>

            <div className="px-3">
              <ul className="list-none text-xs flex space-x-2 my-2">
                <li className="text-xs font-bold w-6 text-center">S</li>
                <li className="text-xs font-bold w-6 text-center">M</li>
                <li className="text-xs font-bold w-6 text-center">T</li>
                <li className="text-xs font-bold w-6 text-center">W</li>
                <li className="text-xs font-bold w-6 text-center">T</li>
                <li className="text-xs font-bold w-6 text-center">F</li>
                <li className="text-xs font-bold w-6 text-center">S</li>
              </ul>

              <ul className="mb-3 list-none text-xs grid grid-cols-7 gap-2 text-center">
                {calendarHTML}
              </ul>
            </div>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
