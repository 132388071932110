import React, { useEffect, useMemo, useState } from 'react';
import Layout from '../components/layout/layout';
import { BsCalendar4, BsClock, BsFillArrowUpCircleFill } from 'react-icons/bs';
import Header from '../components/banner';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSession,
  resetSession,
  getGiftSession,
} from '../features/session/sessionSlice';
import calendar from '../assets/icons/calendar.svg';
import { RxMagnifyingGlass } from 'react-icons/rx';
import ProtectedRoute from '../utils/ProtectedRoute';
import { FiUser } from 'react-icons/fi';
import ReactTimeAgo from 'react-time-ago';
import InfiniteScroll from 'react-infinite-scroll-component';
import ReactLoading from 'react-loading';
import { getEnvVars } from '../app/env';
// import menuIcon from '../assets/icons/vertical.svg';
import SessionDropdown from '../components/shared/SessionDropdown';
import Container from '../components/shared/Container';

const { REACT_APP_FF_AD_IMAGES } = getEnvVars();

const options = {
  day: 'numeric',
  year: 'numeric',
  month: 'long',
};

const duration = {
  hour: 'numeric',
  minute: 'numeric',
};

const Callpage = () => {
  const dispatch = useDispatch();
  const { sessions, callsStatus, giftSessions, sessionsPagination } =
    useSelector((state) => state.session);

  const navigate = useNavigate();
  const location = useLocation();
  const [userId /*setUserId*/] = useState(() => localStorage.userId);
  const filteredValue = useMemo(
    () =>
      giftSessions?.filter(
        (item) => !item?.isClaimed && item.recipient.id === userId,
      ),
    [giftSessions, userId],
  );

  const [skip, setSkip] = useState(0);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    dispatch(
      getSession({ admin: location.pathname.includes('expert'), skip: skip }),
    );

    return () => {
      dispatch(resetSession());
    };
  }, [skip]);

  useEffect(() => {
    if (!location.pathname.includes('expert') && !paginationLoading) {
      dispatch(
        getGiftSession({
          admin: location.pathname.includes('expert'),
        }),
      );
    }
    return () => {
      dispatch(resetSession());
    };
  }, []);

  useEffect(() => {
    const handleScrollButton = () => {
      window.scrollY > 300 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener('scroll', handleScrollButton);

    return () => {
      window.removeEventListener('scroll', handleScrollButton);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const getStatus = (session) => {
    const sessionIsBookedAndPaid =
      (session?.isScheduled &&
        session?.status === 'accepted' &&
        session?.url) ||
      !session.isScheduled;

    if (session.status === 'completed') {
      return 'Done';
    }
    if (session.status === 'accepted' && !session.paymentId) {
      return 'Unpaid';
    }
    if (session?.status === 'cancelled') {
      return 'Cancelled';
    }
    if (sessionIsBookedAndPaid) {
      return 'Booked';
    }
    if (session?.isScheduled && session?.status === 'accepted') {
      return 'Accepted';
    }
    if (session?.isScheduled) {
      return 'Requested';
    }
    if (session?.status === 'declined') {
      return 'Declined';
    }

    return 'Booked';
  };

  const statusShed = (session) => {
    if (session.status === 'completed') {
      return 'bg-gray-300';
    } else if (session.status === 'accepted' && !session.paymentId) {
      return 'text-blue-600 bg-blue-100';
    } else if (session?.isScheduled && session?.status === 'pending') {
      return 'bg-[#FFEFD2] text-[#BA8800]';
    } else if (
      (session?.isScheduled && session?.status === 'declined') ||
      session?.status === 'cancelled'
    ) {
      return 'bg-[#FFEDE9] text-[#BA1B1B]';
    } else {
      return 'text-green-600 bg-green-100';
    }
  };

  const handleFetchData = () => {
    setPaginationLoading(true);
    setSkip(skip + 10);
  };

  const handleSessionRedirect = (callSession) => {
    if (callSession.status === 'completed') {
      return;
    } else if (callSession.status === 'accepted' && !callSession.paymentId) {
      navigate(`/book/${callSession.id}/payment`);
    } else {
      navigate(
        `${
          location.pathname.includes('expert')
            ? '/expert/call/detail/' + callSession?.id
            : '/call/detail/' + callSession?.id
        }`,
      );
    }
  };

  return (
    <ProtectedRoute>
      <div className="altNav">
        <Layout>
          <Header
            title="Calls"
            pathUrl={
              location?.pathname.includes('expert') ? '/expert/call' : '/call'
            }
          />
          <Container>
            <div className="grid gap-y-6 sm:pt-10">
              {sessions?.length !== 0 || filteredValue ? (
                <>
                  {sessions && (
                    <InfiniteScroll
                      dataLength={sessions?.length}
                      next={handleFetchData}
                      hasMore={sessionsPagination.totalDocs > sessions.length}
                      loader={
                        <div className=" flex align-middle items-center justify-center p-10">
                          <ReactLoading
                            type="spin"
                            color="black"
                            className=""
                            height={80}
                            width={80}
                          />
                        </div>
                      }
                      endMessage={<p>No more data to load.</p>}
                    >
                      <div className="flex flex-col gap-y-6">
                        {filteredValue &&
                          filteredValue.map((session, key) => (
                            <React.Fragment key={key}>
                              {filteredValue?.length !== 0 ? (
                                <div
                                  className="grid lg:grid-cols-3 rounded-2xl shadow-sm card cursor-pointer"
                                  onClick={() =>
                                    navigate(
                                      `/gift-book/${session?.expert?.id}`,
                                    )
                                  }
                                  key={key}
                                >
                                  <div className="gap-y-4 grid lg:gap-y-0">
                                    <span
                                      className={`bg-[#FFEFD2] text-[#BA8800] py-1.5 mb-2 px-4 rounded-2xl w-min`}
                                    >
                                      Gifted
                                    </span>
                                    <div className="flex gap-x-4">
                                      <div className="flex">
                                        <BsCalendar4
                                          className="text-neutral-500 mr-1 self-center"
                                          size={18}
                                        />
                                        {session?.createdAt?.length > 0 &&
                                          new Date(
                                            session?.createdAt,
                                          ).toLocaleDateString(
                                            'en-US',
                                            options,
                                          )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex gap-x-4 items-center py-4 lg:py-0">
                                    {session?.expert?.profilePhoto ? (
                                      <div className="sm:w-14 sm:h-14 w-12 h-12 rounded-full object-cover">
                                        <img
                                          src={session?.expert?.profilePhoto}
                                          alt=""
                                          className="w-full h-full rounded-full"
                                        />
                                      </div>
                                    ) : (
                                      <div className=" bg-gray-100 w-14 h-14 rounded-full flex justify-center align-middle items-center">
                                        <FiUser
                                          className="text-[#270058]"
                                          size={28}
                                        />
                                      </div>
                                    )}
                                    <div>
                                      <p>
                                        {`${session?.expert?.firstName} ${session?.expert?.lastName}`}
                                      </p>
                                      <p className="text-neutral-500">
                                        {session?.expert?.jobTitle}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="text-neutral-500 text-sm mr-auto pt-2 md:ml-auto md:mr-0 items-center gap-y-16 md:-mt-32 lg:mt-0">
                                    {session?.updatedAt && (
                                      <ReactTimeAgo
                                        date={session.updatedAt}
                                        locale="en-US"
                                      />
                                    )}
                                  </div>
                                </div>
                              ) : null}
                            </React.Fragment>
                          ))}
                        {sessions.map((session, key) => (
                          <div
                            className="grid lg:grid-cols-3 rounded-2xl shadow-sm bg-white p-4 sm:p-10 cursor-pointer"
                            onClick={() => handleSessionRedirect(session)}
                            key={key}
                          >
                            <div className="gap-y-4 grid lg:gap-y-0">
                              <span
                                className={`${statusShed(
                                  session,
                                )} sm:py-1.5 py-1 sm:px-4 px-3 text-xs sm:text-sm sm:rounded-2xl rounded-xl w-min`}
                              >
                                {getStatus(session)}
                              </span>

                              <p className="sm:text-[22px] text-lg sm:mt-2.5 sm:mb-1">
                                {session?.title}
                              </p>

                              <div className="flex sm:mt-2 justify-between sm:justify-start sm:gap-x-4">
                                <div className="flex items-center text-xs sm:text-sm md:text-base">
                                  <BsCalendar4
                                    className="text-neutral-500 mr-1 self-center"
                                    size={18}
                                  />
                                  {session?.startsAt?.length > 0 &&
                                    new Date(
                                      session?.startsAt,
                                    ).toLocaleDateString('en-US', options)}
                                </div>

                                <div className="flex items-center text-xs sm:text-sm md:text-base">
                                  <BsClock
                                    className="text-neutral-500 mr-1 self-center"
                                    size={18}
                                  />
                                  {session?.startsAt?.length > 0 &&
                                    new Date(session?.startsAt).toLocaleString(
                                      'en-US',
                                      duration,
                                    )}{' '}
                                  -{' '}
                                  {session?.startsAt?.length > 0 &&
                                    new Date(session?.endsAt).toLocaleString(
                                      'en-US',
                                      duration,
                                    )}
                                </div>
                              </div>
                            </div>

                            <div className="flex gap-x-4 items-center py-4 lg:py-0">
                              {session?.participant?.profilePhoto ? (
                                <div className="sm:w-14 sm:h-14 w-12 h-12 rounded-full object-cover">
                                  <img
                                    src={session?.participant.profilePhoto}
                                    alt=""
                                    className="w-full h-full rounded-full"
                                  />
                                </div>
                              ) : (
                                <div className=" bg-gray-100 w-14 h-14 rounded-full flex justify-center align-middle items-center">
                                  <FiUser
                                    className="text-[#270058]"
                                    size={28}
                                  />
                                </div>
                              )}

                              <div className="text-sm sm:text-base">
                                <p>
                                  {session?.participant?.firstName +
                                    ' ' +
                                    session?.participant?.lastName}
                                </p>
                                <p className="text-neutral-500">
                                  {session?.participant?.jobTitle}
                                </p>
                              </div>
                            </div>

                            <div className="text-neutral-500 text-sm md:ml-auto md:mr-0 md:-mt-32 lg:mt-0 flex justify-between flex-row-reverse sm:flex-col">
                              <div className="inline-flex sm:justify-end relative">
                                {session.status === 'completed' &&
                                  !location.pathname.includes('expert') && (
                                    <SessionDropdown session={session} />
                                  )}
                              </div>

                              <p className="mt-auto">
                                <ReactTimeAgo
                                  date={session.updatedAt}
                                  locale="en-US"
                                />
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </InfiniteScroll>
                  )}
                </>
              ) : sessions?.length === 0 &&
                !giftSessions &&
                callsStatus === 'success' ? (
                <div className="text-center">
                  <img src={calendar} className="mx-auto" alt="calendar-icon" />

                  <div className="my-12">
                    <p className="text-xl font-semibold">
                      Looks like you don’t have any booked call
                    </p>

                    <p className="text-neutral-500">
                      It’s easy to book a call. You can start by searching for
                      an expert
                    </p>
                  </div>

                  {location.pathname.includes('expert') ? (
                    <button
                      className="flex btn-default mx-auto"
                      onClick={() => navigate('/expert/dashboard')}
                    >
                      <span>Dashboard</span>
                    </button>
                  ) : (
                    <button
                      className="flex btn-default mx-auto"
                      onClick={() => navigate('/search')}
                    >
                      <RxMagnifyingGlass
                        className="self-center mr-1.5"
                        size={25}
                      />
                      <span>Search for experts</span>
                    </button>
                  )}
                </div>
              ) : null}

              {callsStatus === 'loading' && !paginationLoading && (
                <>
                  <div className="grid rounded-2xl shadow-sm card">
                    <div
                      role="status"
                      className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                    >
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                    </div>
                  </div>
                  <div className="grid rounded-2xl shadow-sm card">
                    <div
                      role="status"
                      className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                    >
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                    </div>
                  </div>
                  <div className="grid rounded-2xl shadow-sm card">
                    <div
                      role="status"
                      className="animate-pulse flex content-between place-content-between align-middle flex-col w-full"
                    >
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 w-48 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg my-3" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg" />
                      <div className="h-5 bg-gray-50 dark:bg-gray-200 rounded-lg mt-3 max-w-[37rem]" />
                    </div>
                  </div>
                </>
              )}

              {REACT_APP_FF_AD_IMAGES === 'true' && (
                <div className="bg-white flex items-center justify-center font-bold text-3xl min-h-[12rem] p-4 sm:px-6 py-8 rounded-lg mt-8 sm:rounded-2xl shadow-md">
                  ADS IMAGE
                </div>
              )}
            </div>
          </Container>
        </Layout>
      </div>

      {showButton && (
        <div
          className="fixed shadow-md sm:bottom-16 bottom-8 rounded-full sm:right-20 right-4 cursor-pointer"
          onClick={handleScrollToTop}
        >
          <BsFillArrowUpCircleFill className="text-black text-4xl" />
        </div>
      )}
    </ProtectedRoute>
  );
};

export default Callpage;
