import React, { useState, useCallback } from 'react';
import {
  useParticipantIds,
  useScreenShare,
  useDailyEvent,
  useLocalSessionId,
} from '@daily-co/daily-react';

import './Call.css';
import Tile from '../Tile/Tile';
import UserMediaError from '../UserMediaError/UserMediaError';

export default function Call() {
  /* If a participant runs into a getUserMedia() error, we need to warn them. */
  const [getUserMediaError, setGetUserMediaError] = useState(false);
  const [pinLocal, setPinLocal] = useState(false);

  const pinScreen = () => {
    setPinLocal(!pinLocal);
  };

  /* We can use the useDailyEvent() hook to listen for daily-js events. Here's a full list
   * of all events: https://docs.daily.co/reference/daily-js/events */
  useDailyEvent(
    'camera-error',
    useCallback(() => {
      setGetUserMediaError(true);
    }, []),
  );

  /* This is for displaying remote participants: this includes other humans, but also screen shares. */
  const { screens } = useScreenShare();
  const remoteParticipantIds = useParticipantIds({ filter: 'remote' });

  /* This is for displaying our self-view. */
  const localSessionId = useLocalSessionId();
  const isAlone = remoteParticipantIds.length < 1 || screens.length < 1;

  // const renderCallScreen = () => (
  //   <div className={screens.length > 0 ? 'is-screenshare' : 'call'}>
  //     {/* Your self view */}
  //     {localSessionId &&
  //     <div className='local mx-auto'>
  //       <Tile id={localSessionId} isLocal isAlone={isAlone} />
  //     </div>
  //     }
  //     {/* Videos of remote participants and screen shares */}
  //     {remoteParticipantIds.length > 0 || screens.length > 0 ? (
  //       <>
  //         {remoteParticipantIds.map((id) => (
  //           <div key={id} className='participant'>
  //             <Tile key={id} id={id} />
  //           </div>
  //         ))}
  //         {screens.map((screen) => (
  //           <Tile key={screen.screenId} id={screen.session_id} isScreenShare />
  //         ))}
  //       </>
  //     ) : (
  //       // When there are no remote participants or screen shares
  //       <div className="info-box bg-white">
  //         <h1>Waiting for others</h1>
  //         {/* <p>Invite someone by sharing this link:</p> */}
  //         {/* <span className="room-url">{window.location.href}</span> */}
  //       </div>
  //     )}
  //   </div>
  // );

  const UnPinnedView = () => {
    return (
      <div className="call">
        <div className="participant">
          {remoteParticipantIds.length > 0 ? (
            remoteParticipantIds.map((id) => (
              <Tile
                key={id}
                id={id}
                pinScreen={pinScreen}
                pinLocal={pinLocal}
              />
            ))
          ) : (
            // When there are no remote participants or screen shares
            <div className="info-box bg-white">
              <p className="text-lg">Waiting for others</p>
              {/* <p>Invite someone by sharing this link:</p> */}
              {/* <span className="room-url">{window.location.href}</span> */}
            </div>
          )}
          {/* {screens.map((screen) => (
            <Tile key={screen.screenId} id={screen.session_id} isScreenShare />
          ))} */}
        </div>
        <div className="local">
          {localSessionId && (
            <Tile
              id={localSessionId}
              isLocal
              isAlone={isAlone}
              pinScreen={pinScreen}
              pinLocal={pinLocal}
            />
          )}
        </div>
      </div>
    );
  };
  const PinnedView = () => {
    return (
      <div className="call">
        <div className="participant">
          {localSessionId && (
            <Tile
              id={localSessionId}
              isLocal
              isAlone={isAlone}
              pinScreen={pinScreen}
              pinLocal={pinLocal}
            />
          )}
        </div>
        <div className="local">
          {remoteParticipantIds.length > 0 ? (
            remoteParticipantIds.map((id) => (
              <Tile
                key={id}
                id={id}
                pinScreen={pinScreen}
                pinLocal={pinLocal}
              />
            ))
          ) : (
            // When there are no remote participants or screen shares
            <div className="info-box bg-white">
              <p className="text-lg">Waiting for others</p>
              {/* <p>Invite someone by sharing this link:</p> */}
              {/* <span className="room-url">{window.location.href}</span> */}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCallScreen = () => (pinLocal ? <PinnedView /> : <UnPinnedView />);

  return getUserMediaError ? <UserMediaError /> : renderCallScreen();
}
