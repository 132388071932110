import React, { useState } from 'react';
import Layout from '../../components/layout//accountLayout';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { getEnvVars } from '../../app/env';
import ReactLoading from 'react-loading';

const { REACT_APP_FRONTEND_URL, REACT_APP_API_URL } = getEnvVars();

const Password = () => {
  const initialState = {
    oldPassword: '',
    newPassword: '',
    homepageUrl: REACT_APP_FRONTEND_URL,
  };
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [confirm_pswd, setConfirm_Pswd] = useState('');

  const navigate = useNavigate();
  const toastID = Math.random();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (state.newPassword !== confirm_pswd) {
      toast.error(
        "Confirm password doesn't match new password, please try again",
        { toastId: toastID },
      );
    } else if (state.oldPassword === state.newPassword) {
      toast.error(
        "New password shouldn't match old password, please try again",
        { toastId: toastID },
      );
    } else {
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.exptToken,
        },
      };

      try {
        setLoading(true);
        await axios
          .patch(`${REACT_APP_API_URL}/v1/auth/update-password`, state, config)
          .then((res) => {
            setLoading(false);
            toast.success(res.data.message, { toastId: toastID });
            navigate('/reset_password_success');
          });
      } catch (err) {
        console.error(err);
        setLoading(false);
        toast.error(err.response.data.message, { toastId: toastID });
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <ProtectedRoute>
      <Layout>
        <form
          className="shadow-sm p-5 sm:p-8 bg-white rounded-xl sm:rounded-2xl grid gap-y-10 gap-x-6 !py-12"
          onSubmit={handleSubmit}
        >
          <div>
            <label htmlFor="" className="text-sm font-medium">
              Current Password
            </label>{' '}
            <br />
            <input
              type="password"
              name="oldPassword"
              className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
              placeholder="Current password"
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="" className="text-sm font-medium">
              New Password
            </label>{' '}
            <br />
            <input
              type="password"
              name="newPassword"
              className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
              placeholder="Enter new password"
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="" className="text-sm font-medium">
              Confirm Password
            </label>{' '}
            <br />
            <input
              type="password"
              name="confirm password"
              className="w-full p-2 border border-neutral-300 rounded-lg mt-1"
              placeholder="Re-enter new password"
              onChange={(e) => setConfirm_Pswd(e.target.value)}
            />
          </div>

          <button className="btn-default w-full flex items-center justify-center gap-2">
            {loading && (
              <ReactLoading
                type="cylon"
                color="#fff"
                className=""
                height={20}
                width={20}
              />
            )}
            Reset Password
          </button>
        </form>
      </Layout>
    </ProtectedRoute>
  );
};

export default Password;
