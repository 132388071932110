import React from 'react';
import Navbar from '../components/layout/navbar';
import Footer from '../components/layout/footer';
import FacebookPixel from '../utils/FacebookPixel';
import Container from '../components/shared/Container';

const Terms = () => {
  return (
    <div className="about mx-auto">
      <FacebookPixel />
      <Navbar />
      <div className="pb-20">
        <section className="three bg-bgPurple sm:pt-36 pt-28 py-16 text-white">
          <Container>
            <h2 className="font-bold text-4xl lg:text-[57px] pb-4 lg:pb-8">
              ZedIntro Terms & Conditions
            </h2>

            <p className="">
              These Terms and Conditions (&quot;Agreement&quot;) govern the use
              of Zedintro, a web application (&quot;Zedintro&quot; or
              &quot;WebApp&quot;) developed and operated by Zedi Africa Limited
              (&quot;Company&quot;). By using the Zedintro WebApp, you
              (&quot;User&quot; or &quot;You&quot;) agree to be bound by the
              terms and conditions set forth in this Agreement.
            </p>
          </Container>
        </section>

        <section className="mt-20">
          <Container>
            <h2 className="font-bold text-xl pb-4">1. Acceptance of Terms</h2>
            <p>
              1.1 By accessing or using the WebApp, you acknowledge that you
              have read, understood, and agree to be bound by this Agreement. If
              you do not agree to these terms, you must immediately cease using
              the WebApp.
            </p>
            <p className="my-4">
              1.2 The Company reserves the right to modify or update this
              Agreement at any time without prior notice. Your continued use of
              the WebApp after any such changes constitutes your acceptance of
              the revised Agreement.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">2. Zedintro Services</h2>
            <p className="mt-4">
              2.1 The WebApp is a platform that connects individuals seeking
              advice or guidance (&quot;User&quot;) with experienced
              professionals or experts (&quot;Experts&quot;) in various fields.
            </p>
            <p className="my-4">
              2.2 Users can browse through a list of available Experts, view
              their profiles, and request or book a call with the desired
              Expert.
            </p>
            <p>
              2.3 Zedintro facilitates communication and payment processing
              between User and Experts. The payment for Expert services is
              determined by the Expert and billed per 15-minutes.
            </p>
            <p className="my-4">
              2.4 Zedintro does not endorse or guarantee the quality, accuracy,
              or reliability of any advice, information, or services provided by
              Experts. Users are responsible for exercising their own judgment
              and discretion when using the WebApp.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">3. User Obligations</h2>
            <p className="">
              3.1 You must be at least 18 years old to use the Zedintro WebApp.
              If you are under 18, you may only use the WebApp with the consent
              and supervision of a parent or legal guardian. The Company
              disclaims any liability from Users under the age of 18 using the
              WebApp.
            </p>
            <p className="mt-4">
              3.2 You agree to provide accurate, current, and complete
              information during the registration process and maintain the
              accuracy of this information.
            </p>
            <p className="my-4">
              3.3 You are responsible for maintaining the confidentiality of
              your account credentials and are solely responsible for any
              activities that occur under your account.
            </p>
            <p className="">
              3.4 You agree not to use the Zedintro WebApp for any illegal,
              unauthorized, or improper purposes, including but not limited to
              transmitting any harmful or disruptive content or engaging in
              fraudulent activities.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">4. Payment and Fees</h2>
            <p className="">
              4.1 When booking or requesting a call with an Expert through
              Zedintro, you agree to pay the fees set by the Expert per every
              15-minutes.
            </p>
            <p className="my-4">
              4.2 The payment will be processed using the payment method
              specified in your account. You are responsible for ensuring the
              accuracy and validity of the payment information provided.
            </p>
            <p>
              4.3 Zedintro may collect and remit applicable taxes on behalf of
              Experts or as required by applicable law.
            </p>
            <p className="mt-4">
              4.4 Zedintro reserves the right to modify the fees charged for the
              use of the WebApp at any time. Any changes to the fees will be
              communicated to you through the WebApp or via email.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">5. Intellectual Property</h2>
            <p className="">
              5.1 The Zedintro WebApp and all its contents, including but not
              limited to text, graphics, logos, images, software, and audio or
              video clips, are the property of the Company or its licensors and
              are protected by intellectual property laws.
            </p>
            <p className="mt-4">
              5.2 You may not modify, reproduce, distribute, create derivative
              works, or reverse engineer any part of the Zedintro WebApp without
              the express written permission of the Company.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">6. Privacy</h2>
            <p>
              6.1 Zedintro collects, stores, and uses personal information in
              accordance with its Privacy Policy. By using the WebApp, you
              consent to the collection, storage, and use of your personal
              information as described in the Privacy Policy.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">
              7. Disclaimer of Warranty
            </h2>
            <p>
              7.1 The Zedintro WebApp is provided on an &quot;as is&quot; and
              &quot;as available&quot; basis without warranties of any kind,
              whether express or implied.
            </p>
            <p className="my-4">
              7.2 The Company does not warrant that the WebApp will be
              error-free, uninterrupted, or free from viruses or other harmful
              components.
            </p>
            <p>
              7.3 The Company does not endorse, guarantee, or assume
              responsibility for the accuracy, reliability, or quality of any
              advice, information, or services provided by Experts through the
              WebApp.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">
              8. Limitation of Liability
            </h2>
            <p>
              8.1 To the maximum extent permitted by applicable law, in no event
              shall Company be liable for any indirect, incidental, special,
              consequential, or punitive damages, or any loss of profits or
              revenue arising out of or in connection with your use of the
              Zedintro WebApp.
            </p>
            <p className="mt-4">
              8.2 Company&apos;s total liability for any claim arising out of or
              relating to this Agreement or the use of the WebApp shall not
              exceed the fees paid by you to the Company in the three months
              preceding the claim.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">9. Indemnification</h2>
            <p>
              9.1 You agree to indemnify and hold harmless Company, its
              affiliates, officers, directors, employees, and agents from any
              claims, liabilities, damages, losses, or expenses arising out of
              or in connection with your use of the Zedintro WebApp or violation
              of this Agreement.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">10. Termination</h2>
            <p>
              10.1 This Agreement is effective until terminated by either party.
              You may terminate this Agreement by deleting your account on the
              Zedintro WebApp and discontinuing its use.
            </p>
            <p className="my-4">
              10.2 The Company may terminate this Agreement at any time without
              prior notice and without liability.
            </p>
            <p>
              10.3 Upon termination, your right to use the WebApp shall
              immediately cease.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">
              11. Governing Law and Dispute Resolution
            </h2>
            <p>
              11.1 This Agreement shall be governed by and construed in
              accordance with the laws of the Federal Republic of Nigeria,
              without regard to its conflict of law provisions.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">12. Severability</h2>
            <p>
              12.1 If any provision of this Agreement is held to be invalid,
              illegal, or unenforceable, the remaining provisions shall continue
              in full force and effect.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">13. Entire Agreement</h2>
            <p>
              13.1 This Agreement constitutes the entire agreement between you
              and the Company regarding the use of the Zedintro WebApp and
              supersedes any prior agreements or understandings, whether written
              or oral.
            </p>
          </Container>
        </section>

        <section>
          <Container>
            <p className="mt-4 font-bold">
              By using the Zedintro WebApp, you acknowledge that you have read,
              understood, and agree to be bound by these Terms and Conditions.
            </p>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
