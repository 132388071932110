import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const authleftBg = () => {
  const location = useLocation();

  return (
    <div>
      {!location.pathname.includes('/user') && (
        <div className="mt-20">
          <Link to="/">
            <h1 className="font-bold text-[32px] mb-5">Zedintro</h1>
          </Link>
          <p>
            Welcome to Zedintro, be introduced to and meet experts in your field
            and gain the knowledge you need to build a successful startup.
          </p>
        </div>
      )}

      {location.pathname.includes('/user') && (
        <div className="">
          <div className="mt-20">
            <Link to="/">
              <h1 className="font-bold text-[32px] mb-5">Zedintro</h1>
            </Link>
            <p>
              {location.pathname.includes('/login')
                ? 'Welcome to Zedintro, your time is money and here you can attach a price to your time and advice a Startup founder to help them grow.'
                : 'Welcome to Zedintro, be introduced to and meet experts in your field and gain the knowledge you need to build a successful startup.'}
            </p>
          </div>

          <div className="absolute inset-x-0 bottom-0 mb-8 px-20">
            <p>© {new Date().getFullYear()} Zedintro. All rights reserved.</p>
            <div className="flex gap-x-4 mt-2">
              <p>Terms</p>
              <p>Privacy</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default authleftBg;
