import React from 'react';
import { Link } from 'react-router-dom';

const expertAuthleftBg = () => {
  return (
    <div>
      <div className="mt-20">
        <Link to="/">
          <h1 className="font-bold text-[32px] mb-5">Zedintro</h1>
        </Link>
        <p>
          Welcome to Zedintro, your time is money and here you can attach a
          price to your time and advice a startup founder to help them grow.
        </p>
      </div>
      <div className="absolute inset-x-0 bottom-0 mb-8 px-20">
        <p>© {new Date().getFullYear()} Zedintro. All rights reserved.</p>
        <div className="flex gap-x-4 mt-2">
          <Link to="/terms">
            <p>Terms</p>
          </Link>
          <Link to="/privacy">
            <p>Privacy</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default expertAuthleftBg;
