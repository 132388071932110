export const EXPERT_TOKEN_KEY = 'exptToken';
export const BASE_DURATION = 15;
export const INDUSTRIES = [
  {
    id: 1,
    value: 'Agriculture',
    label: 'Agriculture',
  },

  {
    id: 2,
    value: 'Apparel and Fashion',
    label: 'Apparel and Fashion',
  },

  {
    id: 3,
    value: 'Automotive',
    label: 'Automotive',
  },

  {
    id: 4,
    value: 'Banking and Finance',
    label: 'Banking and Finance',
  },

  {
    id: 5,
    value: 'Construction',
    label: 'Construction',
  },
  {
    id: 6,
    value: 'Consulting',
    label: 'Consulting',
  },

  {
    id: 7,
    value: 'Energy and Utilities',
    label: 'Energy and Utilities',
  },

  {
    id: 8,
    value: 'Government and Public Administration',
    label: 'Government and Public Administration',
  },
  {
    id: 10,
    value: 'Healthcare',
    label: 'Healthcare',
  },

  {
    id: 11,
    value: 'Hospitality and Tourism',
    label: 'Hospitality and Tourism',
  },

  {
    id: 12,
    value: 'Information Technology',
    label: 'Information Technology',
  },

  {
    id: 13,
    value: 'Insurance',
    label: 'Insurance',
  },

  {
    id: 14,
    value: 'Manufacturing',
    label: 'Manufacturing',
  },

  {
    id: 15,
    value: 'Marketing and Advertising',
    label: 'Marketing and Advertising',
  },

  {
    id: 16,
    value: 'Non-profit and Social Services',
    label: 'Non-profit and Social Services',
  },

  {
    id: 17,
    value: 'Real Estate',
    label: 'Real Estate',
  },

  {
    id: 18,
    value: 'Retail',
    label: 'Retail',
  },

  {
    id: 19,
    value: 'Telecommunications',
    label: 'Telecommunications',
  },

  {
    id: 20,
    value: 'Transportation and Logistics',
    label: 'Transportation and Logistics',
  },

  {
    id: 21,
    value: 'Wholesale and Distribution',
    label: 'Wholesale and Distribution',
  },
  {
    id: 22,
    value: 'Architecture and Interior Design',
    label: 'Architecture and Interior Design',
  },
  {
    id: 23,
    value: 'Film and Television Production',
    label: 'Film and Television Production',
  },
  {
    id: 24,
    value: 'Music and Performing Arts',
    label: 'Music and Performing Arts',
  },
  {
    id: 25,
    value: 'Gaming and Interactive Entertainment',
    label: 'Gaming and Interactive Entertainment',
  },
  {
    id: 26,
    value: 'Insurance and Risk Management',
    label: 'Human Resources and Talent Management',
  },
  {
    id: 27,
    value: 'Insurance and Risk Management',
    label: 'Insurance and Risk Management',
  },
  {
    id: 28,
    value: 'Logistics and Supply Chain',
    label: 'Logistics and Supply Chain',
  },
  {
    id: 29,
    value: 'Pharmaceuticals and Biotechnology',
    label: 'Pharmaceuticals and Biotechnology',
  },
  {
    id: 30,
    value: 'Social Services and Welfare',
    label: 'Social Services and Welfare',
  },
  {
    id: 31,
    value: 'Telecommunications',
    label: 'Telecommunications',
  },
  {
    id: 32,
    value: 'Market Research and Analysis',
    label: 'Market Research and Analysis',
  },
  {
    id: 33,
    value: 'Cryptocurrency and Blockchain',
    label: 'Cryptocurrency and Blockchain',
  },
  {
    id: 34,
    value: 'Event Management and Planning',
    label: 'Event Management and Planning',
  },
  {
    id: 35,
    value: 'Aviation and Aerospace',
    label: 'Aviation and Aerospace',
  },
  {
    id: 36,
    value: 'Publishing and Journalism',
    label: 'Publishing and Journalism',
  },
  {
    id: 37,
    value: 'Beauty and Cosmetics',
    label: 'Beauty and Cosmetics',
  },
  {
    id: 38,
    value: 'Information Security',
    label: 'Information Security',
  },
  {
    id: 39,
    value: 'Marine and Maritime',
    label: 'Marine and Maritime',
  },
  {
    id: 40,
    value: 'Waste Management and Recycling',
    label: 'Waste Management and Recycling',
  },
  {
    id: 41,
    value: 'Forestry and Timber',
    label: 'Forestry and Timber',
  },
  {
    id: 42,
    value: 'Interior Decorating and Home Staging',
    label: 'Interior Decorating and Home Staging',
  },
  {
    id: 43,
    value: 'Social Media and Digital Marketing',
    label: 'Social Media and Digital Marketing',
  },
  {
    id: 44,
    value: 'Nanotechnology',
    label: 'Nanotechnology',
  },
  {
    id: 45,
    value: 'Software Development and Engineering',
    label: 'Software Development and Engineering',
  },
  {
    id: 46,
    value: 'Cybersecurity',
    label: 'Cybersecurity',
  },
  {
    id: 47,
    value: 'Artificial Intelligence (AI) and Machine Learning',
    label: 'Artificial Intelligence (AI) and Machine Learning',
  },
  {
    id: 48,
    value: 'Data Science and Analytics',
    label: 'Data Science and Analytics',
  },
  {
    id: 49,
    value: 'Cloud Computing',
    label: 'Cloud Computing',
  },
  {
    id: 50,
    value: 'Mobile App Development',
    label: 'Mobile App Development',
  },
  {
    id: 51,
    value: 'Web Development',
    label: 'Web Development',
  },
  {
    id: 52,
    value: 'Network Infrastructure and Systems Administration',
    label: 'Network Infrastructure and Systems Administration',
  },
  {
    id: 53,
    value: 'IT Project Management',
    label: 'IT Project Management',
  },
  {
    id: 54,
    value: 'E-commerce and Online Retail',
    label: 'E-commerce and Online Retail',
  },
  {
    id: 55,
    value: 'Digital Marketing and Advertising Technology',
    label: 'Digital Marketing and Advertising Technology',
  },
  {
    id: 56,
    value: 'Internet of Things (IoT)',
    label: 'Internet of Things (IoT)',
  },
  {
    id: 57,
    value: 'Virtual Reality (VR) and Augmented Reality (AR)',
    label: 'Virtual Reality (VR) and Augmented Reality (AR)',
  },
  {
    id: 58,
    value: 'Robotics and Automation',
    label: 'Robotics and Automation',
  },
  {
    id: 59,
    value: 'FinTech (Financial Technology)',
    label: 'FinTech (Financial Technology)',
  },
  {
    id: 60,
    value: 'HealthTech (Healthcare Technology)',
    label: 'HealthTech (Healthcare Technology)',
  },
  {
    id: 61,
    value: 'EdTech (Educational Technology)',
    label: 'EdTech (Educational Technology)',
  },
  {
    id: 62,
    value: 'CleanTech (Clean Technology)',
    label: 'CleanTech (Clean Technology)',
  },
  {
    id: 63,
    value: 'SaaS (Software as a Service)',
    label: 'SaaS (Software as a Service)',
  },
  {
    id: 64,
    value: 'Gaming and Game Development Technology',
    label: 'Gaming and Game Development Technology',
  },

  {
    id: 65,
    value: 'Sustainable Energy and Clean Technology',
    label: 'Sustainable Energy and Clean Technology',
  },
  {
    id: 66,
    value: 'Fitness and Wellness',
    label: 'Fitness and Wellness',
  },
  {
    id: 67,
    value: 'Web 3',
    label: 'Web 3',
  },
  {
    id: 67,
    value: 'Blockchain',
    label: 'Blockchain',
  },
  {
    id: 68,
    value: 'Other',
    label: 'Other',
  },
];
