import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router";
import profileIcon from '../../assets/icons/profile.svg'
import logoutIcon from '../../assets/icons/logout.svg'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileDropdown({
  className,
  children,
}) {
  const navigate = useNavigate()
  const handleLogout = () => {
    localStorage.removeItem('userToken')
    navigate('/login/user')
    window.location.reload()
  }

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="text-sm font-semibold text-gray-900">
          {children}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${
            className ? className : "right-0 mt-2"
          } z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-2">
              {/* <Menu.Item>
                {({ active }) => (
                  <p
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "block px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => navigate('/profile')}
                  >
                    Settings
                  </p>
                )}
              </Menu.Item> */}
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "px-4 py-2 text-sm cursor-pointer flex items-center gap-3"
                    )}
                    onClick={() => navigate('/profile')}
                  >
                    <div className="w-5 h-5">
                      <img src={profileIcon} alt="profile-icon" className="" />
                    </div>
                    Profile
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <p
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "px-4 py-2 text-sm cursor-pointer flex items-center gap-3"
                    )}
                    onClick={handleLogout}
                  >
                    <div className="w-5 h-5">
                      <img src={logoutIcon} alt="logout-icon" className="" />
                    </div>
                    Logout
                  </p>
                )}
              </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
