import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import LeftPane from './authleftBg';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getEnvVars } from '../../app/env';
import ReactLoading from 'react-loading';

const { REACT_APP_FRONTEND_URL, REACT_APP_API_URL } = getEnvVars();

const initialState = {
  email: '',
  passwordResetUrl: `${REACT_APP_FRONTEND_URL}/reset_password`,
};

const forgotPassword = () => {
  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const toastID = Math.random();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      await axios
        .post(`${REACT_APP_API_URL}/v1/auth/forgot-password`, state)
        .then((res) => {
          toast.success(res.data.message, { toastId: toastID });
          setLoading(false);
          navigate('/forgot_password_success');
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
      toast.error(error.response.data.message, { toastId: toastID });
    }
  };

  return (
    <div className="grid lg:grid-cols-5 text-sm auth">
      <div className="col-span-2 sticky top-0 px-16 hidden lg:block">
        <LeftPane />
      </div>
      <div className="col-span-3 px-12 sm:px-36">
        <div className="float-right mt-4">
          <Link to="/signup/user">
            <button className="border-[1.5px] border-purple-500 text-purple-500 rounded py-2 px-6">
              Sign in
            </button>
          </Link>
        </div>

        <div className="mt-40">
          <h1 className="font-semibold text-[28px] mt-56">Reset Password</h1>
          <p className="my-4">
            Please enter your registered email address to get a password reset
            link.
          </p>

          <form
            action=""
            method="post"
            className="text-left mt-8"
            onSubmit={handleSubmit}
          >
            <label htmlFor="email" className="font-medium">
              Email
            </label>
            <br />
            <input
              type="email"
              className="w-full border border-neutral-300 rounded-lg p-2"
              placeholder="Enter email"
              name="email"
              value={state.email}
              onChange={handleChange}
              required
            />
            <button className="btn-default w-full mt-8 mb-4 flex items-center justify-center space-x-2" type="submit">
              {loading && (
                <ReactLoading
                  type="cylon"
                  color="#fff"
                  className=""
                  height={20}
                  width={20}
                />
              )}
              Reset password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default forgotPassword;
