import React from 'react';
import { NavLink } from 'react-router-dom';
import { FiUser, FiLock, FiCalendar, FiCreditCard } from 'react-icons/fi';
import SettingDropdown from '../shared/SettingDropdown';

const Sidebar = ({ title }) => {
  const expertMenu = [
    {
      path: '/expert/account/profile',
      name: 'Profile',
      icon: <FiUser />,
    },

    {
      path: '/expert/account/password',
      name: 'Password',
      icon: <FiLock />,
    },

    {
      path: '/expert/account/availability',
      name: 'Availability',
      icon: <FiCalendar />,
    },

    {
      path: '/expert/account/payment',
      name: 'Payment',
      icon: <FiCreditCard />,
    },
  ];

  const userMenu = [
    {
      path: '/profile',
      name: 'Profile',
      icon: <FiUser />,
    },

    {
      path: '/password',
      name: 'Password',
      icon: <FiLock />,
    },
  ];

  return (
    <nav className="md:h-screen">
      <div className="sidebar hidden md:block">
        {window.location.pathname === '/profile' ||
        window.location.pathname === '/password'
          ? userMenu.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link flex items-center gap-2"
                activeclassname={`"active"`}
              >
                {item.icon}
                <div className="self-center">{item.name}</div>
              </NavLink>
            ))
          : window.location.pathname !== '/profile' ||
            window.location.pathname !== '/password'
          ? expertMenu.map((item, index) => (
              <NavLink
                to={item.path}
                key={index}
                className="link flex items-center gap-2"
                activeclassname={`"active"`}
              >
                {item.icon}
                <div className="self-center">{item.name}</div>
              </NavLink>
            ))
          : ''}
      </div>
      <SettingDropdown options={expertMenu} title={title} />
    </nav>
  );
};

export default Sidebar;
