import React from 'react';
import Navbar from '../components/layout/navbar';
import Footer from '../components/layout/footer';
import { Helmet } from 'react-helmet';
import FacebookPixel from '../utils/FacebookPixel';
import Container from '../components/shared/Container';

const About = () => {
  return (
    <div className="about mx-auto">
      <Helmet>
        <title>Access world-class industry experts on Zedintro</title>
        <meta
          name="description"
          content={
            'Access world-class industry experts at your convenience with ZedIntro. Our platform connects career and business professionals with top-notch mentors, offering valuable insights and guidance. Book time slots, reschedule if needed, and experience a simplified process through our user-friendly website and supportive community.'
          }
        />
      </Helmet>
      <FacebookPixel />
      <Navbar />
      <div className="pb-20">
        <section className="three bg-bgPurple sm:pt-36 pt-28 py-16 text-white">
          <Container>
            <h1 className="font-bold text-4xl lg:text-[57px] pb-4 lg:pb-8 md:leading-[3.5rem] leading-10">
              Access world-class industry experts with Zedintro
            </h1>

            <p className="lg:pr-20">
              Access world-class industry experts at your convenience with
              ZedIntro. Our platform connects career and business professionals
              with top-notch mentors, offering valuable insights and guidance.
              Book time slots, reschedule if needed, and experience a simplified
              process through our user-friendly website and supportive
              community.
            </p>
          </Container>
        </section>

        <section className="mt-20">
          <Container>
            <h2 className="font-bold text-3xl md:text-5xl pb-8">
              ABOUT ZEDINTRO
            </h2>
            <p>
              Life and business can be challenging, and achieving even the
              smallest measure of success often requires the guidance and
              support of mentors, friends, and family. Expert assistance from
              professionals with extensive experience is essential for
              individuals and businesses alike.
            </p>
            <p className=" mt-8">
              At ZedIntro, we believe there&apos;s a better way to access
              world-class industry experts. We have created a platform that
              offers valuable and immersive opportunities for career and
              business professionals to connect with industry experts and gain
              their insights.
            </p>
          </Container>
        </section>

        <section className="my-20">
          <Container>
            <h2 className="font-bold text-3xl md:text-5xl pb-6">OUR MISSION</h2>
            <p>
              Our mission at ZedIntro is simple yet powerful: to help people get
              access to quality industry experts at their convenience. With our
              user-friendly website and mobile view, you can easily select a
              date, book a time slot ranging from 15 to 60 minutes, make
              payment, and secure your slot.
            </p>
          </Container>
        </section>
        <section className="py-8 md:py-20">
          <Container>
            <div className="flex flex-col md:flex-row gap-8 justify-between">
              <div className="md:w-1/2">
                <h2 className="font-bold text-3xl md:text-5xl pb-6">
                  About us
                </h2>
                <p className="text-gray-800">
                  We understand that emergencies happen or plans change, so we
                  offer flexibility. If you need to reschedule, simply let us
                  know. In case an expert is unavailable, they can cancel the
                  call, and you will receive a refund within 24 hours. <br />
                  <br />
                  Accessing industry experts can be a complex and opaque
                  process, but we see it as an opportunity. At ZedIntro, we
                  simplify this problem for everyone by offering innovative
                  software, educational resources, and a supportive community so
                  businesses and individuals can access world-class industry
                  experts.
                </p>
              </div>

              <div>
                <div className="bg-gray-200 w-full md:w-1/2 md:min-w-[30rem] h-[20rem] md:h-full"></div>
              </div>
            </div>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default About;
