import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import confirm from "../../assets/icons/confirm.svg";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { updateSession } from "../../features/session/sessionSlice";

const RequestedCall = ({ approveState, handleClose }) => {
  const dispatch = useDispatch();
  const { updateStatus } = useSelector((state) => state.session);
  const [state, setState] = useState(true);
  const { callId } = useParams();

  useEffect(() => {
    if (updateStatus === "success") {
      setState(false);
    }
  }, []);

  const handleDecline = () => {
    dispatch(
      updateSession({
        id: callId,
        admin: location.pathname.includes("expert") ? true : false,
        status: "declined",
      })
    );
  };

  return (
    <div>
      {!approveState ? (
        <>
          {state ? (
            <div className="rounded-2xl max-w-xl relative card mx-auto">
              <h1 className="font-semibold text-[28px] text-center px-12 pb-8">
                Are you sure you want to decline this call?
              </h1>
              <div className="pt-6 grid grid-cols-2 gap-x-10">
                <button
                  className="btn-default w-full flex items-center gap-2 justify-center"
                  onClick={handleDecline}
                >
                  {updateStatus === "loading" && (
                    <ReactLoading
                      type="cylon"
                      color="#fff"
                      className=""
                      height={20}
                      width={20}
                    />
                  )}
                  Yes
                </button>
                <button
                  className="btn-default w-full !bg-[#EDDBFF] !text-[#270058]"
                  onClick={() => handleClose()}
                >
                  No
                </button>
              </div>
            </div>
          ) : (
            <div className="text-center mt-32 mb-40 card rounded-2xl max-w-xl relative mx-auto">
              <img src={confirm} className="mx-auto" alt="confirm-icon" />
              <h1 className="font-bold text-3xl leading-9 my-8">
                Your call was successfully declined.
              </h1>
              <Link to={"/expert/call"}>
                <button className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded py-3 px-5 w-40">
                  Done
                </button>
              </Link>
            </div>
          )}
        </>
      ) : (
        <div className="text-center mt-32 mb-40 card rounded-2xl max-w-xl relative mx-auto">
          <img src={confirm} className="mx-auto" alt="confirm-icon" />
          <h1 className="font-bold text-3xl leading-9 my-8">
            Your call was successfully approved.
          </h1>
          <p className="px-4">Click to return to your reserved sessions</p>
          <Link to={"/expert/call"}>
            <button className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded py-3 px-5 w-40">
              Done
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default RequestedCall;
