import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { getEnvVars } from '../app/env'

const { REACT_APP_GOOGLE_AUTH_CLIENT_ID } = getEnvVars()

const GoogleProvider = (props) => {
    return (
      <GoogleOAuthProvider clientId={REACT_APP_GOOGLE_AUTH_CLIENT_ID}>
        {props.children}
      </GoogleOAuthProvider>
    );
  };

export default GoogleProvider