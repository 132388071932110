/* eslint-disable react/react-in-jsx-scope */
import './Tile.css';
import { DailyVideo, useVideoTrack } from '@daily-co/daily-react';
// import Username from '../Username/Username';
import { useParticipantProperty } from '@daily-co/daily-react';
import { useState } from 'react';
import { FaEllipsisH } from 'react-icons/fa';

export default function Tile({
  id,
  isScreenShare,
  isLocal,
  isAlone,
  pinScreen,
  pinLocal,
}) {
  const videoState = useVideoTrack(id);
  const username = useParticipantProperty(id, 'user_name');

  const [menuState, setMenuState] = useState(false);

  const splitName = () => {
    let initials = '';
    if (username) {
      let NameArr = username.split(' ');
      if (NameArr.length >= 2) {
        initials += NameArr[0].charAt(0) + NameArr[1].charAt(0);
      } else {
        initials += NameArr[0].charAt(0);
      }
      return initials;
    }
  };

  let containerCssClasses = isScreenShare ? 'tile-screenshare' : 'tile-video';

  if (isLocal) {
    containerCssClasses += ' self-view';
    if (isAlone) {
      containerCssClasses += ' alone';
    }
  }

  const pin = () => {
    pinScreen();
  };

  const handlePin = () => {
    setMenuState(!menuState);
  };

  return (
    <div className={containerCssClasses}>
      {videoState.isOff ? (
        <div className="w-full h-full bg-white rounded-lg flex justify-center items-center shadow-lg relative cursor-pointer">
          {isLocal && (
            <FaEllipsisH
              className="absolute top-4 right-8 cursor-pointer"
              onClick={() => handlePin()}
            />
          )}
          <p className="text-[3rem]">{username && splitName()}</p>
          {menuState && (
            <div className="bg-[#34435E] absolute top-8 right-4 w-[100px] py-[10px] px-[10px] text-white">
              <ul>
                <li onClick={() => pin()}>{!pinLocal ? 'pin' : 'unpin'}</li>
              </ul>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-full relative">
          {isLocal && (
            <FaEllipsisH
              className="absolute top-4 right-8 text-white z-10 cursor-pointer"
              onClick={() => handlePin()}
            />
          )}
          <DailyVideo
            automirror
            sessionId={id}
            type={isScreenShare ? 'screenVideo' : 'video'}
          />
          {menuState && (
            <div className="bg-[#34435E] absolute top-8 right-4 w-[100px] py-[10px] px-[10px] text-white">
              <ul>
                <li onClick={() => pin()}>{!pinLocal ? 'pin' : 'unpin'}</li>
              </ul>
            </div>
          )}
        </div>
      )}

      {/* {!isScreenShare && <Username id={id} isLocal={isLocal} />} */}
    </div>
  );
}
