import React from 'react';

const Error = ({ className, children }) => {
  return (
    <p className={`text-red-800 text-xs absolute -bottom-5 ${className}`}>
      {children}
    </p>
  );
};

export default Error;
