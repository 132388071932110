import React from 'react';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import confirm from '../../assets/icons/confirm.svg';
import FacebookPixel from '../../utils/FacebookPixel';

const SignupSuccess = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get("redirect_to") ?? ""

  return (
    <>
      <FacebookPixel />
      <div className="h-screen flex flex-col items-center">
        <div className="flex flex-wrap justify-center items-center font-semibold mt-16 sm:mt-24 mb-24 sm:mb-32">
          <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
            Get Started
          </button>
          {location.pathname.includes('expert') && (
            <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
              Complete Setup
            </button>
          )}
          <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
            Email Verification
          </button>
          <button className="border-b-2 border-textLight text-textLight py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
            Email Confirmation
          </button>
        </div>

        <div className="text-center max-w-[30rem]">
          <img src={confirm} className="mx-auto" alt="icon" />

          <h1 className="font-bold text-2xl md:text-3xl leading-9 sm:my-8 my-5">
            Congratulations, your account was successfully created!!
          </h1>

          <p className="max-w-[23rem] m-auto">
            Please check your email for a confirmation link. Link expires after
            48 hours.
          </p>

          {location.pathname.includes('expert') ? (
            <Link to="/login/expert">
              <button className="sm:mt-8 mt-5 mb-4 font-medium bg-[#00164F] text-white rounded-lg py-2 sm:py-3 px-5 min-w-[10rem]">
                Done
              </button>
            </Link>
          ) : (
            <Link to={`/login/user?redirect_to=${redirectTo}`}>
              <button className="sm:mt-8 mt-5 mb-4 font-medium bg-[#00164F] text-white rounded-lg py-2 sm:py-3 px-5 min-w-[10rem]">
                Done
              </button>
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default SignupSuccess;
