import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { getEnvVars } from '../../app/env';
import { toast } from 'react-toastify';

/**
 * @param {string} timezone https://www.localeplanet.com/icu/en-NG/timezone.html
 * @returns {'NGN' | 'GHS' | 'UGX' | 'ZAR' | 'USD' | 'TZS' | 'KES'}
 */
const getDefaultCurrency = function (timezone) {
  // Source: https://www.localeplanet.com/icu/en-NG/timezone.html
  // You can decide to use the language locale at https://www.localeplanet.com/icu/
  const timezoneCurrencyMap = {
    'Africa/Lagos': 'NGN',
    'Africa/Accra': 'GHS',
    'Africa/Kampala': 'UGX',
    'Africa/Johannesburg': 'ZAR',
    'Africa/Dar_es_Salaam': 'TZS',
    'Africa/Nairobi': 'KES',
  };

  const currency = timezoneCurrencyMap[timezone] || 'USD';
  return currency;
};

const initialState = {
  profile: null,
  searchParams: '',
  expertsData: [],
  getStatus: 'idle',
  updateStatus: 'idle',
  userNameStatus: 'idle',
  error: null,
  currency: getDefaultCurrency(
    new Intl.DateTimeFormat().resolvedOptions().timeZone,
  ),
  giftRecipient: null,
  redirectPath: false,
  rateInUserCurrency: null,
};

const { REACT_APP_API_URL: URL } = getEnvVars();

const config = {
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('userToken'),
  },
};

export const getProfessionals = createAsyncThunk(
  'get/professionals',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/search?limit=10&skip=${payload.skip}&orderBy=-rating&${payload.searchField}=${payload.name}`;
    try {
      const res = await axios.get(api, config);
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.message);
    }
  },
);

export const getUser = createAsyncThunk(
  'get/user',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/clients/${payload.id}`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken'),
      },
    };

    try {
      const res = await axios.get(api, configReq);
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      localStorage.removeItem('userToken');
      return rejectWithValue(err.message);
    }
  },
);

export const updateUser = createAsyncThunk(
  'update/user',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/clients/profile`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('userToken'),
      },
    };
    try {
      const res = await axios.patch(
        api,
        {
          firstName: payload.firstName,
          lastName: payload.lastName,
          bio: payload.bio,
          location: payload.location,
          jobTitle: payload.jobTitle,
          company: payload.company,
          industry: payload.industry,
          profilePhoto: payload.profilePhoto,
        },
        configReq,
      );
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const setClientUsername = createAsyncThunk(
  'update/expertUsername',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/clients/profile/username`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };

    try {
      const res = await axios.patch(
        api,
        {
          username: payload,
        },
        configReq,
      );
      if (res.status && res.status === 200) {
        localStorage.setItem('userName', payload);
        toast.success('Username saved successfully');
        return payload;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  },
);

const currencyRatesCache = {};

export const setRateInUserCurrency = createAsyncThunk(
  'currency.rate',
  async (currency, { rejectWithValue }) => {
    if (currency == 'USD') return { amount: 1 };

    if (currency in currencyRatesCache) {
      return { amount: currencyRatesCache[currency] };
    }

    const api = `${URL}/v1/payments/flutterwave/currency-conversion?currency=${currency}&amount=${1}`;
    const clientConfig = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    try {
      const res = await axios.get(api, clientConfig);
      if (res.status && res.status === 200) {
        currencyRatesCache[currency] = res.data.data.amount;
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetGetStatus: (state) => {
      state.getStatus = 'idle';
    },
    searchText: (state, action) => {
      state.searchParams = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setGiftRecipient: (state, action) => {
      state.giftRecipient = action.payload;
    },
    setRedirectPath: (state, action) => {
      state.redirectPath = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUser.pending, (state) => {
        state.getStatus = 'loading';
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.getStatus = 'success';
        state.profile = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.getStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(getProfessionals.pending, (state) => {
        state.getStatus = 'loading';
      })
      .addCase(getProfessionals.fulfilled, (state, action) => {
        state.getStatus = 'success';
        state.expertsData = action.payload;
      })
      .addCase(getProfessionals.rejected, (state, action) => {
        state.getStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(updateUser.pending, (state) => {
        state.updateStatus = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.updateStatus = 'success';
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.updateStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(setClientUsername.pending, (state) => {
        state.userNameStatus = 'loading';
      })
      .addCase(setClientUsername.fulfilled, (state) => {
        state.userNameStatus = 'success';
      })
      .addCase(setClientUsername.rejected, (state) => {
        state.userNameStatus = 'failed';
      })
      .addCase(setRateInUserCurrency.pending, (state) => {
        state.rateInUserCurrency = null;
      })
      .addCase(setRateInUserCurrency.fulfilled, (state, action) => {
        state.rateInUserCurrency = action.payload.amount;
      });
  },
});

export const {
  resetGetStatus,
  searchText,
  setCurrency,
  setGiftRecipient,
  setRedirectPath,
} = userSlice.actions;

export default userSlice.reducer;
