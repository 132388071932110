import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dashGuide: 1,
};

export const appSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setDashGuide: (state, action) => {
      state.dashGuide = action.payload;
    },
  },
});

export const { setDashGuide } = appSlice.actions;

export default appSlice.reducer;
