import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getEnvVars } from '../../app/env';

const initialState = {
  expert: null,
  availabilitySlots: [],
  expertAvailability: null,
  availabilityStatus: 'idle',
  getAvailabilityStatus: 'idle',
  requestStatus: 'idle',
  calendarStatus: 'idle',
  mediaStatus: 'idle',
  error: null,
  media: null,
  username: null,
  bankDetails: null,
};

const { REACT_APP_API_URL: URL, REACT_APP_FRONTEND_URL } = getEnvVars();

export const getAvailability = createAsyncThunk(
  'check/availability',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/availability?date=${payload.day}&month=${payload.month}&year=${payload.year}&expertId=${payload.id}`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    try {
      const res = await axios.get(api, configReq);
      if (res.status && res.status === 200) {
        const resKeysArray = Object.keys(res.data.data);
        if (resKeysArray.length === 0) {
          return [];
        }
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  },
);

export const addAvailability = createAsyncThunk(
  'add/availability',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/availability`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };
    try {
      const res = await axios.put(api, { slots: payload }, configReq);
      if (res.status && res.status === 200) {
        profileAvailability();
        toast.success(res.data.message, {
          pauseOnFocusLoss: false,
          position: toast.POSITION.TOP_RIGHT,
        });
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message, {
        pauseOnFocusLoss: false,
        position: toast.POSITION.TOP_RIGHT,
      });
      return rejectWithValue(err.response.data.message);
    }
  },
);

export const profileAvailability = createAsyncThunk(
  'profile/availability',
  async (_, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/availability/me`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };
    try {
      const res = await axios.get(api, configReq);
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  },
);

export const getExpert = createAsyncThunk(
  'get/expert',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/${payload.id}`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };
    try {
      const res = await axios.get(api, configReq);
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      localStorage.removeItem('exptToken');
      localStorage.removeItem('userToken');
      window.location.reload();
      console.error(err);
    }
  },
);

export const getExpertBank = createAsyncThunk(
  'get/expertBank',
  async (_, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/bank`;
    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };
    try {
      const res = await axios.get(api, config);
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  },
);

export const getProfile = createAsyncThunk(
  'get/expertProfile',
  async (payload, { rejectWithValue }) => {
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };
    const api = `${URL}/v1/experts/profile`;
    try {
      const res = await axios.get(api, configReq);
      if (res.status && res.status === 200) {
        localStorage.setItem('exptId', res.data.data.id);
        localStorage.setItem('exptRating', res.data.data.rating);

        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      localStorage.removeItem('exptToken');
      localStorage.removeItem('userToken');
      window.location.reload();
      console.error(err);
    }
  },
);

export const updateProfile = createAsyncThunk(
  'update/expertProfile',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/profile`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };

    try {
      const res = await axios.patch(
        api,
        {
          firstName: payload.firstName,
          lastName: payload.lastName,
          bio: payload.bio,
          location: payload.location,
          jobTitle: payload.jobTitle,
          company: payload.company,
          industry: payload.industry,
          skills: payload.skills,
          profilePhoto: payload.profilePhoto,
          fee: payload.fee,
          calendarUrl: payload.url,
          timezone: payload.timezone,
          minutesPerUnitSession: payload.minutesPerUnitSession,
        },
        configReq,
      );
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      toast.error(err.response.data.message);
      console.error(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const expertUsername = createAsyncThunk(
  'update/expertUsername',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/profile/username`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };

    try {
      const res = await axios.patch(
        api,
        {
          username: payload,
        },
        configReq,
      );
      if (res.status && res.status === 200) {
        return payload;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  },
);

export const publicProfile = createAsyncThunk(
  'public/expertUsername',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/experts/profile/public?username=${payload}`;
    const configReq = {
      headers: {
        Authorization: 'Bearer ' + localStorage.exptToken,
      },
    };

    try {
      const res = await axios.get(
        api,
        {
          username: payload,
        },
        configReq,
      );
      if (res.status && res.status === 200) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createProfile = createAsyncThunk(
  'create/expertProfile',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/auth/register-expert`;

    const reqData = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      password: payload.password,
      bio: payload.bio,
      location: payload.location,
      industry: payload.industry,
      jobTitle: payload.jobTitle,
      company: payload.company,
      profilePhotoUrl: payload.profilePhotoUrl,
      homepageUrl: REACT_APP_FRONTEND_URL,
      verificationSuccessfulUrl: `${REACT_APP_FRONTEND_URL}/expert_verification_success`,
      referralCode: payload.referralCode,
    };

    for (const key in reqData) {
      if (reqData[key] === undefined || reqData[key].trim() === '') {
        delete reqData[key];
      } else {
        reqData[key] = reqData[key].trim();
      }
    }

    try {
      const res = await axios.post(api, reqData);

      if (res.status && res.status === 201) {
        toast.success('Account created successfully');
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.response.data.message);
      return rejectWithValue(err.response.data);
    }
  },
);

export const expertMedia = createAsyncThunk(
  'upload/media/expert',
  async (payload, { rejectWithValue }) => {
    const api = `${URL}/v1/media`;
    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.userToken,
      },
    };

    const formData = new FormData();
    formData.append('media', payload.photo);

    try {
      const res = await axios.post(api, formData, {
        ...config,
        headers: {
          ...config.headers,
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      });
      if (res.status && res.status === 201) {
        return res.data.data;
      } else {
        return rejectWithValue(res.data);
      }
    } catch (err) {
      console.error(err);
      return rejectWithValue(err.response.data);
    }
  },
);

export const expertSlice = createSlice({
  name: 'expert',
  initialState,
  reducers: {
    resetMedia: (state) => {
      state.mediaStatus = 'idle';
    },
    resetCalendar: (state) => {
      state.calendarStatus = 'idle';
    },
    resetAvailability: (state) => {
      state.availabilityStatus = 'loading';
    },
    resetUsername: (state) => {
      state.username = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailability.pending, (state) => {
        state.availabilityStatus === 'loading';
      })
      .addCase(getAvailability.fulfilled, (state, action) => {
        state.availabilitySlots = action.payload;
        state.availabilityStatus = 'success';
      })
      .addCase(getAvailability.rejected, (state, action) => {
        state.availabilityStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(getExpert.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(getExpert.fulfilled, (state, action) => {
        state.expert = action.payload;
        state.requestStatus = 'success';
      })
      .addCase(getExpert.rejected, (state, action) => {
        state.error = action.payload;
        state.requestStatus = 'failed';
      })
      .addCase(getProfile.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.expert = action.payload;
        state.requestStatus = 'success';
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.requestStatus = 'failed';
      })
      .addCase(updateProfile.pending, (state) => {
        state.calendarStatus = 'loading';
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.calendarStatus = 'success';
        state.expert = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.calendarStatus = 'failed';
      })
      .addCase(expertUsername.fulfilled, (state, action) => {
        state.requestStatus = 'success';
        state.username = action.payload;
      })
      .addCase(expertUsername.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(expertUsername.rejected, (state, action) => {
        state.error = action.payload;
        state.requestStatus = 'failed';
      })
      .addCase(expertMedia.pending, (state) => {
        state.mediaStatus = 'loading';
      })
      .addCase(expertMedia.fulfilled, (state, action) => {
        state.media = action.payload;
        state.mediaStatus = 'success';
      })
      .addCase(expertMedia.rejected, (state, action) => {
        state.error = action.payload;
        state.mediaStatus = 'failed';
      })
      .addCase(createProfile.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(createProfile.fulfilled, (state, action) => {
        state.requestStatus = 'success';
        state.expert = action.payload;
      })
      .addCase(createProfile.rejected, (state, action) => {
        state.requestStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(publicProfile.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(publicProfile.fulfilled, (state, action) => {
        state.requestStatus = 'success';
        state.expert = action.payload;
      })
      .addCase(publicProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.requestStatus = 'failed';
      })
      .addCase(addAvailability.pending, (state) => {
        state.availabilityStatus = 'loading';
      })
      .addCase(addAvailability.fulfilled, (state, action) => {
        state.availabilityStatus = 'success';
        state.availabilitySlots = action.payload;
      })
      .addCase(addAvailability.rejected, (state, action) => {
        state.availabilityStatus = 'failed';
        state.error = action.payload;
      })
      .addCase(profileAvailability.pending, (state) => {
        state.getAvailabilityStatus = 'loading';
      })
      .addCase(profileAvailability.fulfilled, (state, action) => {
        state.expertAvailability = action.payload;
        state.getAvailabilityStatus = 'success';
      })
      .addCase(profileAvailability.rejected, (state, action) => {
        state.error = action.payload;
        state.getAvailabilityStatus = 'failed';
      })
      .addCase(getExpertBank.pending, (state) => {
        state.requestStatus = 'loading';
      })
      .addCase(getExpertBank.fulfilled, (state, action) => {
        state.requestStatus = 'success';
        state.bankDetails = action.payload;
      })
      .addCase(getExpertBank.rejected, (state, action) => {
        state.requestStatus = 'failed';
        state.error = action.payload;
      });
  },
});

export const { resetMedia, resetCalendar, resetAvailability, resetUsername } =
  expertSlice.actions;

export default expertSlice.reducer;
