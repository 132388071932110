import React from 'react';
import Layout from '../components/layout/layout';
import calendar from '../assets/icons/calendar.svg';
import Header from '../components/banner';
import { RxMagnifyingGlass } from 'react-icons/rx';
import ProtectedRoute from '../utils/ProtectedRoute';
import Container from '../components/shared/Container';

const EmptyCall = () => {
  return (
    <ProtectedRoute>
      <div className="altNav">
        <Layout>
          <Header title="Calls" pathUrl={'/'} />
          <Container>
            <div className="text-center">
              <img src={calendar} className="mx-auto" alt="calendar-icon" />

              <div className="my-12">
                <p className="text-xl font-semibold">
                  Looks like you don’t have any booked call
                </p>
                <p className="text-neutral-500">
                  It’s easy to book a call. You can start by searching for an
                  expert
                </p>
              </div>

              <button className="flex btn-default mx-auto">
                <RxMagnifyingGlass className="self-center mr-1.5" size={25} />{' '}
                <span>Search for experts</span>
              </button>
            </div>
          </Container>
        </Layout>
      </div>
    </ProtectedRoute>
  );
};

export default EmptyCall;
