import React from 'react';
import Navbar from '../components/layout/navbar';
import AlternateFooter from '../components/layout/AlternateFooter';
import confirm from '../assets/icons/confirm.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ProtectedRoute from '../utils/ProtectedRoute';

const GiftConfirmation = () => {
  const navigate = useNavigate();
  const { savedGiftedSession } = useSelector((state) => state.session);

  return (
    <ProtectedRoute>
      <div className="confirmation mx-auto altNav">
        <Navbar />
        <div className="text-center mx-4 mt-32 mb-40">
          {/* <div className="bg-gray-100 rounded-lg h-60 w-4/5 lg:w-1/2 mx-auto"></div> */}
          <img src={confirm} className="mx-auto" alt="confirm-icon" />
          <h1 className="font-bold text-3xl leading-9 my-8">
            You’re ready to Zedintro!
          </h1>
          <p className="px-4 md:px-44 lg:px-80 xl:px-96">
            Your call with{' '}
            {`${savedGiftedSession?.firstName} ${savedGiftedSession?.lastName}`}{' '}
            has been set,
          </p>
          <button
            className="mt-8 mb-4 font-medium bg-[#00164F] text-white rounded py-3 px-5 w-40"
            onClick={() => navigate('/Call/')}
          >
            Go to calls
          </button>
        </div>
        <div className="absolute right-0 bottom-0 left-0">
          <AlternateFooter />
        </div>
      </div>
    </ProtectedRoute>
  );
};

export default GiftConfirmation;
