import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Index from './pages/Index';
import About from './pages/About';
import Search from './pages/Search';
import SearchResults from './pages/Searchresults';
import Book from './pages/Book';
import Schedule from './pages/Schedule';
import BookConfirmation from './pages/bookConfirmation';
import RequestConfirmation from './pages/requestConfirmation';
import LoginUser from './pages/auth/loginUser';
import LoginExpt from './pages/auth/loginExpt';
import ForgotPassword from './pages/auth/forgotPassword';
import SignupUser from './pages/auth/signupUser';
import SignupExpert from './pages/auth/signupExpt';
import EmptyCall from './pages/EmptyCall';
import RateCall from './pages/rateCall';
import Availability from './pages/expertDashboard/Availability';
import Profile from './pages/expertDashboard/Profile';
import Payment from './pages/expertDashboard/Payment';
import Password from './pages/expertDashboard/Password';
import Dashboard from './pages/expertDashboard/Dashboard';
import EmailVerificationSuccess from './pages/auth/emailVerificationSuccess';
import ResetPassword from './pages/auth/resetPassword';
import ForgotPasswordSuccess from './pages/auth/forgotPasswordSuccess';
import ResetPasswordSuccess from './pages/auth/resetPasswordSuccess';
import Contact from './pages/Contact';
import UserProfile from './pages/Profile';
import UserPassword from './pages/Password';
import ExptCallDetail from './pages/expertDashboard/callDetail';
import CallDetail from './pages/callDetail';
import GoogleProvider from './components/googleProvider';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './styles/scss/app.scss';
import SessionPayment from './pages/SessionPayment';
import Calls from './pages/Callpage';
import Gift from './pages/Gift';
import Ratings from './pages/expertDashboard/Ratings';
import CallsNotifications from './pages/expertDashboard/CallsNotifications';
import Callpage from './pages/Callpage';
import ClaimGift from './pages/claimGift';
import GiftConfirmation from './pages/giftConfirmation';
import SignupSuccess from './pages/auth/SignupSuccess';
import EmailVerificationExpert from './pages/auth/emailVerificationExpert';
import ExpertProfile from './components/expert/ExpertProfile';
import ExpertErrorpage from './pages/ExpertErrorpage';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import GoogleSuccess from './pages/auth/GoogleSuccess';
import Meeting from './pages/Meeting';

function App() {
  return (
    <GoogleProvider>
      <Router>
        <Routes>
          {/* <Route exact path="/" element={<Index />} /> */}
          <Route exact path="/about" element={<About />} />
          <Route exact path="/meeting" element={<Meeting />} />
          <Route exact path="/search" element={<Search />} />
          <Route exact path="/privacy" element={<Privacy />} />
          <Route exact path="/terms" element={<Terms />} />
          <Route exact path="/search/results" element={<SearchResults />} />
          <Route exact path="/book/:id" element={<Book />} />
          <Route exact path="/schedule" element={<Schedule />} />
          <Route exact path="/book/:id/payment" element={<SessionPayment />} />
          <Route exact path="/schedule/payment" element={<Schedule />} />
          <Route exact path="/call" element={<Calls />} />
          <Route exact path="/call/empty" element={<EmptyCall />} />
          <Route exact path="/call/rate/:sessionId" element={<RateCall />} />
          {/* edit expert layout*/}
          <Route
            exact
            path="/expert/call/rate/:sessionId"
            element={<RateCall />}
          />
          {/*  */}
          <Route exact path="/gift/:id" element={<Gift />} />
          <Route exact path="/gift-book/:id" element={<ClaimGift />} />
          <Route
            exact
            path="/book/payment/confirmation/:id"
            element={<BookConfirmation />}
          />
          <Route
            exact
            path="/request/confirmation"
            element={<RequestConfirmation />}
          />
          <Route
            exact
            path="/gift/confirmation/:id"
            element={<GiftConfirmation />}
          />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/call/detail/:callId" element={<CallDetail />} />
          <Route exact path="/profile" element={<UserProfile />} />
          <Route exact path="/password" element={<UserPassword />} />

          {/* Auth Pages */}
          <Route exact path="/login/user" element={<LoginUser />} />
          <Route exact path="/login/expert" element={<LoginExpt />} />
          <Route exact path="/signup/user" element={<SignupUser />} />
          <Route exact path="/signup/expert" element={<SignupExpert />} />
          <Route exact path="/forgot_password" element={<ForgotPassword />} />
          <Route
            exact
            path="/forgot_password_success"
            element={<ForgotPasswordSuccess />}
          />
          <Route
            exact
            path="/email_verification_success"
            element={<EmailVerificationSuccess />}
          />
          <Route
            exact
            path="/expert_verification_success"
            element={<EmailVerificationExpert />}
          />
          <Route exact path="/signup_success" element={<SignupSuccess />} />
          <Route
            exact
            path="/expert/signup_success"
            element={<SignupSuccess />}
          />
          <Route
            exact
            path="/expert/google_success"
            element={<GoogleSuccess />}
          />
          <Route exact path="/reset_password" element={<ResetPassword />} />
          <Route
            exact
            path="/reset_password_success"
            element={<ResetPasswordSuccess />}
          />

          {/* Expert Pages */}
          <Route
            exact
            path="/expert/account/availability"
            element={<Availability />}
          />
          <Route exact path="/expert/error" element={<ExpertErrorpage />} />
          <Route
            exact
            path="/user/profile/:username"
            element={<ExpertProfile />}
          />
          <Route exact path="/user/:username" element={<ExpertProfile />} />
          <Route exact path="/expert/account/profile" element={<Profile />} />
          <Route exact path="/expert/account/payment" element={<Payment />} />
          <Route exact path="/expert/account/password" element={<Password />} />
          <Route exact path="/expert/Call/" element={<Callpage />} />
          <Route exact path="/expert/dashboard" element={<Dashboard />} />
          <Route exact path="/expert/dashboard/ratings" element={<Ratings />} />
          <Route
            exact
            path="/expert/CallsNotifications"
            element={<CallsNotifications />}
          />
          <Route
            exact
            path="/expert/call/detail/:callId"
            element={<ExptCallDetail />}
          />
        </Routes>
      </Router>
    </GoogleProvider>
  );
}

export default App;
