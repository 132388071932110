import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FiCalendar, FiCreditCard, FiLock, FiUser } from 'react-icons/fi';
import { BiChevronDown } from 'react-icons/bi';
import { useNavigate } from 'react-router';
// import profileIcon from "../../assets/icons/profile.svg";
// import logoutIcon from "../../assets/icons/logout.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SettingDropdown({ options, className, title }) {
  const navigate = useNavigate();
  return (
    <Menu as="div" className="relative inline-block text-left mb-12 md:hidden">
      <div>
        <Menu.Button className="text font-semibold text-gray-900 flex items-center gap-4">
          <div className="">
            {title === 'Profile' ? (
              <FiUser className=" text-textPurple font-bold text-xl" />
            ) : title === 'Password' ? (
              <FiLock className=" text-textPurple font-bold text-xl" />
            ) : title === 'Availability' ? (
              <FiCalendar className=" text-textPurple font-bold text-xl" />
            ) : (
              <FiCreditCard className="text-textPurple font-bold text-xl" />
            )}
          </div>
          {title}
          <BiChevronDown className="text-textPurple text-xl" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${
            className ? className : 'left-0 mt-2'
          } z-10 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-2">
            {options.map((item) => (
              <Menu.Item key={item.path}>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'px-4 py-2 text-sm cursor-pointer flex items-center gap-3',
                    )}
                    onClick={() => navigate(item.path)}
                  >
                    <div className="icon">{item.icon}</div>
                    <div className="">{item.name}</div>
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
