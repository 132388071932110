import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout/layout';
import { BsChevronLeft } from 'react-icons/bs';
import Header from '../../components/banner';
import { useNavigate } from 'react-router-dom';
import person from '../../assets/icons/person.svg';
import ReactStars from 'react-rating-stars-component';
import axios from 'axios';
import ProtectedRoute from '../../utils/ProtectedRoute';
import { getEnvVars } from '../../app/env';
import Container from '../../components/shared/Container';

const { REACT_APP_API_URL } = getEnvVars();
const Ratings = () => {
  const [ratings, setRatings] = useState([]);
  const navigate = useNavigate();
  const ratingChanged = () => {
    // console.log(newRating);
  };

  const [token /*setToken*/] = useState(() => localStorage.exptToken);
  const [expertId /*setExpertId*/] = useState(() => localStorage.expertId);
  const headers = {
    Authorization: 'Bearer ' + token,
  };

  useEffect(() => {
    const getData = async () => {
      const api = `${REACT_APP_API_URL}/v1/reviews/?expertId=${expertId}&limit=5&skip=0`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const dataInfo = res.data.data.docs;

          setRatings(dataInfo);
        })
        .catch(function (error) {
          console.error(error);
          //   navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };

    getData();
  }, [token]);

  return (
    <ProtectedRoute>
      <div className="altNav">
        <Layout>
          <Header title="Dasboard" />
          <Container>
            <div className="grid gap-y-6">
              <p
                onClick={() => navigate('/expert/dashboard')}
                className="font-bold ml-4 flex"
              >
                <span className="mt-1 font-bold">
                  <BsChevronLeft />
                </span>
                Customer feedbacks
              </p>
              {ratings.map((rating) => (
                <div
                  key={rating?.id}
                  className=" rounded-2xl shadow-sm card cursor-pointer"
                >
                  <div className="flex ">
                    <img
                      src={person}
                      className="rounded-full h-14 w-14"
                      alt="person-image"
                    />
                    <div className="flex flex-col ml-2 mt-2">
                      <p className="font-bold ml-2">Amaka</p>
                      <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={12}
                        classNames="star-rate"
                        // color2=""
                        // name='rating'
                        name="read-only"
                        value={rating?._doc?.rating}
                        edit={false}
                      />
                    </div>
                  </div>

                  <p className="mt-3">{rating?._doc?.comment}</p>
                </div>
              ))}
            </div>
          </Container>
        </Layout>
      </div>
    </ProtectedRoute>
  );
};

export default Ratings;
