import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LeftPane from './authleftBg'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import { getEnvVars } from '../../app/env';

const {REACT_APP_FRONTEND_URL, REACT_APP_API_URL} = getEnvVars()

const toastID = Math.random();
const ResetPassword = () => {
    const [searchParams, /*setSearchParams*/] = useSearchParams();
    const token = searchParams.get("token")
    const userId = searchParams.get("userId")
    const initialState = {
        password: '',
        token: token,
        userId: userId,
        homepageUrl: REACT_APP_FRONTEND_URL,
    }
    const [state, setState] = useState(initialState)
    const [confirm_pswd, setConfirm_Pswd] = useState('')

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(state.password !== confirm_pswd){
            toast.error("Confirm password doesn't match, please try again", {toastId: toastID})
        }else{

            try{
                await axios.post (`${REACT_APP_API_URL}/v1/auth/reset-password`, state
                )
                .then(res => {
                    toast.success(res.data.message, {toastId: toastID})
                    navigate('/reset_password_success')
                  })
                }catch(err) {
                    console.error(err);
                    // err.response.data.message
                    toast.error(err.response.data.message, {toastId: toastID})
                }
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState((prevState) => ({ ...prevState, [name]: value }));
    };


  return (
    <div className='grid lg:grid-cols-5 text-sm auth'>
        <div className="col-span-2 sticky top-0 px-16 hidden lg:block">
            <LeftPane/>
        </div>
        <div className="col-span-3 px-12 sm:px-36">
            <ToastContainer pauseOnFocusLoss={false}/>

            <h1 className='font-semibold text-[28px] mt-40'>Create new password</h1>
            <p className='text-neutral-500 pt-4'>A password reset link has successfully been sent to your email address.</p>

            <form action="" method="post" className='text-left mb-20 mt-10 grid gap-6' onSubmit={handleSubmit}>
                <div className="">
                    <label htmlFor="new password" className='font-medium'>New Password</label><br />
                    <input type="password" className='w-full border border-neutral-300 rounded-lg p-2' placeholder='Enter new password' minLength={8} name='password' onChange={handleChange} value={state.password} required/>
                </div>

                <div className="">
                    <label htmlFor="confirm password" className='font-medium'>Confirm Password</label><br />
                    <input type="password" className='w-full border border-neutral-300 rounded-lg p-2' placeholder='Re-enter new password' minLength={8} name='confirm_password' onChange={(e) => setConfirm_Pswd(e.target.value)} value={confirm_pswd} required/>
                </div>

                <button type='submit' className='btn-default w-full mt-8 mb-4'>Reset Password</button>
            </form>
        </div>
    </div>
  )
}

export default ResetPassword