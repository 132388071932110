import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Error from '../../shared/Error';
import { getEnvVars } from '../../../app/env';
import axios from 'axios';
import ReactLoading from 'react-loading';
import SelectMenu from '../../shared/SelectMenu';
import { INDUSTRIES } from '../../../data/constants';
import { BiChevronDown, BiChevronLeft } from 'react-icons/bi';
import CountryDropDown from '../../shared/CountryDropDown';
import countryList from 'react-select-country-list';
import { useDispatch, useSelector } from 'react-redux';
import { createProfile } from '../../../features/experts/expertSlice';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

const { REACT_APP_API_URL } = getEnvVars();

const StepTwoForm = ({ stepOneData, userName, handleStepOne }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { requestStatus } = useSelector((state) => state.expert);
  const [loading, setLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);
  const [validate, setValidate] = useState({
    valLocation: false,
    valIndustry: false,
  });
  const [state, setState] = useState({
    location: '',
    industry: '',
  });
  const countriesList = useMemo(() => countryList().getData(), []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (requestStatus === 'success') {
      sessionStorage.removeItem('referralId');
      navigate(`/expert/signup_success`);
    }
  }, [requestStatus]);

  async function handleFileInputChange(event) {
    event.preventDefault();
    const exptImg = event.target.files[0];
    const formData = new FormData();
    formData.append('media', exptImg);

    try {
      setLoading(true);
      const config = {
        headers: {
          'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
        },
      };

      const res = await axios.post(
        `${REACT_APP_API_URL}/v1/media`,
        formData,
        config,
      );

      if (res.status && res.status === 201) {
        setImgUrl(res.data.data);
      }

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  }

  const handleFormSubmit = (data) => {
    setValidate({
      valLocation: state.location === '',
      valIndustry: state.industry === '',
    });

    if (state.location === '' || state.industry === '') return;

    if (!imgUrl) {
      toast.error('Upload your profile image');
      return;
    }

    let reqObj = {
      ...state,
      ...data,
      ...stepOneData,
      profilePhotoUrl: imgUrl.url,
    };

    if (userName) {
      reqObj = { ...reqObj, referralCode: userName };
    }

    dispatch(createProfile(reqObj));
  };

  const handleSelect = (item, field) => {
    setState({
      ...state,
      [field]: field === 'location' ? item.label : item.value,
    });

    if (field === 'location') {
      setValidate({ ...validate, valLocation: false });
    } else {
      setValidate({ ...validate, valIndustry: false });
    }
  };

  const handleValidation = () => {
    setValidate({
      valLocation: state.location === '',
      valIndustry: state.industry === '',
    });
  };

  return (
    <div className="w-full">
      <div className="flex flex-wrap justify-center items-center sm:mt-[12rem] mt-[26rem] mb-4 sm:mb-10">
        <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-4 px-3 text-xs sm:text-sm">
          Get Started
        </button>
        <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-4 px-3 text-xs sm:text-sm">
          Complete Setup
        </button>
        <button className="border-b-2 border-textLight text-textLight py-2 sm:px-4 px-3 text-xs sm:text-sm">
          Email Verification
        </button>
        <button className="border-b-2 border-textLight text-textLight py-2 sm:px-4 px-3 text-xs sm:text-sm">
          Email Confirmation
        </button>
      </div>

      <h1
        className="font-semibold sm:text-2xl text-xl flex items-center gap-3 cursor-pointer"
        onClick={() => handleStepOne()}
      >
        <BiChevronLeft className="sm:text-4xl text-2xl" /> Complete Expert
        Account
      </h1>

      <form
        className="text-left mt-10 grid gap-y-5 my-20"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className="grid gap-y-5">
          <div className="flex flex-col gap-1">
            <label htmlFor="image" className="font-medium">
              Profile Photo
            </label>

            <div className="flex items-center">
              <input
                type="file"
                name=""
                accept="image/*"
                onChange={handleFileInputChange}
              />
              {loading && (
                <ReactLoading
                  type="cylon"
                  color="#270058"
                  className=""
                  height={20}
                  width={20}
                />
              )}
            </div>

            <small className="text-gray-400">
              Image should be at least 800x800 pixels.
            </small>
          </div>

          <div className="grid md:grid-cols-2 gap-x-4 gap-y-5">
            <div className="flex flex-col gap-1 relative">
              <label htmlFor="jobtitle" className="font-medium">
                Job title
              </label>

              <input
                type="text"
                name="jobTitle"
                className="w-full border border-gray-300 rounded-md p-2"
                placeholder="Job title"
                {...register('jobTitle', {
                  required: 'Required',
                })}
              />

              <Error>{errors.jobTitle && errors.jobTitle.message}</Error>
            </div>

            <div className="flex flex-col gap-1 relative">
              <label htmlFor="company" className="font-medium">
                Company
              </label>

              <input
                type="text"
                name="company"
                className="w-full border border-gray-300 rounded-md p-2"
                placeholder="Company"
                {...register('company', {
                  required: 'Required',
                })}
              />

              <Error>{errors.company && errors.company.message}</Error>
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-x-4 gap-y-5">
            <div className="relative flex flex-col gap-1">
              <label htmlFor="industry" className="font-medium text-sm">
                Industry
              </label>

              <SelectMenu
                optionField={'industry'}
                className={'w-full max-h-[15rem] overflow-y-auto mt-4'}
                options={INDUSTRIES}
                handleSelect={handleSelect}
              >
                <div className="w-full border border-gray-300 text-gray-600 rounded-md p-2 text-sm font-normal flex justify-between items-center">
                  {state.industry ? state.industry : 'Select industry'}
                  <BiChevronDown className="text-xl text-black" />
                </div>
              </SelectMenu>

              <Error>{validate.valIndustry && 'Required'}</Error>
            </div>

            <div className="flex flex-col gap-1 relative">
              <label htmlFor="location" className="font-medium text-sm">
                Location
              </label>

              <CountryDropDown
                optionField={'location'}
                className={'w-full max-h-[15rem] overflow-y-auto mt-4'}
                options={countriesList}
                handleSelect={handleSelect}
              >
                <div className="w-full border border-gray-300 text-gray-600 rounded-md p-2 text-sm font-normal flex justify-between items-center">
                  {state.location ? state.location : 'Location'}
                  <BiChevronDown className="text-xl text-black" />
                </div>
              </CountryDropDown>
              <Error>{validate.valLocation && 'Required'}</Error>
            </div>
          </div>

          <div className="flex flex-col gap-1 relative">
            <label htmlFor="bio" className="font-medium">
              Bio
            </label>

            <textarea
              name="bio"
              id="bio"
              cols="30"
              rows="5"
              className="w-full border border-gray-300 rounded-md p-2"
              placeholder="Write a bit about yourself."
              {...register('bio', {
                required: 'Required',
              })}
            ></textarea>

            <Error>{errors.bio && errors.bio.message}</Error>
          </div>

          <div className="flex sm:items-center space-x-2 m-0">
            <input type="checkbox" name="" id="" />
            <label className="text-neutral-700 flex flex-wrap space-x-2 items-center text-xs sm:text-base">
              By signing up I agree to ZedIntro
              <Link to="/terms" className="text-bgPurple font-bold">
                Terms & Conditions
              </Link>
              and
              <Link to="/privacy" className="text-bgPurple font-bold">
                Privacy Policy
              </Link>
            </label>
          </div>

          <button
            type="submit"
            onClick={handleValidation}
            className="w-full bg-[#270058] text-white rounded p-2 flex items-center justify-center gap-2"
          >
            {requestStatus === 'loading' && (
              <ReactLoading
                type="cylon"
                color="#fff"
                className=""
                height={20}
                width={20}
              />
            )}
            Sign Up
          </button>

          <p className="text-neutral-700 text-center flex gap-2 items-center justify-center mt-4">
            Already have an account?
            <Link to="/login/expert/" className="text-purple-500">
              Sign in
            </Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default StepTwoForm;
