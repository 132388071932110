import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { useNavigate } from 'react-router';
import menuIcon from '../../assets/icons/vertical.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SessionDropdown({ className, session }) {
  const navigate = useNavigate();
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
    >
      <div>
        <Menu.Button className="text font-semibold text-gray-900 flex items-center gap-4">
          <img src={menuIcon} alt="menu" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={`absolute ${
            className ? className : 'left-0 mt-2'
          } z-10 origin-top-right w-[10rem] rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none -translate-x-32`}
        >
          <div className="py-2">
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-2 text-sm cursor-pointer flex items-center gap-3',
                  )}
                  onClick={() => navigate(`/call/rate/${session.id}`)}
                >
                  <div className="">Report Expert</div>
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'px-4 py-2 text-sm cursor-pointer flex items-center gap-3 w-',
                  )}
                  onClick={() => navigate(`/call/rate/${session.id}`)}
                >
                  <div className="">Rate Expert</div>
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
