import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  let location = useLocation();

  const token = location.pathname.includes('expert')
    ? localStorage.exptToken
    : localStorage.userToken;

  if (!token && location.pathname.includes('expert')) {
    return <Navigate to="/login/expert" state={{ from: location }} replace />;
  } else if (!token && !location.pathname.includes('expert')) {
    sessionStorage.setItem('path', location.pathname);
    return (
      <Navigate
        to={`/login/user?redirect_to=${location.pathname || ''}`}
        state={{ from: location }}
        replace
      />
    );
  }
  return <>{children}</>;
};

export default ProtectedRoute;
