import React, { useEffect, useState } from 'react';
import Navbar from '../components/layout/navbar';
import AlternateFooter from '../components/layout/AlternateFooter';
import flutter from '../assets/icons/flutter.svg';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import ReactLoading from 'react-loading';

import {
  BsCalendar3,
  BsClock,
  BsHourglassSplit,
  BsChevronLeft,
} from 'react-icons/bs';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetSession,
  singleSession,
  // sessionPayment,
} from '../features/session/sessionSlice';
import ProtectedRoute from '../utils/ProtectedRoute';
// import { BASE_DURATION } from '../data/constants';
import { getEnvVars } from '../app/env';
import { setRateInUserCurrency } from '../features/user/userSlice';
import Container from '../components/shared/Container';

const {
  REACT_APP_FLW_PUBLIC_KEY,
  REACT_APP_PAYMENT_APP_ID,
  REACT_APP_FRONTEND_URL,
} = getEnvVars();

const options = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const SessionPayment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currency, rateInUserCurrency, redirectPath, profile } = useSelector(
    (state) => state.user,
  );
  const { id } = useParams();
  const [checkedBtn, setCheckedBtn] = useState(false);
  const [paid, setPaid] = useState(false);

  const { paymentStatus, session, requestStatus } = useSelector(
    (state) => state.session,
  );

  const getDuration = () => {
    const duration = Math.abs(
      Math.round(
        (new Date(session?.startsAt).getTime() -
          new Date(session?.endsAt).getTime()) /
          60000,
      ),
    );
    return duration;
  };

  const config = {
    public_key: REACT_APP_FLW_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: rateInUserCurrency * session?.fee,
    // amount: rateInUserCurrency * session?.fee * (getDuration() / BASE_DURATION),
    currency: currency /* !== 'USD' ? 'NGN' : 'USD' */,
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: profile?.email,
      name: `${profile?.firstName} ${profile?.lastName}`,
    },
    meta: {
      sessionId: session?.id,
      sessionUrl: `${REACT_APP_FRONTEND_URL}/expert/call/detail/${session?.id}`,
      app: REACT_APP_PAYMENT_APP_ID,
      sessionType: 'regular',
    },
    customizations: {
      title: session?.title,
      description: session?.agenda,
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg', // TODO Change this logo to a Zedintro logo
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  useEffect(() => {
    dispatch(singleSession({ id }));
    return () => {
      dispatch(resetSession());
    };
  }, []);

  useEffect(() => {
    if (requestStatus === 'failed' && redirectPath) {
      navigate(-1);
    }
    if (paid) {
      navigate(`/book/payment/confirmation/${id}`);
    }
  }, [paymentStatus, paid]);

  useEffect(() => {
    if (session) {
      dispatch(setRateInUserCurrency(currency));
    }
  }, [session, currency]);

  const handlePayment = () => {
    // We're using webhooks now so no need for this
    // dispatch(
    //   sessionPayment({
    //     sessionId: id,
    //     provider: 'flutterwave',
    //     transactionId: res?.transaction_id.toString(),
    //   }),
    // );
    setPaid(true);
  };

  function btnCheck(e) {
    setCheckedBtn(e.target.checked);
  }

  const handleNavigate = () => {
    dispatch(resetSession());
    navigate(-1);
  };

  return (
    <ProtectedRoute>
      <div className="payment mx-auto altNav relative">
        <ToastContainer />
        <Navbar />
        <div className="title">
          <Container>
            <div
              className="flex py-4 sm:text-[28px] text-xl cursor-pointer"
              onClick={handleNavigate}
            >
              <Link to="/book" className="self-center">
                <BsChevronLeft className="font-bold pr-2" />
              </Link>
              <p className="">Check Out</p>
            </div>
          </Container>
          <hr className="shadow-sm" />
        </div>
        <Container>
          <div className="lg:grid lg:grid-cols-2 mx-auto pt-12 pb-28 gap-8">
            <div className="container">
              <div className="bg-white sm:p-8 p-5 sm:rounded-2xl rounded-xl">
                <div className="flex sm:mb-4 sm:h-20 h-16 object-cover">
                  <img
                    src={session?.participant?.profilePhoto}
                    alt="img"
                    className="w-20 h-full rounded-lg"
                  />

                  <div className="grid items-start w-full pl-4">
                    <p className="font-semibold sm:text-xl text-lg sm:pr-2 capitalize">
                      {session?.participant &&
                        session?.participant?.firstName +
                          ' ' +
                          session?.participant?.lastName}
                    </p>

                    <p className="text-sm sm:text-base">
                      {session?.participant && session?.participant?.jobTitle}
                    </p>
                  </div>
                </div>

                <p className="sm:text-[22px] text-lg py-4">
                  {session?.title && session?.title} call with{' '}
                  {session?.participant && (
                    <span className="capitalize">
                      {`${session?.participant?.firstName}
                    ${session?.participant?.lastName}`}
                    </span>
                  )}
                </p>

                <div className="grid gap-2">
                  <p className="flex text-sm sm:text-base">
                    <BsCalendar3 className="text-gray-400 self-center mr-1.5" />{' '}
                    {session &&
                      new Date(session?.startsAt).toLocaleDateString(
                        'en-US',
                        options,
                      )}
                  </p>

                  <p className="flex text-sm sm:text-base">
                    <BsClock className="text-gray-400 self-center mr-1.5" />{' '}
                    {session &&
                      new Date(session?.startsAt).toLocaleTimeString()}{' '}
                    -{' '}
                    {session && new Date(session?.endsAt).toLocaleTimeString()}
                  </p>

                  <p className="flex text-sm sm:text-base">
                    <BsHourglassSplit className="text-gray-400 self-center mr-1.5" />{' '}
                    {session && getDuration()} Minutes
                  </p>
                </div>
              </div>

              <div className="bg-white sm:rounded-2xl rounded-xl sm:p-8 p-5 sm:mt-12 mt-10">
                <h3 className="font-semibold text-xl sm:pb-4 pb-3">
                  Payment summary
                </h3>

                <div className="grid gap-x-16 md:gap-x-80 lg:gap-x-36">
                  <hr className="my-3" />

                  <div className="flex">
                    <p className="text-gray-400">Call fee</p>
                    <p className="text-gray-800 font-medium ml-auto">
                      {session && rateInUserCurrency
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency,
                          }).format(session.fee * rateInUserCurrency)
                        : null}
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-gray-400 py-2">Tax</p>
                    <p className="text-gray-800 font-medium py-2 ml-auto">
                      {session && rateInUserCurrency
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency,
                          }).format(0)
                        : null}
                    </p>
                  </div>

                  <hr className="my-3" />
                  <div className="flex">
                    <p className="text-gray-400 text-lg pt-2">Subtotal</p>
                    <p className="text-gray-800 text-lg font-medium pt-2 ml-auto">
                      {session && rateInUserCurrency
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency,
                          }).format(session.fee * rateInUserCurrency)
                        : null}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="mt-12 lg:mt-0 bg-white sm:rounded-2xl rounded-xl shadow-sm sm:p-8 p-5">
                <div className="flex sm:pb-4">
                  <h3 className="font-semibold text-xl text-gray-800">
                    Payment Information
                  </h3>
                </div>

                <div className="flex items-center sm:my-6 my-4">
                  <img src={flutter} alt="flutter-icon" />
                  <p className="text-sm sm:text-base">Pay with Flutterwave</p>
                </div>

                <div className="flex items-center sm:mb-8 mb-5">
                  <input
                    type="checkbox"
                    name="consent"
                    id="payment_consent"
                    onChange={btnCheck}
                  />
                  <label
                    htmlFor="payment_consent"
                    className="ml-3 sm:text-sm text-xs text-ellipsis"
                  >
                    I have read and accepted the terms of use and privacy
                    policy.
                  </label>
                </div>

                <button
                  type="submit"
                  id="payBtn"
                  className={`${
                    checkedBtn === false ? 'bg-gray-400' : 'bg-[#00164F]'
                  } mt-2 text-white rounded sm:py-3 py-2 px-5 w-full flex items-center gap-2 justify-center`}
                  disabled={!checkedBtn}
                  onClick={() => {
                    handleFlutterPayment({
                      callback: (response) => {
                        handlePayment(response);
                        closePaymentModal();
                      },
                      onClose: () => {
                        closePaymentModal();
                      },
                    });
                  }}
                >
                  {paymentStatus === 'loading' && (
                    <ReactLoading
                      type="cylon"
                      color="#fff"
                      className=""
                      height={20}
                      width={20}
                    />
                  )}
                  Pay
                </button>
              </div>
            </div>
          </div>
        </Container>
        <AlternateFooter />
      </div>
    </ProtectedRoute>
  );
};

export default SessionPayment;
