// import { DailyProvider } from '@daily-co/daily-react';
import { DailyAudio, DailyProvider } from '@daily-co/daily-react';
import React from 'react';
import Call from '../../pages/dailyVideo/components/Call/Call';
import Tray from '../../pages/dailyVideo/components/Tray/Tray';

const CallInterface = ({ callObject, startLeavingCall }) => {
  return (
    <div className="min-h-screen bg-[#34435E]">
      <DailyProvider callObject={callObject}>
        <Call />
        <Tray leaveCall={startLeavingCall} />
        <DailyAudio />
      </DailyProvider>
    </div>
  );
};

export default CallInterface;
