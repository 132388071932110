import React, { useState, useEffect } from 'react';
import Navbar from '../components/layout/navbar';
import AlternateFooter from '../components/layout/AlternateFooter';
import { BsChevronLeft, BsHourglassSplit } from 'react-icons/bs';
import {
  Link,
  useParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import axios from 'axios';
import ReactLoading from 'react-loading';
import flutter from '../assets/icons/flutter.svg';
import { useFlutterwave /* closePaymentModal */ } from 'flutterwave-react-v3';
import {
  getGiftedSession, // giftSession,
} from '../features/session/sessionSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUser,
  setRateInUserCurrency,
  setGiftRecipient,
} from '../features/user/userSlice';
import ProtectedRoute from '../utils/ProtectedRoute';
import { getEnvVars } from '../app/env';
import Container from '../components/shared/Container';

const {
  REACT_APP_API_URL,
  REACT_APP_FLW_PUBLIC_KEY,
  REACT_APP_FRONTEND_URL,
  REACT_APP_PAYMENT_APP_ID,
} = getEnvVars();

const Gift = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: expertId } = useParams();
  const [searchParams] = useSearchParams();
  const { paymentStatus, requestStatus, session /* price */ } = useSelector(
    (state) => state.session,
  );
  const { profile } = useSelector((state) => state.user);
  const { currency, rateInUserCurrency, giftRecipient } = useSelector(
    (state) => state.user,
  );
  const [expertProfile, setExpertProfile] = useState();
  const [userId /*setUserId*/] = useState(() => localStorage.userId);

  const [checkedBtn, setCheckedBtn] = useState(false);

  useEffect(() => {
    if (requestStatus === 'failed') {
      navigate(-1);
    }
    if (paymentStatus === 'success') {
      dispatch(
        getGiftedSession({
          firstName: expertProfile.firstName,
          lastName: expertProfile.lastName,
        }),
      );
      navigate(`/gift/confirmation/${session.id}`);
    }
  }, [paymentStatus]);

  useEffect(() => {
    (() => {
      const api = `${REACT_APP_API_URL}/v1/experts/${expertId}`;
      axios
        .get(api)
        .then((result) => {
          const expert = result.data.data;
          setExpertProfile(expert);
        })
        .catch(function (error) {
          console.error(error);
        });
    })();
  }, []);

  useEffect(() => {
    dispatch(setRateInUserCurrency(currency));
  }, []);

  const config = {
    public_key: REACT_APP_FLW_PUBLIC_KEY,
    tx_ref: Date.now(),
    // amount: rateInUserCurrency * expertProfile?.fee * (getDuration() / BASE_DURATION),
    currency: currency,
    // TODO Set number of minutes per gift
    amount: expertProfile?.fee * rateInUserCurrency * 2,
    payment_options: 'card,mobilemoney,ussd',
    customer: {
      email: profile?.email,
      name: `${profile?.firstName} ${profile?.lastName}`,
    },
    customizations: {
      title: 'Gift a call',
      logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
    },
    meta: {
      gifterId: profile?.id,
      expertId: expertId,
      recipientName: searchParams.get('name') || giftRecipient?.name,
      recipientEmail: searchParams.get('email') || giftRecipient?.email,
      app: REACT_APP_PAYMENT_APP_ID,
      homepageUrl: REACT_APP_FRONTEND_URL,
      sessionType: 'gift',
    },
  };

  useEffect(() => {
    dispatch(getUser({ id: userId }));
  }, []);

  const handleFlutterPayment = useFlutterwave(config);

  const handlePayment = () => {
    dispatch(setGiftRecipient(null));
  };

  function btnCheck(e) {
    setCheckedBtn(e.target.checked);
  }

  return (
    <ProtectedRoute>
      <div className="payment mx-auto altNav">
        <Navbar />
        <div className="title">
          <Container>
            <div className="flex py-4 text-[28px]">
              <Link to="/search/results" className="self-center">
                <BsChevronLeft className="font-bold pr-2" />
              </Link>
              <p className="">Check Out</p>
            </div>
          </Container>
          <hr className="shadow-sm" />
        </div>
        <Container>
          <div className="lg:grid lg:grid-cols-2 mx-auto pt-12 pb-28 gap-8">
            <div className="container">
              <div className="card bg-white p-8 rounded-2xl">
                <div className="flex">
                  <img
                    src={expertProfile?.profilePhoto}
                    alt=""
                    className=" rounded-lg"
                    style={{ width: '70px', height: '60px' }}
                  />
                  <div className="grid items-start pl-4">
                    <p className="font-semibold text-xl pr-2">
                      {expertProfile &&
                        expertProfile?.firstName +
                          ' ' +
                          expertProfile?.lastName}
                    </p>
                    <p className="">
                      {expertProfile && expertProfile?.jobTitle}
                    </p>
                  </div>
                </div>
                <div className="grid gap-y-4 mt-10">
                  <p className="text-[22px]">Gifted call session</p>
                  <p className="flex">
                    <BsHourglassSplit className="self-center mr-2" size={20} />
                    <span>30 Minutes</span>
                  </p>
                </div>
              </div>

              <div className="bg-white rounded-2xl p-8 mt-12">
                <h3 className="font-semibold text-xl pb-4">Payment summary</h3>
                <div className="grid gap-x-16 md:gap-x-80 lg:gap-x-36">
                  <div className="flex">
                    <p className="text-gray-400">Call fee</p>
                    <p className="text-gray-800 font-medium ml-auto">
                      {rateInUserCurrency && expertProfile
                        ? new Intl.NumberFormat('en-US', {
                            style: 'currency',
                            currency,
                          }).format(expertProfile.fee * 2 * rateInUserCurrency)
                        : null}
                    </p>
                  </div>

                  <div className="flex">
                    <p className="text-gray-400 py-2">VAT (0%)</p>
                    <p className="text-gray-800 font-medium py-2 ml-auto">
                      {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency,
                      }).format(0)}
                    </p>
                  </div>

                  <hr className="my-3" />

                  <div className="flex font-semibold">
                    <p className="text-lg pt-2">Total</p>
                    {expertProfile && (
                      <p className="text-gray-800 text-lg pt-2 ml-auto">
                        {rateInUserCurrency && expertProfile
                          ? new Intl.NumberFormat('en-US', {
                              style: 'currency',
                              currency,
                            }).format(
                              expertProfile.fee * 2 * rateInUserCurrency,
                            )
                          : null}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <form className="card !bg-[#E8E0EB] p-8 rounded-2xl mt-12 lg:mt-0 lg:mb-12">
                <>
                  <p className="font-semibold text-xl">Gift Added</p>
                  <p className="text-sm pt-1">
                    This call will be sent as a gift to{' '}
                    <span className="text-purple-500">
                      {giftRecipient?.email || searchParams.get('email')}
                    </span>
                  </p>
                  <button
                    className="px-6 py-1.5 mt-6 rounded-lg font-medium text-sm border-2 border-[#270058] text-[#270058]"
                    onClick={() => navigate('/search/results')}
                  >
                    Remove Gift
                  </button>
                </>
              </form>

              <div className="mt-12 lg:mt-0 card bg-white rounded-2xl shadow-sm p-8">
                <div className="flex pb-4">
                  <h3 className="font-semibold text-xl text-gray-800">
                    Payment Information
                  </h3>
                </div>

                <div className="grid">
                  <div className="flex items-center mb-4">
                    <img src={flutter} alt="flutter-icon" />
                    <p className="ml-2">Pay with Flutterwave</p>
                  </div>
                  <div className="flex">
                    <input
                      type="checkbox"
                      name=""
                      id="payment_consent"
                      onChange={btnCheck}
                      required
                    />
                    <label
                      htmlFor="payment_consent"
                      className="text-gray-400 text-sm ml-2"
                    >
                      I have read and accepted the terms of use and privacy
                      policy.
                      {/* TODO Please include a link to this policy */}
                    </label>
                  </div>
                </div>

                <button
                  type="submit"
                  id="payBtn"
                  className={`${
                    checkedBtn === false ? 'bg-gray-400' : 'btn-default'
                  } mt-4 text-white rounded-lg py-3 px-5 w-full flex items-center gap-2 justify-center`}
                  disabled={!checkedBtn}
                  onClick={() => {
                    handleFlutterPayment({
                      callback: (response) => {
                        handlePayment(response);
                      },
                      onClose: () => {
                        navigate('/search');
                      },
                    });
                  }}
                >
                  {paymentStatus === 'loading' && (
                    <ReactLoading
                      type="cylon"
                      color="#fff"
                      className=""
                      height={20}
                      width={20}
                    />
                  )}
                  Pay
                </button>
              </div>
            </div>
          </div>
        </Container>
        <AlternateFooter />
      </div>
    </ProtectedRoute>
  );
};

export default Gift;
