const skillOption = [
  {
    id: 1,
    value: 'Tech',
  },
  {
    id: 2,
    value: 'Sales',
  },
  {
    id: 3,
    value: 'Product analytics',
  },
  {
    id: 4,
    value: 'Email marketing',
  },
  {
    id: 5,
    value: 'Product management',
  },
];

const priceFromOption = [
  {
    id: 1,
    value: '0',
  },
  {
    id: 2,
    value: '10',
  },
  {
    id: 3,
    value: '25',
  },
  {
    id: 4,
    value: '50',
  },
  {
    id: 5,
    value: '100',
  },
  {
    id: 6,
    value: '250',
  },
  {
    id: 7,
    value: '500',
  },
];

const priceToOption = [
  {
    id: 1,
    value: '10',
  },
  {
    id: 2,
    value: '25',
  },
  {
    id: 3,
    value: '50',
  },
  {
    id: 4,
    value: '100',
  },
  {
    id: 5,
    value: '250',
  },
  {
    id: 6,
    value: '500',
  },
  {
    id: 7,
    value: '1000',
  },
];

const limitOption = [
  {
    id: 1,
    value: '10',
  },
  {
    id: 2,
    value: '20',
  },
  {
    id: 3,
    value: '30',
  },
  {
    id: 4,
    value: '40',
  },
  {
    id: 5,
    value: '50',
  },
];

const orderOption = [
  {
    id: 1,
    value: 'Low Ratings',
    option: '-rating',
  },
  {
    id: 2,
    value: 'High Ratings',
    option: 'rating',
  },
  {
    id: 3,
    value: 'Low Fees',
    option: '-fee',
  },
  {
    id: 4,
    value: 'High Fees',
    option: 'fee',
  },
];

const searchOption = [
  {
    id: 1,
    value: 'By Expert’s Name',
    option: 'name',
  },
  {
    id: 2,
    value: 'By Expert’s Skill',
    option: 'skill',
  }
];

export {
  orderOption,
  skillOption,
  priceFromOption,
  priceToOption,
  limitOption,
  searchOption
};
