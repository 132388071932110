
import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../components/layout/navbar'
import AlternateFooter from '../components/layout/AlternateFooter'

import user from '../assets/imgs/user.png'
import star from '../assets/icons/fivestar.svg'
import verified from '../assets/icons/verified.svg'

import { FiLoader, FiClock, FiCalendar, FiFile } from "react-icons/fi";

import '../styles/scss/pages/book.scss'

const Book = () => {
  return (
    <div className='book mx-auto altNav'>
        <Navbar />
        <div className="mx-auto container px-8 lg:px-0 2xl:px-36">
            <div className="nav bg-gray-100 rounded-3xl text-gray-300 text-center md:w-9/12 lg:w-4/6 my-4 mx-auto">
                <div className="grid grid-cols-3 py-4 px-4 items-center">
                    <span className="text-gray-900 rounded-2xl font-bold py-2 text-sm break-words lg:text-lg bg-white">Call Details</span>
                    <span className="hover:bg-white hover:text-gray-900 hover:rounded-2xl hover:font-bold py-2 text-sm break-words lg:text-lg font-medium">Payment Information</span>
                    <span className="hover:bg-white hover:text-gray-900 hover:rounded-2xl hover:font-bold py-2 text-sm break-words lg:text-lg font-medium">Confirmation</span>
                </div>
            </div>

            <div className="lg:grid lg:grid-cols-2 grid-rows-1 py-12 maingrid">
                <div className="container firstChild md:px-40 lg:px-0">
                    <img src={user} alt="user-image"/>
                    <div className="pt-2 flex">
                        <p className='text-2xl font-semibold pr-2 name leading-8'>Emeka Obi</p>
                        <img src={verified} alt="verified-icon" />
                    </div>            
                    <p className='text-sm py-2'>
                    Entrepreneur and Co-founder at Fit+
                    </p>
                    <div className="flex">
                        <img src={star} alt="rating-icon" className=""/>
                        <span className='text-gray-800 text-sm font-semibold pt-1'>5.0</span>
                    </div>
                    
                    <p className='text-xl font-semibold pr-2 name leading-8'>About Me</p>
                    <p className='text-sm py-2 container w-60 md:w-80'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus diam commodo augue urna nunc fames.</p>

                    <span className="flex text-xs text-gray-700 py-2 font-medium">
                    <p className="bg-gray-100 px-2 rounded-xl">Business</p>
                    <p className="bg-gray-100 px-2 mx-1 rounded-xl">Cryptocurrency</p>
                    <p className="bg-gray-100 px-2 rounded-xl">Fitness</p>
                    </span>
                </div>

                <div className="container secondChild pt-12 lg:pt-0 md:px-24 lg:px-0">
                    <h2 className='text-2xl font-bold text-gray-800 py-2'>Schedule a Video Call</h2>
                    <p className='text-gray-500 w-2/3 lg:w-10/12'>Select the date and time duration that you’ll be available for a video call.</p>

                    <div className="flex pt-6">
                        <FiFile className='mr-4' size={19}/>
                        <input type="text" className='py-2 px-4 border border-gray-300 rounded w-96' placeholder='Add Title'/>
                    </div>

                    <div className="flex pt-6">
                        <FiCalendar className='mr-4' size={19}/>
                            <input type="date" name="" id="" className='py-2 px-4 border border-gray-300 rounded w-72' placeholder="< Wednesday, Ferbuary 23, 2022 >"/>
                    </div>
                    

                    <div className="flex pt-6">
                        <FiClock className='mr-5 lg:mr-2' size={19}/>
                        <div className="grid grid-cols-2 md:grid-cols-1">
                            <div className="">
                                <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>10 - 11 AM</button> 
                                <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>11 - 12 AM</button> 
                                <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>12 - 1 PM</button> 
                                <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>1 - 2 PM</button>
                            </div>

                            <div className=''>
                                <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>2 -3 PM</button> 
                                <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>3 -4 PM</button> 
                                <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>4 - 5 PM</button> 
                                <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>5 - 6 PM</button>
                            </div>
                        </div>
                    </div>


                    <div className="flex pt-4">
                        <FiLoader className='mr-2' size={19}/>
                        <div className="grid grid-cols-2 md:flex md:grid-cols-none">
                            <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>15 Mins</button> 
                            <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>19 Mins</button> 
                            <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>45 Mins</button> 
                            <button type="" className='py-2 px-4 mx-2 text-sm font-medium border border-gray-300 rounded mb-4'>60 Mins</button>
                        </div>
                    </div>
                    
                    <hr className='text-gray-300 my-8' />
                    <div className="text-gray-800">
                        <h3 className='text-3xl font-bold pb-2'>N100,000</h3>
                        <div className='flex'>
                            <div className="grid">
                                <p className='text-xl font-bold'>Wed, 2/23/2022</p>
                                <p>10:30 AM - 11:15 AM</p>
                            </div>
                            <div className="ml-8">
                                <Link to="/schedule/payment"><button className='bg-purple-600 text-white rounded-lg py-3 px-5 w-32 lg:w-60'>Next</button></Link>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <AlternateFooter />
    </div>
  )
}

export default Book