import React, { useState } from 'react';
import Header from '../components/banner';
import ReactStars from 'react-rating-stars-component';
import Layout from '../components/layout/layout';
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import ProtectedRoute from '../utils/ProtectedRoute';
import { getEnvVars } from '../app/env';
import { useNavigate, useParams } from 'react-router';
import ReactLoading from 'react-loading';

const { REACT_APP_API_URL } = getEnvVars();

const RateCall = () => {
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const initialState = {
    sessionId: sessionId,
    rating: '',
    comment: '',
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const toastID = Math.random();

  const handleRating = (newRating) => {
    setState((prevState) => ({
      ...prevState,
      rating: newRating,
    }));
  };

  const headers = {
    Authorization: 'Bearer ' + localStorage.getItem('userToken'),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios
        .put(`${REACT_APP_API_URL}/v1/reviews`, state, { headers })
        .then((res) => {
          toast.success(res.data.message, { toastId: toastID });
          localStorage.setItem('exptToken', res.data.data.token);
        });
      setLoading(false);
      navigate('/call');
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error(err.response.data.message, { toastId: toastID });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <>
      <ProtectedRoute>
        <Layout>
          <ToastContainer />
          <div className="h-screen">
            <Header title="Calls" pathUrl="/" />
            <div className="bg-[#00164F] h-full flex justify-center items-center -mt-12">
              <form
                className="bg-white sm:p-6 p-5 rounded-xl sm:rounded-2xl shadow-md -mt-24 w-[87%] sm:w-max mx-auto h-min"
                onSubmit={handleSubmit}
              >
                <h6 className="font-semibold text-xl">Rate your call</h6>
                <ReactStars
                  count={5}
                  onChange={handleRating}
                  size={40}
                  classNames="star-ratings sm:my-4 sm:mb-6"
                  activeColor="#ffd700"
                  name="rating"
                />
                <textarea
                  name="comment"
                  id=""
                  cols="30"
                  rows="4"
                  placeholder="Additional comments"
                  className="w-full bg-neutral-100 rounded-lg p-2.5 border border-neutral-200 sm:min-w-[33rem]"
                  onChange={handleChange}
                ></textarea>

                <button className="btn-default w-full mt-8 flex items-center justify-center space-x-4">
                  {loading && (
                    <ReactLoading
                      type="cylon"
                      color="#fff"
                      className=""
                      height={20}
                      width={20}
                    />
                  )}
                  <p>Submit feedback</p>
                </button>
              </form>
            </div>
          </div>
        </Layout>
      </ProtectedRoute>
    </>
  );
};

export default RateCall;
