import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { FiUser, FiChevronDown } from 'react-icons/fi';
import ProfileDropdown from '../shared/ProfileDropdown';
import { BiChevronDown, BiMenu } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import useExternalClick from '../../hooks/useExternalClick';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, setCurrency } from '../../features/user/userSlice';
import DropDown from '../shared/DropDown';
import downIcon from '../../assets/icons/down-icon.svg';
import zedIcon from '../../assets/icons/Zedintro-icon.svg';
import SelectMenu from '../shared/SelectMenu';
import { setRateInUserCurrency } from '../../features/user/userSlice';
import Container from '../shared/Container';

const currencyOptions = [
  { id: 'GHS', value: 'GHS' },
  { id: 'KES', value: 'KES' },
  { id: 'NGN', value: 'NGN' },
  { id: 'TZS', value: 'TZS' },
  { id: 'UGX', value: 'UGX' },
  { id: 'USD', value: 'USD' },
  { id: 'ZAR', value: 'ZAR' },
];

const navOptions = [
  { id: 1, value: 'Create an Account' },
  { id: 2, value: 'Sign In' },
];

const dashNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { profile, currency } = useSelector((state) => state.user);
  const [state, setState] = useState(false);
  const [userId /*setUserId*/] = useState(() => localStorage.userId);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [headerView, setHeaderView] = useState('top');
  const prohibitedRoutes = [
    '/',
    '/about',
    '/contact',
    '/user/profile/',
    '/expert/error',
    '/privacy',
    '/terms',
  ];

  const ref = useRef();

  useEffect(() => {
    if (prohibitedRoutes.includes(window.location.pathname) && profile) {
      return;
    } else {
      dispatch(getUser({ id: userId }));
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      setScrollPosition(currentPosition);
      if (currentPosition < 76) {
        setHeaderView('top');
      } else if (currentPosition > scrollPosition || currentPosition < 56) {
        setHeaderView('down');
      } else {
        setHeaderView('up');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);

  const handleOnClick = () => {
    if (!state) {
      return;
    } else {
      setState(false);
    }
  };

  useExternalClick(ref, handleOnClick);

  function home() {
    if (
      location.pathname === '/about' ||
      location.pathname === '/contact' ||
      localStorage.getItem('userToken') === null
    ) {
      navigate('/');
    } else {
      navigate('/search');
    }
  }

  const handleSelectCurrency = (currency) => {
    dispatch(setCurrency(currency));
    dispatch(setRateInUserCurrency(currency));
  };

  const handleNavShed = () => {
    let classList;
    if (headerView === 'top' && location.pathname === '/') {
      classList = 'bg-none';
    } else if (headerView === 'up') {
      classList = 'backdrop-filter backdrop-blur-xl bg-bgPurple';
    } else if (headerView === 'down') {
      classList = '-translate-y-full';
    } else {
      classList = 'bg-bgPurple';
    }
    return classList;
  };

  const handleLogout = () => {
    localStorage.removeItem('userToken');
  };

  const handleNavDropdown = (item, field) => {
    if (field === 'expert') {
      if (item.id === 1) {
        navigate('/signup/expert');
      } else {
        navigate('/login/expert');
      }
    } else {
      if (item.id === 1) {
        navigate('/signup/user');
      } else {
        navigate('/login/user');
      }
    }
  };

  return (
    <nav
      className={`w-full transition-all m-auto duration-500 z-50 ${handleNavShed()} `}
    >
      <Container>
        <div className="relative flex justify-between items-center h-24 sm:h-16 z-30">
          <div className="sm:basis-1/3">
            <button
              className="text-white text-2xl sm:text-3xl font-bold w-auto flex items-center gap-3"
              onClick={home}
            >
              <img src={zedIcon} alt="zedintro" className="w-6 sm:w-8" />
              <p>Zedintro</p>
            </button>
          </div>

          <div className="sm:flex hidden -ml-2 sm:ml-0 sm:basis-1/3">
            {prohibitedRoutes.includes(window.location.pathname) ||
            window.location.pathname.includes('/user/profile/') ? (
              <>
                <NavLink
                  to="/about"
                  className="text-white hover:underline hover:underline-offset-8 hover:decoration-purple-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium ml-auto"
                >
                  About
                </NavLink>
                <NavLink
                  to="/contact"
                  className="text-white px-3 py-2 hover:underline hover:underline-offset-8 hover:decoration-purple-500 hover:text-white rounded-md text-sm font-medium mr-auto"
                >
                  Contact
                </NavLink>
              </>
            ) : (
              <>
                <NavLink
                  to="/search"
                  className="text-white hover:underline hover:underline-offset-8 hover:decoration-purple-500 hover:text-white px-3 py-2 rounded-md text-sm font-medium ml-auto"
                >
                  Browse Experts
                </NavLink>
                <NavLink
                  to="/call"
                  className="text-white px-3 py-2 hover:underline hover:underline-offset-8 hover:decoration-purple-500 hover:text-white rounded-md text-sm font-medium mr-auto"
                >
                  Calls
                </NavLink>
              </>
            )}
          </div>

          <div className="flex justify-end gap-x-4 sm:basis-1/3 sm:mt-0">
            {(prohibitedRoutes.includes(window.location.pathname) &&
              window.location.pathname !== '/search') ||
            location.pathname.includes('/user/profile') ? (
              <>
                <SelectMenu
                  options={navOptions}
                  optionField={'expert'}
                  className="min-w-[10rem] top-12"
                  handleSelect={handleNavDropdown}
                >
                  <p className="text-purple-700 bg-white border-2 border-white hidden md:flex items-center text-xs gap-2 py-2 xl:px-8 lg:px-5 px-3 font-semibold lg:text-sm rounded-lg shadow-sm">
                    As an expert
                    <BiChevronDown className="text-xl" />
                  </p>
                </SelectMenu>

                <SelectMenu
                  options={navOptions}
                  optionField={'client'}
                  className="min-w-[10rem] top-12"
                  handleSelect={handleNavDropdown}
                >
                  <p className="text-white border-2 border-white hidden md:flex items-center text-xs gap-2 py-2 xl:px-8 lg:px-5 px-3 font-semibold lg:text-sm rounded-lg shadow-sm">
                    As a client
                    <BiChevronDown className="text-xl" />
                  </p>
                </SelectMenu>
              </>
            ) : (
              <>
                <div className="flex items-center">
                  <DropDown
                    options={currencyOptions}
                    handleSelect={handleSelectCurrency}
                    className={'top-14'}
                  >
                    <div className="bg-white sm:px-4 px-3 text-sm sm:text-base sm:py-2 py-1 rounded flex gap-3 font-light mb-0 sm:mr-8 items-center justify-between">
                      {currency}
                      <div className="">
                        <img
                          src={downIcon}
                          alt="icon"
                          className="text-sm sm:text-base"
                        />
                      </div>
                    </div>
                  </DropDown>
                </div>

                <div className="relative hidden sm:flex items-center p-0">
                  <ProfileDropdown className={'w-36 left-[-5rem] top-14'}>
                    <div className="flex items-center gap-4">
                      <div className="bg-white rounded-full p-0.5 ml-auto">
                        {profile?.profilePhoto ? (
                          <div className="w-6 h-6 rounded-full object-cover">
                            <img
                              src={profile?.profilePhoto}
                              alt=""
                              className="w-full h-full rounded-full"
                            />
                          </div>
                        ) : (
                          <FiUser className="text-[#270058]" size={18} />
                        )}
                      </div>
                      <FiChevronDown className="text-[#C197FF] self-center -ml-3" />
                    </div>
                  </ProfileDropdown>
                </div>
              </>
            )}

            <div className="ml-auto md:hidden">
              {!state && (
                <BiMenu
                  className="text-white text-4xl cursor-pointer"
                  onClick={() => setState(true)}
                />
              )}
            </div>
          </div>
        </div>

        <div
          className={`top-0 fixed z-50 left-0 w-screen h-full backdrop-blur-sm transition-all duration-500 ${
            state ? 'fixed' : 'hidden'
          }`}
        >
          <div
            className={`bg-white pt-8 pb-12 px-12 w-11/12 max-w-[30rem] m-auto mt-32 border rounded-md shadow-md transition-all duration-1000 ${
              state ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-24'
            }`}
            ref={ref}
          >
            <MdClose
              className="text-3xl ml-auto cursor-pointer text-textPurple"
              onClick={() => setState(false)}
            />

            {prohibitedRoutes.includes(window.location.pathname) ? (
              <>
                <ul className="flex flex-col items-end justify-center gap-10 font-bold text-lg mt-10 text-textPurple">
                  <li>
                    <Link to={'/about'}>About</Link>
                  </li>

                  <li>
                    <Link to={'/contact'}>Contact</Link>
                  </li>

                  <li>
                    <SelectMenu
                      options={navOptions}
                      optionField={'expert'}
                      className="min-w-[10rem] top-12"
                      handleSelect={handleNavDropdown}
                    >
                      <p className="text-white bg-textPurple border-2 border-textPurple flex items-center justify-center w-40 gap-2 py-2 px-5 font-semibold lg:text-sm rounded-lg shadow-sm">
                        As Expert
                        <BiChevronDown className="text-xl text-white" />
                      </p>
                    </SelectMenu>
                  </li>

                  <li>
                    <SelectMenu
                      options={navOptions}
                      optionField={'client'}
                      className="min-w-[10rem] top-12"
                      handleSelect={handleNavDropdown}
                    >
                      <p className="text-purple-700 border-2 border-textPurple flex items-center justify-center w-40 gap-2 py-2 px-5  font-semibold lg:text-sm rounded-lg shadow-sm">
                        As User
                        <BiChevronDown className="text-xl text-textPurple" />
                      </p>
                    </SelectMenu>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <ul className="flex flex-col items-end justify-center gap-10 font-bold text-lg mt-10 text-textPurple">
                  <li>
                    <Link to={'/search'}>Browse Experts</Link>
                  </li>

                  <li>
                    <Link to={'/profile'}>Profile</Link>
                  </li>

                  <li>
                    <Link to={'/call'}>Calls</Link>
                  </li>

                  <li>
                    <Link to={'/login/user'} onClick={handleLogout}>
                      Sign out
                    </Link>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
      </Container>
    </nav>
  );
};

export default dashNavbar;
