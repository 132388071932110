import React from 'react';
import { Link } from 'react-router-dom';
import Container from './shared/Container';

const Header = ({ title, pathUrl }) => {
  return (
    <div className={`font-poppins mb-12`}>
      <div className="text-lg lg:text-2xl my-4 mt-8">
        <Container>
          <Link to={pathUrl} className="self-center flex">
            <p className="text-[#4E4A5A] text-[28px] leading-9">{title}</p>
          </Link>
        </Container>
      </div>
      <hr className="h-0.5 border-none bg-neutral-200 shadow-sm" />
    </div>
  );
};

export default Header;
