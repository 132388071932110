import React from 'react'
import { Link } from 'react-router-dom'
import LeftPane from './authleftBg'
import confirm from '../../assets/icons/confirm.svg'

const ForgotPasswordSuccess = () => {
 
  return (
    <div className='grid lg:grid-cols-5 text-sm auth h-screen'>
        <div className="col-span-2 sticky top-0 px-16 hidden lg:block">
            <LeftPane/>
        </div>
        <div className="col-span-3 px-12 sm:px-36 self-center">
      
            <div className="text-center mx-2">
                <img src={confirm} className="mx-auto" alt="confirm-icon" />
                <h1 className='font-bold text-3xl leading-9 my-8'>Password reset sent</h1>
                <p className='px-4'>A password reset link has successfully been sent to your email address.</p>
                <Link to="/">
                <button className='btn-default w-full mt-8 mb-4'>Done</button>
                </Link>
            </div> 
           
        </div>
    </div>
  );
};

export default ForgotPasswordSuccess
