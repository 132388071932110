import React from 'react';
import { Link } from 'react-router-dom';
import Container from '../shared/Container';

const footer = () => {
  return (
    <>
      <section className="bg-[#270058]">
        <footer className="text-white py-10">
          <Container>
            <div className="grid lg:grid-cols-4">
              <div className="grid grid-cols-1 grid-rows-1">
                <h2 className="text-3xl font-bold">Zedintro</h2>

                <span className="about">
                  <p className="py-4">
                    Book world-class experts and get advise tailored just for
                    you
                  </p>

                  <p className="mt-4">
                    <Link
                      to="/signup/expert"
                      className="sm:rounded-lg rounded-md border-slate-300 border text-sm sm:text-base sm:py-3 py-2 sm:px-6 px-5"
                    >
                      Become an Expert
                    </Link>
                  </p>
                </span>
              </div>

              <div className="grid grid-cols-1 lg:grid-cols-none sm:py-12 py-8 input">
                <h2 className="text-sm pb-4">Stay up to date</h2>

                <form className="flex-none lg:flex">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="sm:py-3 py-2 px-4 sm:rounded-lg rounded-md text-sm sm:text-base text-black"
                  />

                  <button className="sm:py-3 py-2 sm:px-6 px-5 text-sm sm:text-base mt-5 lg:mt-0 rounded-lg bg-purple-600 ml-3">
                    Subscribe
                  </button>
                </form>
              </div>

              <div className="grid grid-cols-2 sm:pb-12 pb-8 links">
                <div className="">
                  <h6 className="text-gray-400">Company</h6>

                  <p className="py-3">
                    <Link to="/about">About</Link>
                  </p>

                  <p>
                    <Link to="">Partners</Link>
                  </p>

                  <p className="py-3">
                    <Link to="">Career</Link>
                  </p>

                  <p>
                    <Link to="">Contact</Link>
                  </p>
                </div>

                <div className="">
                  <h6 className="text-gray-400">Product</h6>

                  <p className="py-3">
                    <Link to="" className="py-4">
                      Overview
                    </Link>
                  </p>

                  <p>
                    <Link to="">Pricing</Link>
                  </p>

                  <p className="py-3">
                    <Link to="">Help Centre</Link>
                  </p>

                  <p>
                    <Link to="">Support</Link>
                  </p>
                </div>
              </div>
            </div>
          </Container>
          <hr className="border border-gray-700 bg-gray-700 lg:my-5" />
          <Container>
            <div className="grid grid-cols-1 lg:grid-cols-2 text-gray-400 pt-4">
              <p className="">
                © {new Date().getFullYear()} Zedintro. All rights reserved.
              </p>
              <div className="flex sm:justify-end pt-4 lg:pt-0 lg:py-0">
                <Link to="/terms">Terms</Link>
                <Link to="/privacy" className="pl-4 lg:pl-8">
                  Privacy
                </Link>
              </div>
            </div>
          </Container>
        </footer>
      </section>
    </>
  );
};

export default footer;
