import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import confirm from '../../assets/icons/confirm.svg';
import { useDispatch } from 'react-redux';
import { setRedirectPath } from '../../features/user/userSlice';
import FacebookPixel from '../../utils/FacebookPixel';

const EmailVerificationSuccess = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirect_to');
  const handleRedirect = () => {
    dispatch(setRedirectPath({ payload: true }));
  };

  return (
    <>
      <FacebookPixel />
      <div className="h-screen flex flex-col items-center">
        <div className="flex flex-wrap justify-center items-center font-semibold mt-16 sm:mt-24 mb-24 sm:mb-32">
          <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
            Get Started
          </button>
          <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
            Email Verification
          </button>
          <button className="border-b-2 border-[#792AE2] text-[#792AE2] py-2 sm:px-6 px-3 text-xs sm:text-sm md:text-base">
            Email Confirmation
          </button>
        </div>

        <div className="text-center max-w-[30rem]">
          <img src={confirm} className="mx-auto" alt="icon" />

          <h1 className="font-bold text-2xl md:text-3xl leading-9 sm:my-8 my-5">
            Congratulations, your email confirmation was successful.
          </h1>

          <p className="max-w-[20rem] m-auto">
            You have successfully signed up. Welcome to Zedintro
          </p>

          <Link
            to={`/login/user?redirect_to=${redirectTo || ''}`}
            onClick={handleRedirect}
          >
            <button className="sm:mt-8 mt-5 mb-4 font-medium bg-[#00164F] text-white rounded-lg py-2 sm:py-3 px-5 min-w-[10rem]">
              Continue
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default EmailVerificationSuccess;
