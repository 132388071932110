import React from 'react';
import Navbar from '../components/layout/navbar';
import Footer from '../components/layout/footer';
import FacebookPixel from '../utils/FacebookPixel';
import Container from '../components/shared/Container';

const Privacy = () => {
  return (
    <div className="about mx-auto">
      <FacebookPixel />
      <Navbar />
      <div className="pb-20">
        <section className="three bg-bgPurple sm:pt-36 pt-28 py-16 text-white">
          <Container>
            <h2 className="font-bold text-4xl lg:text-[57px] pb-4 lg:pb-8">
              ZedIntro Privacy Policy
            </h2>

            <p className="lg:pr-20">
              This Privacy Policy (&quot;Policy&quot;) explains how Zedintro
              (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or
              &quot;our&quot;) collects, uses, and protects the personal
              information of users (&quot;User&quot; or &quot;you&quot;) when
              you access or use the Zedintro web application
              (&quot;Webapp&quot;). We are committed to respecting your privacy
              and protecting your personal information. By using the Zedintro
              Webapp, you consent to the practices described in this Policy.
            </p>
          </Container>
        </section>

        <section className="mt-20">
          <Container>
            <h2 className="font-bold text-xl pb-4">
              1. Information We Collect
            </h2>
            <p>
              1.1 Personal Information: When you create an account or use
              certain features of the Zedintro Webapp, we may collect personal
              information such as your name, email address, and payment
              information. We may also collect information such as your gender,
              sex and age,
            </p>
            <p className="my-4">
              1.2 Usage Information: We may collect information about your
              interactions with the Webapp, such as the pages you visit, the
              features you use, and the duration of your sessions.
            </p>
            <p>
              1.3 Device Information: We may collect information about the
              device you use to access the Webapp, including your device type,
              operating system, browser type, and IP address.
            </p>
            <p className="my-4">
              1.4 Cookies and Similar Technologies: We may use cookies and
              similar tracking technologies to enhance your experience and
              collect information about your usage patterns.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">2. Use of Information</h2>
            <p>We use the collected information to</p>
            <p className="mt-4">
              2.1 provide and improve the Zedintro Webapp, facilitate
              communication between users, process payments, and personalize
              your experience.
            </p>
            <p className="my-4">
              2.2 understand how you use our network, products and services.
              that way, we can develop more interesting and relevant products
              and services, as well as personalise the products and services we
              offer you.
            </p>
            <p>
              2.3 carry out research and statistical analysis to monitor how
              users’ use our network, products and services on an anonymous or
              personal basis.
            </p>
            <p className="my-4">
              2.4 we may use your email address to send you important
              notifications, updates, or promotional materials related to the
              webapp. you can opt out of receiving marketing communications by
              following the unsubscribe instructions in the email.
            </p>
            <p>
              2.5 where your personal information is required other than in
              respect of the aforementioned, we shall notify you of such other
              purpose for which your personal information is required to enable
              you to provide your consent.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">3. Information Sharing</h2>

            <p className="">
              3.1 We do not sell, rent, or trade your personal information to
              third parties for marketing purposes.
            </p>
            <p className="my-4">
              3.2 We may share your personal information with third-party
              service providers who assist us in operating the Webapp and
              delivering our services. These service providers are bound by
              confidentiality obligations and are prohibited from using your
              personal information for any other purpose.
            </p>
            <p>
              3.3 We may disclose your personal information if required by law,
              regulation, or legal process, or if we believe that disclosure is
              necessary to protect our rights, property, or safety, or the
              rights, property, or safety of others.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">4. Data Security</h2>
            <p className="">
              4.1 Maintaining the security of Your Personal Information is
              important to MTN. Reasonable security safeguards have been
              implemented to protect Your Personal Information.
            </p>
            <p className="my-4">
              4.2 We implement reasonable security measures to protect your
              personal information from unauthorized access, disclosure,
              alteration, or destruction.
            </p>
            <p>
              4.3 To protect any data, You store on Our servers, MTN regularly
              audits its systems for possible vulnerabilities and attacks.
            </p>
            <p className="mt-4">
              4.4 However, no method of transmission over the internet or
              electronic storage is 100% secure. Therefore, while we strive to
              protect your personal information, we cannot guarantee its
              absolute security.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">5. Data Retention</h2>
            <p className="">
              5.1 We retain your personal information for as long as necessary
              to fulfill the purposes outlined in this Policy, unless a longer
              retention period is required or permitted by law.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">6. Your Rights as a User</h2>
            <p>
              6.1 Erasing your information: You have the right to request that
              we erase your personal information if it is no longer valid or
              necessary for the purposes for which it was collected or if it is
              incomplete or inaccurate.
            </p>
            <p className="my-4">
              6.2 Receiving your information: You have the right to receive your
              personal information in a commonly used and machine-readable
              format and the right to transmit these data to another Data
              Controller when the processing is based on (explicit) consent or
              when the processing is necessary for the performance of a
              contract.
            </p>
            <p>
              6.3 You can review the information you provide to us and make any
              desired changes to the information you have provided. Please be
              aware that even after your request for a change is processed, we
              may, for a time, retain residual information about you in our
              backup and/or archival copies of our database.
            </p>
            <p className="my-4">
              6.4 Direct Marketing: You have the right to choose whether you
              receive marketing material or not. By accepting using our products
              and services, you are also agreeing to us sending you marketing
              material on related services, products and offerings provided by
              us from time to time. If you change your mind at any time, you are
              always able to opt-out.
            </p>
            <p className="mt-4">
              6.5 Your personal information shall be subject to the Nigerian
              Data Protection Regulation 2019 (as may be amended from time to
              time) and any other applicable Data Protection Regulation.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">7. Your Obligations</h2>

            <p>
              As a user, there are certain obligations expected from You. Some
              of these obligations are imposed by applicable law and
              regulations, and others have become commonplace for us:
            </p>
            <p className="my-4">
              7.1 you must always abide by the terms of this notice and any
              agreement or consent relating to our products and services that
              you have subscribed to. this includes respecting all intellectual
              property rights that may belong to third parties (such as
              trademarks or photographs).
            </p>
            <p>
              7.2 you must not provide us with information that you believe
              might be false or misleading.
            </p>
            <p className="my-4">
              7.3 you must keep your username and password confidential and not
              share it with others.
            </p>
            <p className="mt-4">
              7.4 promptly notify us at the contact details indicated below,
              where you wish to exercise any of your rights with respect to your
              personal information.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">8. Third-Party Links</h2>
            <p>
              8.1 The Zedintro Webapp may contain links to third-party websites
              or services. We are not responsible for the privacy practices or
              content of those third parties. We encourage you to review the
              privacy policies of those third parties before providing any
              personal information.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">
              9. Children&apos;s Privacy
            </h2>
            <p>
              9.1 The Zedintro Webapp is not intended for use by individuals
              under the age of 18. We do not knowingly collect personal
              information from children. If we become aware that we have
              inadvertently collected personal information from a child, we will
              take steps to delete such information as soon as possible.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">10. Reporting Incidents</h2>
            <p>
              10.1 In the event of a privacy breach, we shall report such breach
              to the relevant authority and if necessary, affected individuals
              of personal data breach (where the personal data breach will
              likely result in high risks to the freedoms and rights of the data
              subject) within reasonable time of being aware of the breach.
            </p>
            <p className="my-4">
              10.2 We shall also take steps to investigate and recover personal
              data and will also ensure that controls are enhanced to prevent a
              re-occurrence of the breach.
            </p>
            <p>
              10.3 You have a right to lodge a complaint before an appropriate
              authority for any breach of Your rights.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">
              11. Changes to this Privacy Policy
            </h2>

            <p>
              11.1 We reserve the right to update or modify this Policy at any
              time. Any changes will be effective when we post the revised
              Policy on the Zedintro Webapp. Your continued use of the Webapp
              following the posting of any changes constitutes your acceptance
              of those changes.
            </p>
          </Container>
        </section>

        <section className="mt-10">
          <Container>
            <h2 className="font-bold text-xl pb-4">12. Contact Us</h2>
            <p>
              12.1 If you have any questions or concerns about this Privacy
              Policy or our data practices, please contact us at
              hello@zedi.africa.
            </p>
          </Container>
        </section>

        <section>
          <Container>
            <p className="mt-4 font-bold">
              By using the Zedintro Webapp, you acknowledge that you have read,
              understood, and agree to the practices described in this Privacy
              Policy.
            </p>
          </Container>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
